import React from "react";
import ReactSelect from "react-select";

export const Select = ({ ...props }) => {
    const customStyles = {
        control: (base, state) => ({
            ...base,
            borderRadius: 0,
        }),
        option: (base, state) => ({
            ...base,
            whiteSpace: "normal",
        }),
    };
    return <ReactSelect menuPlacement={"auto"} styles={customStyles} {...props} />;
};

// export const MultiSelect = props => {
//     const customStyles = {
//         control: (base, state) => ({
//             ...base,
//             borderRadius: 0
//         }),
//     };
//     return <ReactSelect  styles={customStyles} {...props} />;
// }

// const SingleValue = props => {
//     console.log(props);
//     return (
//         <div>
//             {props.data.name}
//             {props.data.gmbUrl}
//             {props.data.placeId}
//             {props.data.gmbAccountId}
//         </div>
//     );
// };

// export const SelectAccount = props => {
//     const customStyles = {
//         option: (base, state) => ({
//             ...base,
//             whiteSpace: "normal"
//         }),
//         valueContainer: base => ({
//             ...base
//         }),
//         control: (base, state) => ({
//             ...base,
//             borderRadius: 0,
//             height: "60px"
//         })
//     };
//
//     return (
//         <ReactSelect
//             components={{ SingleValue }}
//             menuPlacement={"auto"}
//             styles={customStyles}
//             {...props}
//         />
//     );
// };
