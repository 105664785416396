import { createSlice } from "@reduxjs/toolkit";

export default createSlice({
    name: "openPractice",
    initialState: {},
    reducers: {
        set: (state, action) => action.payload,
        setIsNewModel: (state, action) => ({ ...state, isNewModel: action.payload }),
    },
});
