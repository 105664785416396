import { api } from "../utilityActions";
import getPractices from "./getPractices";
import {ententeUrl} from "../../config";

export default (practiceData) =>
    api({
        call: {
            url: "/piston/secure/practices/add",
            method: "post",
            data: { practiceData },
            baseURL: ententeUrl
        },
        success: getPractices,
    });
