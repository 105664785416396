import React from "react";
import ReactLoader from "react-loader-spinner";
import { useTheme } from "styled-components";

export function Spinner({ isLoading = true, sideLength = 150 }) {
    const theme = useTheme();
    return isLoading ? (
        <ReactLoader
            height={sideLength}
            width={sideLength}
            type={"TailSpin"}
            color={theme.colors.nauticBlue}
        />
    ) : null;
}
