import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash/fp";

const currentUserInitialState = {
    isAuthenticated: false,
    userId: "",
    user: {},
};
export default createSlice({
    name: "currentUser",
    initialState: currentUserInitialState,
    reducers: {
        set: (state, { payload }) => ({ ...state, user: payload }),
        setUserId: (state, { payload }) => ({ ...state, isAuthenticated: true, userId: payload }),
        reset: _.constant(currentUserInitialState),
    },
});
