import styled from "styled-components";
import { RawGradient } from "./assets";

export const Gradient = styled(RawGradient)`
    width: 100vw;
    height: 11px;
    margin-bottom: 0;
    display: block;
    //position: fixed;
    //top: 0;
    //z-index: 100;
`;
