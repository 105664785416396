import slices from "../slices";
import { api } from "../utilityActions";
import {ententeUrl} from "../../config";

export default () =>
    api({
        call: {
            url: "/piston/secure/clients/get-strategists",
            method: "get",
            baseURL: ententeUrl
        },
        success: slices.strategists.actions.set,
    });
