import _ from "lodash/fp";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import styled, { useTheme } from "styled-components";
import { layout } from "styled-system";
import { routerHistory } from "../../routerHistory";
import { slices } from "../../redux";
import { Gradient } from "../Atoms";
import { Topbar } from "../Organisms";
import { Box } from "../StyledSystemUtilities";
import { MyAccount } from "../Pages/MyAccount";

const ContentContainer = styled.div`
    display: grid;
    max-width: 1200px;
    justify-content: center;
    margin-left: ${(props) => (props.addMargin ? "204px" : 0)};
    margin-right: ${(props) => (props.addMargin ? "20px" : 0)};
    padding-top: ${(props) => (props.addMargin ? "6px" : 0)};

    @media (max-width: ${(props) => props.theme.breakpoints[0]}px) {
        grid-template-columns: 1fr;
    }

    @media (min-width: ${(props) => props.theme.breakpoints[0]}px) {
        grid-template-columns: 1fr 1fr;
    }
    ${layout}
`;

const SidebarContainer = (props) => (
    <Box position={"fixed"} top={"52px"} height={"100vh"} zIndex={100} {...props} />
);

const Top = styled.div`
    position: fixed;
    z-index: 200;
    top: 0;
    transform: translateZ(0);
    -moz-transform: translatez(0);
    -ms-transform: translatez(0);
    -o-transform: translatez(0);
    -webkit-transform: translateZ(0);
    -webkit-font-smoothing: antialiased;
`;

export const BuildMain = ({ renderSidebar, renderRoutes }) => {
    const dispatch = useDispatch();
    const contentContainerRef = useRef(null);

    const handleContainerResize = _.debounce(150, () => {
        dispatch(
            slices.dimensions.actions.set(
                "contentContainer",
                _.invoke("current.getBoundingClientRect", contentContainerRef)
            )
        );
    });

    const contentContainerRefWidth = _.get(
        "width",
        _.invoke("current.getBoundingClientRect", contentContainerRef)
    );

    useEffect(() => {
        handleContainerResize();
    }, [contentContainerRefWidth, handleContainerResize]);

    const theme = useTheme();

    const isAuthenticated = useSelector((state) => state.currentUser.isAuthenticated);
    const width = useSelector((state) => state.dimensions.viewport.width);

    const [showSidebar, setShowSidebar] = useState(false);

    const handleSidebarToggleClick = () => {
        setShowSidebar((s) => !s);
    };

    const isWidthGreaterThanBreakpoint = width > theme.breakpoints[1];

    useEffect(() => {
        if (width > theme.breakpoints[1]) {
            setShowSidebar(true);
        }
    }, [isWidthGreaterThanBreakpoint, theme.breakpoints, width]);

    useEffect(() => {
        routerHistory.push("/dash/practice-list");
    }, []);

    return (
        <Box my={"52px"} mb={"70px"}>
            <Top>
                <Gradient />
                <Topbar
                    showSidebar={showSidebar}
                    showToggle={true}
                    //showToggle={width <= theme.breakpoints[1]}
                    handleSidebarToggleClick={handleSidebarToggleClick}
                />
            </Top>

            {showSidebar ? (
                <SidebarContainer>{renderSidebar(setShowSidebar)}</SidebarContainer>
            ) : null}

            <ContentContainer ref={contentContainerRef} addMargin={showSidebar}>
                <Switch>
                    <Route path={"/dash/my-account"} render={() => <MyAccount />} />

                    {renderRoutes()}

                    <Route>
                        {isAuthenticated ? <Redirect to="/dash" /> : <Redirect to={"/login"} />}
                    </Route>
                </Switch>
            </ContentContainer>
        </Box>
    );
};
