import jwtDecode from "jwt-decode";
import { routerHistory } from "../../routerHistory";
import setAuthToken from "../../utilities/setAuthToken";
import slices from "../slices";

const setJwtToken = (token) => localStorage.setItem("jwtToken", token);

export default (token) => (dispatch) => {
    setJwtToken(token);
    setAuthToken(token);
    const decoded = jwtDecode(token);
    dispatch(slices.currentUser.actions.setUserId(decoded._id));
    routerHistory.push("/dash/practice-list");
};
