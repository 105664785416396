import React from "react";

import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styled from "styled-components";
import { getColor, getSize } from "../../../helpers/themeGetters";

const Wrapper = styled.div`
    .datePickerInput {
        height: ${getSize(11)};
        width: 100%;
        display: block;
        border: 1px solid ${getColor("pearlGray")};
        box-sizing: border-box;
        padding: 0.5rem;
        color: ${getColor("balticBlue")};
        font-size: 1em;
        -webkit-appearance: none;
        -webkit-border-radius: 0;
        -moz-appearance: none;
        appearance: none;
    }
    .react-datepicker-wrapper,
    .react-datepicker__input-container {
        display: block;
        width: 100%;
    }

    .react-datepicker {
        font-size: 1em;
    }
    .react-datepicker__header {
        padding-top: 0.8em;
    }
    .react-datepicker__month {
        margin: 0.4em 1em;
    }
    .react-datepicker__day-name,
    .react-datepicker__day {
        width: 1.9em;
        line-height: 1.9em;
        margin: 0.166em;
    }
    .react-datepicker__current-month {
        font-size: 1em;
    }
    .react-datepicker__navigation {
        top: 1em;
        line-height: 1.7em;
        height: 1em;
        border: 0.45em solid transparent;
    }
    .react-datepicker__navigation--previous {
        border-right-color: #ccc;
        left: 1em;
    }
    .react-datepicker__navigation--next {
        border-left-color: #ccc;
        right: 1em;
    }
`;

export const DatePicker = ({ ...props }) => {
    return (
        <Wrapper>
            <ReactDatePicker data-testid={props.testid} className={"datePickerInput"} {...props} />
        </Wrapper>
    );
};

// export const MonthYearPicker = ({ ...props }) => {
//     return (
//         <Wrapper>
//             <ReactDatePicker {...props} />
//         </Wrapper>
//     );
// };
