import styled, { css } from "styled-components";

import { getBorder, getColor } from "../../helpers/themeGetters";
import { allStyleFunctions } from "../StyledSystemUtilities";

const baseCellStyles = css`
    font-family: Arial;
    white-space: nowrap;
    min-width: 80px;
    border: ${getBorder("gray")};
    text-align: left;
    padding: 10px;
    color: black;
    font-size: 14px;
`;

export const Td = styled.td`
    ${baseCellStyles};
`;
export const Th = styled.td`
    ${baseCellStyles};
    background-color: ${getColor("chalk")};
    user-select: none;
`;

export const Table = styled.table`
    border-collapse: collapse;
    overflow-x: scroll;
    ${allStyleFunctions};
`;
