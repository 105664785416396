import slices from "../slices";
import { api } from "../utilityActions";
import {ententeUrl} from "../../config";

export default () =>
    api({
        call: {
            url: "/piston/secure/practices/get-all",
            method: "get",
            baseURL: ententeUrl
        },
        success: slices.practices.actions.set,
        label: "getPractices",
    });
