import React from "react";
import styled from "styled-components";

import { getColor, getFontSize } from "../../../helpers/themeGetters";
import ErrorBoundary from "../../Utilities/ErrorBoundary";

const ChartContainer = styled.div`
    grid-column: 1;
    display: grid;
    grid-template-columns: auto 1fr;
`;

const BaseLabel = styled.div`
    color: ${getColor("agateGray")};
    text-align: center;
    font-size: ${getFontSize(1)};
    font-family: Roboto;
`;

const YLabel = styled(BaseLabel)`
    grid-column: 1;
    writing-mode: vertical-rl;
    transform: rotate(180deg);

    grid-row: 1;
`;

const XLabel = styled(BaseLabel)`
    grid-column: 2;
    grid-row: 2;
`;

const ChartWrap = styled.div`
    grid-column: 2;
    grid-row: 1;
`;

export const Chart = ({ renderPlot, xLabel, yLabel }) => {
    return (
        <ChartContainer>
            <YLabel>{yLabel}</YLabel>
            <XLabel>{xLabel}</XLabel>
            <ErrorBoundary>
                <ChartWrap>{renderPlot()}</ChartWrap>
            </ErrorBoundary>
        </ChartContainer>
    );
};
