import getAggregatedPpc from "./getAggregatedPpc";
import login from "./login";
import getPractices from "./getPractices";
import addPractice from "./addPractice";
import updatePractice from "./updatePractice";
import logout from "./logout";
import getUser from "./getUser";
import changePassword from "./changePassword";
import setNewPassword from "./setNewPassword";
import resetPassword from "./resetPassword";
import getMyAccount from "./getMyAccount";
import selectPractice from "./selectPractice";
import getUsers from "./getUsers";
import addEmployee from "./addEmployee";
import getGoogleAccounts from "./getGoogleAccounts";
import getLeads from "./getLeads";
import getPpcCampaigns from "./getPpcCampaigns";
import getCallRailAccounts from "./getCallRailAccounts";
import getDirectMailCampaigns from "./getDirectMailCampaigns";
import addDirectMailCampaign from "./addDirectMailCampaign";
import updateDirectMailCampaign from "./updateDirectMailCampaign";
import getClients from "./getClients";
import addClient from "./addClient";
import updateClient from "./updateClient";
import getCallRailCompanies from "./getCallRailCompanies";
import getRecurlyAccounts from "./getRecurlyAccounts";
import addClientUser from "./addClientUser";
import getClientUsers from "./getClientUsers";
import getAdviceLocalAccounts from "./getAdviceLocalAccounts";
import getVeloxPractices from "./getVeloxPractices";
import getContractPeriodsReport from "./getContractPeriodsReport";
import editEmployee from "./editEmployee";
import upgradePracticeToNewModel from "./upgradePracticeToNewModel";
import getStrategists from "./getStrategists";
import getAggregatedInvoiceReport from "./getAggregatedInvoiceReport";
import getSwellLocations from "./getSwellLocations";

const actions = {
    upgradePracticeToNewModel,
    login,
    getPractices,
    addPractice,
    updatePractice,
    logout,
    getUser,
    changePassword,
    setNewPassword,
    resetPassword,
    getMyAccount,
    selectPractice,
    getUsers,
    addEmployee,
    getGoogleAccounts,
    getLeads,
    getPpcCampaigns,
    getCallRailAccounts,
    getAggregatedPpc,
    getDirectMailCampaigns,
    addDirectMailCampaign,
    updateDirectMailCampaign,
    getClients,
    addClient,
    updateClient,
    getCallRailCompanies,
    getRecurlyAccounts,
    addClientUser,
    getClientUsers,
    getAdviceLocalAccounts,
    getVeloxPractices,
    getContractPeriodsReport,
    editEmployee,
    getStrategists,
    getAggregatedInvoiceReport,
    getSwellLocations,
};

export default actions;
