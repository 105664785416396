import { ReactComponent as AddIcon } from "./Add.svg";
import { ReactComponent as ArrowDownAsset } from "./arrowDown.svg";
import { ReactComponent as ArrowLeftAsset } from "./arrowLeft.svg";
import { ReactComponent as ArrowRightAsset } from "./arrowRight.svg";
import { ReactComponent as CheckAsset } from "./Check.svg";
import { ReactComponent as Cross } from "./Cross.svg";
import { ReactComponent as GradientSVG } from "./gradientBar.svg";
import { ReactComponent as HamburgerAsset } from "./Hamburger.svg";
import { ReactComponent as ListIcon } from "./ListIcon.svg";
import { ReactComponent as LogoSVG } from "./Logo.svg";
import { ReactComponent as ProfileIcon } from "./Profile.svg";
import { ReactComponent as SmallLogo } from "./SmallLogo.svg";

export const RawArrowRight = ArrowRightAsset;
export const RawArrowLeft = ArrowLeftAsset;
export const RawArrowDown = ArrowDownAsset;
export const RawLogo = LogoSVG;
export const RawGradient = GradientSVG;
export const RawCheck = CheckAsset;
export const RawHamburger = HamburgerAsset;
export const RawCross = Cross;
export const RawSmallLogo = SmallLogo;
export const RawListIcon = ListIcon;
export const RawProfileIcon = ProfileIcon;
export const RawAddIcon = AddIcon;
