import slices from "../slices";
import { api } from "../utilityActions";
import {ententeUrl} from "../../config";

export default (practiceId) =>
    api({
        call: {
            url: `/piston/secure/practices/get-is-new-model/${practiceId}`,
            method: "get",
            baseURL: ententeUrl
        },
        success: slices.openPractice.actions.setIsNewModel,
        label: "getIsNewModel",
    });
