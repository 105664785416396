import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash/fp";

export default createSlice({
    name: "dimensions",
    initialState: {
        contentContainer: { width: 375 },
        viewport: { width: window.innerWidth, height: window.innerHeight },
    },
    reducers: {
        set: {
            reducer: (state, { payload }) => _.set(payload.label, payload.dimensions, state),
            prepare: (label, dimensions) => ({ payload: { label, dimensions } }),
        },
    },
});
