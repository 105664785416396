import React from "react";
import styled from "styled-components";
import { getColor, getSpace } from "../../../helpers/themeGetters";
import { border, color, typography } from "styled-system";
import _ from "lodash/fp";

const Table = styled.table`
    background-color: ${getColor("chalk")};
    border-collapse: collapse;
    width: 100%;
`;

const BaseTd = styled.td`
    padding: 3px;
    font-family: Arial;
    font-size: 12px;
    text-align: center;
    color: ${getColor("agateGray")};
    ${color};
    height: 20px;
    ${border};
    ${typography};
`;

const Td = styled(BaseTd)``;

const Th = styled.th`
    padding: ${getSpace(3)} 0;
    color: ${getColor("agateGray")};
    font-size: 12px;
    ${color}
`;

const Tr = styled.tr`
    ${border}
`;

const BoldTr = styled.tr`
    border-bottom: 1px solid ${getColor("slateGray")};
`;

export const PrimaryTable = ({ data, columns, lastRow, lastRowColor, lastRows }) => {
    return (
        <Table>
            <thead>
                <BoldTr>
                    {columns.map((col, index) =>
                        col.renderHeader ? (
                            <Th key={index}>{col.renderHeader()}</Th>
                        ) : (
                            <Th color={col.headerColor ? col.headerColor : "agateGray"} key={index}>
                                {col.header}
                            </Th>
                        )
                    )}
                </BoldTr>
            </thead>
            <tbody>
                {data.map((row, rowIndex, array) => {
                    return (
                        <Tr key={rowIndex}>
                            {columns.map((col, colIndex) => {
                                return (
                                    <Td
                                        key={colIndex}
                                        borderBottom={
                                            colIndex === array.length - 1
                                                ? "heavyGray"
                                                : col.omitBorder
                                                ? "none"
                                                : "gray"
                                        }
                                        color={
                                            col.textColor
                                                ? col.textColor
                                                : col.getTextColor
                                                ? col.getTextColor(row)
                                                : "agateGray"
                                        }
                                    >
                                        {col.getValue(row, rowIndex)}
                                    </Td>
                                );
                            })}
                        </Tr>
                    );
                })}

                {lastRow ? (
                    <Tr>
                        {lastRow.map((value, index) => (
                            <Td key={index} color={lastRowColor} style={{ fontWeight: "bold" }}>
                                {value}
                            </Td>
                        ))}
                    </Tr>
                ) : null}

                {lastRows
                    ? lastRows.map((row, index) => (
                          <Tr key={index}>
                              {row.cells.map((col, index) =>
                                  _.isObject(col) ? (
                                      <Td key={index} {...row.cellStyles} colSpan={col.colSpan}>
                                          {col.value}
                                      </Td>
                                  ) : (
                                      <Td key={index} {...row.cellStyles}>
                                          {col}
                                      </Td>
                                  )
                              )}
                          </Tr>
                      ))
                    : null}
            </tbody>
        </Table>
    );
};

const monthAbbreviations = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
];

export const MultiYearTable = ({ years, formatter, shouldShowAverage }) => {
    return (
        <Table>
            <thead>
                <BoldTr>
                    <Th> </Th>
                    {years.map((year, index) => (
                        <Th color={"agateGray"} key={index}>
                            {year.year}
                        </Th>
                    ))}
                </BoldTr>
            </thead>
            <tbody>
                {monthAbbreviations.map((monthName, monthIndex, array) => {
                    const border = monthIndex === array.length - 1 ? "heavyGray" : "gray";
                    return (
                        <Tr key={monthName}>
                            <Td borderBottom={border}>{monthName}</Td>
                            {years.map((year) => {
                                const foundYear = _.find({ year: year.year }, years);
                                const month = _.find({ x: monthIndex }, _.get("months", foundYear));

                                return (
                                    <Td borderBottom={border} key={year.year}>
                                        {formatter(_.get("y", month))}
                                    </Td>
                                );
                            })}
                        </Tr>
                    );
                })}
                <Tr borderBottom={"gray"}>
                    <Td fontWeight="bold">Avg.</Td>
                    {years.map((year) => {
                        return <Td key={year.year}>{formatter(year.average)}</Td>;
                    })}
                </Tr>
                <Tr borderBottom={"heavyGray"}>
                    <Td fontWeight="bold">Total</Td>
                    {years.map((year) => {
                        return <Td key={year.year}>{formatter(year.total)}</Td>;
                    })}
                </Tr>
            </tbody>
        </Table>
    );
};
