import styled, { css } from "styled-components";
import { getSize } from "../../helpers/themeGetters";
import { RawAddIcon, RawCross, RawHamburger, RawListIcon, RawProfileIcon } from "./assets";

const iconCss = css`
    display: block;
    height: ${getSize(7)};
    width: ${getSize(7)};
    fill: ${(props) => (props.color ? props.color : "white")};
`;

export const Hamburger = styled(RawHamburger)`
    ${iconCss}
`;

export const Cross = styled(RawCross)`
    ${iconCss}
`;
export const ListIcon = styled(RawListIcon)`
    ${iconCss}
`;
export const ProfileIcon = styled(RawProfileIcon)(iconCss);

export const AddIcon = styled(RawAddIcon)(iconCss);
