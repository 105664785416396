import React from "react";
import styled from "styled-components";
import { getColor, getSpace } from "../../../helpers/themeGetters";
import { BlueBody2, BlueBody3, Body2, Card } from "../../Atoms";
import { Box } from "../../StyledSystemUtilities";

const Container = styled(Card)`
    background-color: ${getColor("chalk")};
    padding: ${getSpace(5)} ${getSpace(8)};
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-column: 1 / -1;
`;

const Info = styled.div`
    box-sizing: border-box;
    background-color: white;
    padding: ${getSpace(3)} ${getSpace(8)};
`;

export const InfoBar = ({ label, info, label2 }) => {
    return (
        <Container>
            <Info>
                <BlueBody2>{info}</BlueBody2>
            </Info>
            <Box mr={4} />
            <Box display={"flex"} flexDirection={"column"}>
                <Body2>{label}</Body2>
                {label2 ? (
                    <>
                        <Box mb={1} />
                        <BlueBody3>{label2}</BlueBody3>
                    </>
                ) : null}
            </Box>
        </Container>
    );
};
