import _ from "lodash/fp";
import React, { useLayoutEffect, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import { actions } from "../../../redux";

import { transformCampaignInitialState } from "../../../utilities";
import {
    AddButton,
    BlueBody2,
    BlueBody3,
    Body2,
    Body3,
    Card,
    Checkbox,
    CrossButton,
    EditButton,
    Error,
    Heading,
    Heading2,
    PrimaryButton,
    Select,
    SmallTextInput,
    UppercaseLabel,
} from "../../Atoms";
import { LabeledCheckbox } from "../../Molecules";
import { Modal } from "../../Organisms";
import { LinkedCallRailTrackersSelect } from "../../Organisms/Forms/DirectMailCampaignForm";
import { Box, FlexRow, Grid } from "../../StyledSystemUtilities";
import { manageCampaignSchema, ppcCampaignSchema } from "../../../yupSchemas";
import ObjectID from "bson-objectid";

export const PpcSetup = () => {
    const dispatch = useDispatch();
    const openPractice = useSelector((state) => state.openPractice);

    const [isManageCampaignModalOpen, setIsManageCampaignModalOpen] = useState(false);
    const [isUpdateCampaignOpen, setIsUpdateCampaignOpen] = useState(false);
    const [campaignToUpdateId, setCampaignToUpdateId] = useState("");

    const handleUpdateMode = (id) => {
        setCampaignToUpdateId(id);
        setIsUpdateCampaignOpen(true);
    };

    const handleUpdateSubmit = (update) => {
        const updatedCampaigns = openPractice.ppcCampaigns.map((campaign) => {
            if (campaign.campaignId === update.campaignId) {
                return {
                    ...campaign,
                    ...update,
                };
            } else {
                return campaign;
            }
        });

        dispatch(actions.updatePractice(openPractice._id, { ppcCampaigns: updatedCampaigns }));
        setIsUpdateCampaignOpen(false);
    };

    return (
        <>
            <Modal
                title={"Manage Campaigns"}
                isOpen={isManageCampaignModalOpen}
                setIsOpen={setIsManageCampaignModalOpen}
            >
                <ManageCampaignsForm
                    practice={openPractice}
                    setClosed={() => setIsManageCampaignModalOpen(false)}
                />
            </Modal>

            <Modal
                title={"Edit Campaign"}
                isOpen={isUpdateCampaignOpen}
                setIsOpen={setIsUpdateCampaignOpen}
            >
                <PpcForm
                    campaign={_.find({ campaignId: campaignToUpdateId }, openPractice.ppcCampaigns)}
                    superviseSubmit={handleUpdateSubmit}
                />
            </Modal>

            <Card gridColumn={"1 / -1"}>
                <Box
                    display={"flex"}
                    flexDirection={"row"}
                    justifyContent={"space-between"}
                    flexWrap={"wrap"}
                >
                    <Box>
                        <Heading data-testid={"pageHeadline"}>{"Pay Per Click Setup"}</Heading>
                        <Box mb={2} />
                        <Heading2 data-testid={"pageSubheader"}>
                            {openPractice.practiceName}
                        </Heading2>
                    </Box>
                    <Box alignSelf={"flex-end"}>
                        <EditButton
                            data-testid={"addCampaign"}
                            onClick={() => setIsManageCampaignModalOpen((s) => !s)}
                        >
                            manage campaigns
                        </EditButton>
                    </Box>
                </Box>
            </Card>

            {!_.isEmpty(openPractice.ppcCampaigns) &&
                openPractice.ppcCampaigns.map((campaign) => (
                    <Card data-testid={campaign.campaignId} key={campaign.campaignId}>
                        <Grid
                            backgroundColor={"chalk"}
                            py={6}
                            px={9}
                            gridTemplateColumns={"1fr 1fr"}
                            gridColumnGap={3}
                            gridRowGap={8}
                        >
                            <Box gridColumn={"1"}>
                                <UppercaseLabel>Campaign name</UppercaseLabel>
                                <Box mb={1} />
                                <BlueBody2>{campaign.campaignName}</BlueBody2>
                            </Box>
                            <Box gridColumn={"2"}>
                                <UppercaseLabel>customer name</UppercaseLabel>
                                <Box mb={1} />
                                <BlueBody3>{campaign.customerName}</BlueBody3>
                            </Box>

                            <Box gridColumn={"1"}>
                                <UppercaseLabel>Campaign id</UppercaseLabel>
                                <Box mb={1} />
                                <BlueBody2>{campaign.campaignId}</BlueBody2>
                            </Box>

                            <Box gridColumn={"2"}>
                                <UppercaseLabel>new patient goal</UppercaseLabel>
                                <Box mb={1} />
                                <BlueBody3>{_.get("goals.newPatients", campaign)}</BlueBody3>
                            </Box>

                            <Box gridColumn={"1/ -1"}>
                                <Body2>Recurly Campaign Id</Body2>
                            </Box>
                            <Box gridColumn={"1 / -1"}>
                                <BlueBody3>{campaign.recurlyCampaignId}</BlueBody3>
                            </Box>
                            <Box gridColumn={"1/ -1"}>
                                <Body2>Sub Campaigns</Body2>
                            </Box>
                            {_.map(
                                (subCampaign) => (
                                    <Box key={subCampaign.campaignId} gridColumn={"1 / -1"}>
                                        <BlueBody3>{subCampaign.campaignName}</BlueBody3>
                                    </Box>
                                ),
                                campaign.subCampaigns
                            )}

                            <Box gridColumn={"1/ -1"}>
                                <Body2>Linked Call Rail Accounts</Body2>
                            </Box>
                            {_.map(
                                (linkedCallRailTracker) => (
                                    <Box
                                        key={linkedCallRailTracker.trackerId}
                                        gridColumn={"1 / -1"}
                                    >
                                        <BlueBody3>{linkedCallRailTracker.name}</BlueBody3>
                                    </Box>
                                ),
                                campaign.linkedCallRailTrackers
                            )}
                            <Box>
                                <LabeledCheckbox checked={campaign.isActive} label={"Active"} />
                            </Box>
                            <Box gridColumn={2} justifySelf={"end"}>
                                <EditButton onClick={() => handleUpdateMode(campaign.campaignId)}>
                                    edit campaign
                                </EditButton>
                            </Box>
                        </Grid>
                    </Card>
                ))}
        </>
    );
};

function preprocess(campaigns) {
    if (!campaigns) {
        return [];
    }
    return campaigns.map((campaign) => ({
        data: campaign,
    }));
}
const postprocess = _.update("ppcCampaigns", _.map(_.get("data")));

function ManageCampaignsForm({ practice, setClosed }) {
    const dispatch = useDispatch();

    const ppcCampaigns = useSelector((state) => state.ppcCampaigns);
    const openPractice = useSelector((state) => state.openPractice);

    const { handleSubmit, control } = useForm({
        defaultValues: { ppcCampaigns: preprocess(practice.ppcCampaigns) },
        resolver: manageCampaignSchema,
    });

    const { fields, append, remove } = useFieldArray({
        control,
        name: "ppcCampaigns",
        keyName: "campaignId",
    });

    useLayoutEffect(() => {
        dispatch(actions.getPpcCampaigns());
    }, [dispatch]);

    function submitHandler(rawFormData) {
        const transformedUpdate = postprocess(rawFormData);

        dispatch(
            actions.updatePractice(openPractice._id, {
                ppcCampaigns: transformedUpdate.ppcCampaigns.map((campaign) => ({
                    ...campaign,
                    isActive: true,
                    goals: {
                        newPatients: 5,
                    },
                    subCampaigns: [],
                    linkedCallRailTrackers: [],
                    recurlyCampaignId: "",
                    _id: new ObjectID(),
                })),
            })
        );
        setClosed();
    }

    return (
        <Box>
            <Box mb={4}>
                <Body2>Ppc Campaigns</Body2>
            </Box>
            <form onSubmit={handleSubmit(submitHandler)}>
                <Grid gridTemplateColumns={"1fr 1fr"}>
                    {fields.map((field, index) => {
                        return (
                            <FlexRow mb={4} gridColumn={"1 / -1"} key={index}>
                                <Box flexGrow={1}>
                                    <Controller
                                        control={control}
                                        name={`ppcCampaigns[${index}].data`}
                                        render={({ onChange, value }) => {
                                            return (
                                                <Select
                                                    classNamePrefix={`ppcCampaigns${index}`}
                                                    value={_.filter(
                                                        { campaignId: _.get("campaignId", value) },
                                                        ppcCampaigns
                                                    )}
                                                    isOptionSelected={(campaign) =>
                                                        _.isEqual(value, campaign.campaignId)
                                                    }
                                                    menuPlacement={"auto"}
                                                    options={ppcCampaigns}
                                                    onChange={onChange}
                                                    getOptionLabel={(campaign) =>
                                                        `${campaign.campaignName} -- ${campaign.customerName} -- ${campaign.campaignId}`
                                                    }
                                                />
                                            );
                                        }}
                                    />
                                </Box>
                                <Box>
                                    <CrossButton
                                        color={"red"}
                                        onClick={() => remove(index)}
                                        type={"button"}
                                    />
                                </Box>
                            </FlexRow>
                        );
                    })}
                    <Box gridColumn={"1 / -1"}>
                        <AddButton
                            testid={"linkNewCampaign"}
                            onClick={() => append({})}
                            type={"button"}
                        />
                    </Box>

                    <Box gridColumn={"2"} justifySelf={"end"}>
                        <PrimaryButton type={"submit"}>submit</PrimaryButton>
                    </Box>
                </Grid>
            </form>
        </Box>
    );
}

function PpcForm({ superviseSubmit, campaign }) {
    const callRailTrackers = useSelector((state) => state.callRailAccounts);
    const addOns = useSelector((state) => state.googleAdsAddOns);
    const campaigns = useSelector((state) => state.ppcCampaigns);

    const { register, handleSubmit, errors, control } = useForm({
        defaultValues: transformCampaignInitialState(campaign),
        resolver: ppcCampaignSchema,
    });

    const subCampaignsField = useFieldArray({
        control,
        name: "subCampaigns",
    });

    const linkedCallRailTrackersField = useFieldArray({
        control,
        name: "linkedCallRailTrackers",
        keyName: "trackerId",
    });

    return (
        <Box>
            <Body2 mb={5}>
                {campaign.campaignName} -- {campaign.customerName}
            </Body2>

            <form onSubmit={handleSubmit(superviseSubmit)}>
                <input type={"hidden"} name={"campaignId"} ref={register} />
                <Box
                    display={"grid"}
                    gridColumnGap={4}
                    gridRowGap={8}
                    gridTemplateColumns={"repeat(auto-fill, minmax(280px, 1fr))"}
                >
                    <Box>
                        <UppercaseLabel>new patient goal</UppercaseLabel>
                        <Box mb={1} />
                        <SmallTextInput
                            type={"number"}
                            step={"any"}
                            name={"goals.newPatients"}
                            ref={register}
                        />
                        <Error error={errors.name} />
                    </Box>
                    <Box gridColumn=" 1 / -1">
                        <Body2>Recurly Campaign Id</Body2>
                        <Box pb={8} />
                        <FlexRow width={"100%"}>
                            <Box flexGrow={1}>
                                <Controller
                                    control={control}
                                    name={`recurlyCampaignId`}
                                    defaultValue={""}
                                    render={({ onChange, value }) => {
                                        return (
                                            <Select
                                                isOptionSelected={_.isEqual(value)}
                                                classNamePrefix={`recurlyCampaignId`}
                                                value={_.filter({ id: value }, addOns)}
                                                options={addOns}
                                                onChange={(addOn) => onChange(addOn.id)}
                                                getOptionLabel={(addOn) =>
                                                    `${_.get("unitAmount", addOn)}--${_.get(
                                                        "id",
                                                        addOn
                                                    )}`
                                                }
                                            />
                                        );
                                    }}
                                />
                            </Box>

                            <Box>
                                <Controller
                                    control={control}
                                    name={`recurlyCampaignId`}
                                    render={({ onChange }) => {
                                        return (
                                            <CrossButton
                                                color={"red"}
                                                onClick={() => onChange("")}
                                                type={"button"}
                                            />
                                        );
                                    }}
                                />
                            </Box>
                        </FlexRow>
                    </Box>
                    <Box gridColumn=" 1 / -1">
                        <Body2>Sub Campaigns</Body2>
                    </Box>
                    <SubCampaignsSelect
                        campaigns={campaigns}
                        control={control}
                        fieldArray={subCampaignsField}
                    />

                    <Box gridColumn=" 1 / -1">
                        <Body2>Linked Call Rail Trackers</Body2>
                    </Box>
                    <LinkedCallRailTrackersSelect
                        control={control}
                        fieldArray={linkedCallRailTrackersField}
                        callRailTrackers={callRailTrackers}
                    />
                    <Box />
                    <Box display={"flex"} flexDirection={"row"} justifyContent={"space-between"}>
                        <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
                            <Body3>Active</Body3>
                            <Box mr={3} />
                            <Controller
                                name={"isActive"}
                                control={control}
                                render={({ onChange, value }) => {
                                    return (
                                        <Checkbox
                                            checked={value}
                                            onClick={() => onChange(!value)}
                                        />
                                    );
                                }}
                            />
                        </Box>
                        <PrimaryButton type={"submit"}>submit</PrimaryButton>
                    </Box>
                </Box>
            </form>
        </Box>
    );
}

export function SubCampaignsSelect({ fieldArray, campaigns, control }) {
    return (
        <>
            {fieldArray.fields.map((field, index) => {
                return (
                    <FlexRow gridColumn={"1 / -1"} key={index}>
                        <Box flexGrow={1}>
                            <Controller
                                control={control}
                                name={`subCampaigns[${index}].data`}
                                defaultValue={field}
                                render={({ onChange, value }) => {
                                    return (
                                        <Select
                                            isOptionSelected={_.isEqual(value)}
                                            classNamePrefix={`subCampaigns${index}`}
                                            value={_.filter(
                                                { campaignId: value.campaignId },
                                                campaigns
                                            )}
                                            options={campaigns}
                                            onChange={onChange}
                                            getOptionLabel={(subCampaign) =>
                                                `${_.get("campaignName", subCampaign)} - ${_.get(
                                                    "customerName",
                                                    subCampaign
                                                )}`
                                            }
                                        />
                                    );
                                }}
                            />
                        </Box>
                        <Box>
                            <CrossButton
                                color={"red"}
                                onClick={() => fieldArray.remove(index)}
                                type={"button"}
                            />
                        </Box>
                    </FlexRow>
                );
            })}
            <Box gridColumn={"1 / -1"}>
                <AddButton
                    data-testid={"addSubCampaign"}
                    onClick={() => fieldArray.append({ data: null })}
                    type={"button"}
                />
            </Box>
        </>
    );
}
