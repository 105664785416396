import { Box, FlexCol, FlexRowAlignCenter } from "../StyledSystemUtilities";
import { SquareButton, TextButton } from "../Atoms/Inputs/Buttons";
import styled from "styled-components";
import { RawCheck } from "../Atoms/assets";
import { getColor } from "../../helpers/themeGetters";
import theme from "../../theme";
import { useRef } from "react";
import { useDetectOutsideClick } from "../../hooks/useDetectOutsideClick";

export const FilterDropDown = ({
    options,
    checkedOptions,
    onCancelClicked,
    onOkClicked,
    setFilterOptions,
    headerRef,
    ...props
}) => {
    const handleClearClicked = () => {
        setFilterOptions([]);
    };
    const onOptionClicked = (option) => {
        if (!checkedOptions.includes(option)) {
            setFilterOptions((prev) => [...prev, option]);
        } else {
            setFilterOptions((prev) => prev.filter((item) => item !== option));
        }
    };

    const dropDownRef = useRef(null);
    useDetectOutsideClick(dropDownRef, onCancelClicked);
    const headerPosition = headerRef.current.getBoundingClientRect();

    return (
        <Box
            ref={dropDownRef}
            backgroundColor={"chalk"}
            borderRadius={5}
            boxShadow={`5px 5px 5px ${theme.colors.slateGray}`}
            position={"absolute"}
            left={headerPosition.left}
            top={headerPosition.bottom}
            width={300}
            {...props}
        >
            {options.map((option, index) => (
                <FilterOption
                    key={index}
                    option={option}
                    isChecked={checkedOptions.includes(option)}
                    onClick={() => onOptionClicked(option)}
                />
            ))}

            <Box height={10} />
            <FlexCol justifyContent={"flex-end"} pr={5} pb={5}>
                <Box />
                <FlexRowAlignCenter justifyContent={"space-between"}>
                    <TextButton onClick={handleClearClicked}>Clear</TextButton>
                    <FlexRowAlignCenter>
                        <SquareButton onClick={onCancelClicked}>Cancel</SquareButton>
                        <Box width={5} />
                        <SquareButton onClick={onOkClicked}>OK</SquareButton>
                    </FlexRowAlignCenter>
                </FlexRowAlignCenter>
            </FlexCol>
        </Box>
    );
};

const Check = styled(RawCheck)`
    fill: ${getColor("sienna")};
    margin: 2px 0px 0px 1px;
`;

const FilterOption = ({ option, isChecked, onClick }) => {
    return (
        <FlexRowAlignCenter
            justifyContent={"space-between"}
            borderRadius={5}
            onClick={onClick}
            onMouseOver={(event) => (event.target.style.background = theme.colors.pearlGray)}
            onMouseOut={(event) => (event.target.style.background = theme.colors.chalk)}
            height={"40px"}
            px={5}
        >
            {option}
            {isChecked ? <Check /> : null}
        </FlexRowAlignCenter>
    );
};
