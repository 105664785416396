import { api } from "../utilityActions";
import slices from "../slices";
import {ententeUrl} from "../../config";
export default () =>
    api({
        call: {
            url: "/piston/secure/aggregated-reports/get-contract-periods-report",
            method: "get",
            baseURL: ententeUrl
        },
        success: slices.contractPeriodsReport.actions.set,
        label: "contractPeriodsReport",
    });
