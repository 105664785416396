import React from "react";
import { moneyRound } from "../../../utilities";
import { BlueBody4, Body2, Cell, FullCard, UppercaseLabel } from "../../Atoms";
import { Box, FlexCol, FlexRow } from "../../StyledSystemUtilities";

export const CampaignSummaryCard = ({ columns, title }) => {
    //columns : [{value, title}]
    return (
        <>
            <FullCard pb={4} backgroundColor={"chalk"} mb={5}>
                <UppercaseLabel>Summary &mdash; {title}</UppercaseLabel>
                <Box mb={6} />
                <FlexRow maxWidth={"400px"} justifyContent={"space-between"} flexWrap={"wrap"}>
                    {columns.map(({ value, label }, index) => (
                        <FlexCol flexShrink={0} alignItems={"center"} mb={3} key={index}>
                            <Cell>
                                <Body2>{moneyRound(value)}</Body2>
                            </Cell>
                            <Box mb={2} />
                            <BlueBody4>{label}</BlueBody4>
                        </FlexCol>
                    ))}
                </FlexRow>
                <Box mb={6} />
            </FullCard>
        </>
    );
};
