import React from "react";
import { useSelector } from "react-redux";
import { Route } from "react-router-dom";
import ErrorBoundary from "../../Utilities/ErrorBoundary";
import { PracticeInfo } from "../PracticeDashboard/PracticeInfo";
import { DirectMailSetup } from "../PracticeDashboard/DirectMailSetup";
import { PpcSetup } from "../PracticeDashboard/PpcSetup";

import _ from "lodash/fp";

const PracticeDashboard = () => {
    const openPractice = useSelector((state) => state.openPractice);

    if (_.isEmpty(openPractice)) {
        return null;
    }

    return (
        <>
            <Route
                path={`/dash/practice-list/practice-dashboard/practice-info`}
                render={() => (
                    <ErrorBoundary>
                        <PracticeInfo />
                    </ErrorBoundary>
                )}
            />
            <Route
                path={`/dash/practice-list/practice-dashboard/setup-ppc`}
                render={() => (
                    <ErrorBoundary>
                        <PpcSetup />
                    </ErrorBoundary>
                )}
            />
            <Route
                path={`/dash/practice-list/practice-dashboard/direct-mail/setup`}
                render={() => <DirectMailSetup />}
            />
            {/*<Route*/}
            {/*    exact*/}
            {/*    path={`/dash/practice-list/practice-dashboard/direct-mail`}*/}
            {/*    render={() => (*/}
            {/*        <ErrorBoundary>*/}
            {/*            <DirectMailReport />*/}
            {/*        </ErrorBoundary>*/}
            {/*    )}*/}
            {/*/>*/}
        </>
    );
};

export default PracticeDashboard;
