import { api } from "../utilityActions";
import loginWithToken from "./loginWithToken";
import {ententeUrl} from "../../config";

export default (currentPassword, newPassword) =>
    api({
        call: {
            url: "/piston/secure/users/change-password",
            method: "patch",
            data: {
                currentPassword,
                newPassword,
            },
            baseURL: ententeUrl
        },
        success: loginWithToken,
        label: "changePassword",
    });
