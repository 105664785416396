import { useDispatch, useSelector } from "react-redux";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import React, { useEffect } from "react";
import { actions } from "../../../redux";

import { transformCampaignInitialState } from "../../../utilities";
import { directMailCampaignSchema } from "../../../yupSchemas";
import { TextInputWithLabel } from "../../Molecules";
import { Box, FlexRow, Grid } from "../../StyledSystemUtilities";
import {
    AddButton,
    Checkbox,
    CrossButton,
    Body3,
    UppercaseLabel,
    PrimaryButton,
    Select,
    Body2,
    ModalFormContainer,
} from "../../Atoms";
import _ from "lodash/fp";

const months = _.range(0, 12).map((value) => ({ value, label: _.toString(value + 1) }));
const years = _.range(2019, 2030).map((value) => ({ value, label: _.toString(value) }));

function useGetCallRailTrackers() {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(actions.getCallRailAccounts());
    }, [dispatch]);
}

export const DirectMailCampaignForm = ({ superviseSubmit, initialState = {} }) => {
    const transformedInitialState = transformCampaignInitialState(initialState);
    const callRailTrackers = useSelector((state) => state.callRailAccounts);

    useGetCallRailTrackers();

    const { register, handleSubmit, errors, control } = useForm({
        defaultValues: transformedInitialState,
        resolver: directMailCampaignSchema,
    });

    const linkedCallRailTrackersField = useFieldArray({
        control,
        name: "linkedCallRailTrackers",
        keyName: "key",
    });

    const mailingPeriodsField = useFieldArray({
        control,
        name: "mailingPeriods",
        keyName: "key",
    });

    return (
        <Box>
            <form onSubmit={handleSubmit(superviseSubmit)}>
                <ModalFormContainer>
                    <TextInputWithLabel
                        label={"campaign name"}
                        path={"name"}
                        register={register}
                        errors={errors}
                    />
                    <TextInputWithLabel
                        label={"new patient goal"}
                        register={register}
                        errors={errors}
                        path={"goals.newPatients"}
                    />
                    <Box gridColumn="1 / -1">
                        <Body2>Linked Call Rail Accounts</Body2>
                    </Box>
                    <LinkedCallRailTrackersSelect
                        control={control}
                        fieldArray={linkedCallRailTrackersField}
                        callRailTrackers={callRailTrackers}
                    />

                    <Box gridColumn=" 1 / -1">
                        <Body2>Mailing Periods</Body2>
                    </Box>
                    <Grid
                        gridColumn={"1/-1"}
                        gridTemplateColumns={"1fr 1fr 1fr 1fr 1fr 1fr auto"}
                        gridRowGap={5}
                    >
                        {mailingPeriodsField.fields.map((field, index) => {
                            return (
                                <React.Fragment key={index}>
                                    <Box>
                                        <UppercaseLabel>Start Month</UppercaseLabel>
                                        <Box mb={1} />

                                        <Controller
                                            name={`mailingPeriods[${index}].startPeriod.month`}
                                            control={control}
                                            defaultValue={_.get("startPeriod.month", field)}
                                            render={({ onChange, value }) => (
                                                <Select
                                                    classNamePrefix={`mailingPeriods_${index}_startPeriod_month`}
                                                    onChange={(option) => onChange(option.value)}
                                                    value={_.filter({ value: value }, months)}
                                                    options={months}
                                                />
                                            )}
                                        />
                                    </Box>

                                    <Box>
                                        <UppercaseLabel>Start Year</UppercaseLabel>
                                        <Box mb={1} />
                                        <Controller
                                            name={`mailingPeriods[${index}].startPeriod.year`}
                                            control={control}
                                            defaultValue={_.get("startPeriod.year", field)}
                                            render={({ onChange, value }) => (
                                                <Select
                                                    classNamePrefix={`mailingPeriods_${index}_startPeriod_year`}
                                                    onChange={(option) => onChange(option.value)}
                                                    value={_.filter({ value: value }, years)}
                                                    options={years}
                                                />
                                            )}
                                        />
                                    </Box>
                                    <Box>
                                        <UppercaseLabel>End Month</UppercaseLabel>
                                        <Box mb={1} />
                                        <Controller
                                            name={`mailingPeriods[${index}].endPeriod.month`}
                                            control={control}
                                            defaultValue={_.get("endPeriod.month", field)}
                                            render={({ onChange, value }) => (
                                                <Select
                                                    classNamePrefix={`mailingPeriods_${index}_endPeriod_month`}
                                                    onChange={(option) => onChange(option.value)}
                                                    isOptionSelected={_.isEqual(value)}
                                                    value={_.filter({ value: value }, months)}
                                                    options={months}
                                                />
                                            )}
                                        />
                                    </Box>

                                    <Box>
                                        <UppercaseLabel>End Year</UppercaseLabel>
                                        <Box mb={1} />
                                        <Controller
                                            name={`mailingPeriods[${index}].endPeriod.year`}
                                            control={control}
                                            defaultValue={_.get("endPeriod.year", field)}
                                            render={({ onChange, value }) => (
                                                <Select
                                                    classNamePrefix={`mailingPeriods_${index}_endPeriod_year`}
                                                    onChange={(option) => onChange(option.value)}
                                                    value={_.filter({ value: value }, years)}
                                                    options={years}
                                                />
                                            )}
                                        />
                                    </Box>

                                    <Box>
                                        <TextInputWithLabel
                                            type={"number"}
                                            step={"any"}
                                            defaultValue={field.budget}
                                            label={"total budget"}
                                            errors={errors}
                                            path={`mailingPeriods[${index}].budget`}
                                            register={register()}
                                        />
                                    </Box>
                                    <Box>
                                        <TextInputWithLabel
                                            type={"number"}
                                            step={"any"}
                                            defaultValue={field.numberOfMailers}
                                            label={"number of mailers"}
                                            errors={errors}
                                            path={`mailingPeriods[${index}].numberOfMailers`}
                                            register={register()}
                                        />
                                    </Box>

                                    <Box>
                                        <CrossButton
                                            color={"red"}
                                            onClick={() => mailingPeriodsField.remove(index)}
                                            type={"button"}
                                        />
                                    </Box>
                                </React.Fragment>
                            );
                        })}
                    </Grid>
                    <Box gridColumn={"1 / -1"}>
                        <AddButton
                            data-testid={"addMailingPeriod"}
                            onClick={() => mailingPeriodsField.append({})}
                            type={"button"}
                        />
                    </Box>
                    <Box />
                    <FlexRow justifyContent={"space-between"}>
                        <FlexRow alignItems={"center"}>
                            <Body3>Active</Body3>
                            <Box mr={3} />
                            <Controller
                                name={"active"}
                                control={control}
                                render={({ onChange, value }) => {
                                    return (
                                        <Checkbox
                                            checked={value}
                                            onClick={() => onChange(!value)}
                                        />
                                    );
                                }}
                            />
                        </FlexRow>
                        <PrimaryButton type={"submit"}>submit</PrimaryButton>
                    </FlexRow>
                </ModalFormContainer>
            </form>
        </Box>
    );
};

export function LinkedCallRailTrackersSelect({ fieldArray, callRailTrackers, control }) {
    return (
        <>
            {fieldArray.fields.map((field, index) => {
                return (
                    <FlexRow gridColumn={"1 / -1"} key={index}>
                        <Box flexGrow={1}>
                            <Controller
                                control={control}
                                name={`linkedCallRailTrackers[${index}].data`}
                                defaultValue={field.data}
                                render={({ onChange, value }) => {
                                    return (
                                        <Select
                                            isOptionSelected={_.isEqual(value)}
                                            classNamePrefix={`linkedCallRailTrackers${index}`}
                                            value={_.filter(
                                                _.flow(
                                                    _.get("trackerId"),
                                                    _.isEqual(_.get("trackerId", value))
                                                ),
                                                callRailTrackers
                                            )}
                                            options={callRailTrackers}
                                            onChange={onChange}
                                            getOptionLabel={(tracker) =>
                                                `${_.get("name", tracker)}--${_.get(
                                                    "trackerId",
                                                    tracker
                                                )}`
                                            }
                                        />
                                    );
                                }}
                            />
                        </Box>
                        <Box>
                            <CrossButton
                                color={"red"}
                                onClick={() => fieldArray.remove(index)}
                                type={"button"}
                            />
                        </Box>
                    </FlexRow>
                );
            })}
            <Box gridColumn={"1 / -1"}>
                <AddButton
                    data-testid={"addTrackingId"}
                    onClick={() => fieldArray.append({ data: null })}
                    type={"button"}
                />
            </Box>
        </>
    );
}
