import { createSlice } from "@reduxjs/toolkit";

export default createSlice({
    name: "ppcCampaigns",
    initialState: [],
    reducers: {
        set: (state, action) => action.payload,
        reset: (state) => [],
    },
});
