import jwtDecode from "jwt-decode";
import _ from "lodash/fp";
import React, { Fragment, useEffect, useLayoutEffect } from "react";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route, Router, Switch } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { Normalize } from "styled-normalize";

import { ChooseNewPassword, Login, ResetPassword } from "./Components/Pages";
import { AdminMain, EmployeeMain } from "./Components/Pages/employee/EmployeeMain";

import ErrorBoundary from "./Components/Utilities/ErrorBoundary";

import PrivateRoute from "./PrivateRoute/PrivateRoute";

import { actions, slices, store } from "./redux";
import { routerHistory } from "./routerHistory";

import theme from "./theme";
import setAuthToken from "./utilities/setAuthToken";
import GlobalStyle from "./GlobalStyle";

handleResize();

const App = () => {
    const currentUser = useSelector((state) => state.currentUser);
    const isAuthenticated = currentUser.isAuthenticated;
    const isAdmin = currentUser?.user?.positions?.includes("ADMIN") ?? false;

    useTokenInitialization();
    useGetUserType();

    return (
        <Fragment>
            <Normalize />
            <GlobalStyle />
            <ThemeProvider theme={theme}>
                <Router history={routerHistory}>
                    <Switch>
                        <Route
                            path={"/login"}
                            render={() => (
                                <ErrorBoundary>
                                    <Login />
                                </ErrorBoundary>
                            )}
                        />
                        <Route
                            path={"/reset-password"}
                            render={() => (
                                <ErrorBoundary>
                                    <ResetPassword />
                                </ErrorBoundary>
                            )}
                        />
                        <Route
                            path={"/new-password/:passwordResetToken"}
                            render={() => (
                                <ErrorBoundary>
                                    <ChooseNewPassword />
                                </ErrorBoundary>
                            )}
                        />

                        <PrivateRoute path={"/dash"} render={() => renderMain(isAdmin)} />

                        <Route>
                            {isAuthenticated ? (
                                <Redirect to="/dash/practice-list" />
                            ) : (
                                <Redirect to={"/login"} />
                            )}
                        </Route>
                    </Switch>
                </Router>
            </ThemeProvider>
        </Fragment>
    );
};

export default App;

function renderMain(isAdmin) {
    if (isAdmin) {
        return <AdminMain />;
    }

    return <EmployeeMain />;
}

function handleResize() {
    const handleResize = _.debounce(150, () =>
        store.dispatch(
            slices.dimensions.actions.set("viewport", {
                width: window.innerWidth,
                height: window.innerHeight,
            })
        )
    );

    window.addEventListener("resize", () => {
        handleResize();
    });
}

function useTokenInitialization() {
    const dispatch = useDispatch();
    const currentUser = useSelector((state) => state.currentUser);
    const isAuthenticated = currentUser.isAuthenticated;
    useLayoutEffect(() => {
        if (localStorage.jwtToken && !isAuthenticated) {
            const token = localStorage.jwtToken;

            const decoded = jwtDecode(token);
            const currentTime = Date.now() / 1000;

            if (decoded.exp < currentTime) {
                dispatch(actions.logout());
                routerHistory.push("/login");
            } else {
                setAuthToken(token);
                dispatch(slices.currentUser.actions.setUserId(decoded._id));

                routerHistory.push("/dash/practice-list");
            }
        }
    }, [isAuthenticated, dispatch]);
}

function useGetUserType() {
    const dispatch = useDispatch();
    const isAuthenticated = useSelector((state) => state.currentUser.isAuthenticated);
    useEffect(() => {
        if (localStorage.jwtToken) {
            dispatch(actions.getUser());
        }
    }, [isAuthenticated, dispatch]);
}
