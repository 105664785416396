import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";

import "react-vis/dist/style.css";
import App from "./App";

import ErrorBoundary from "./Components/Utilities/ErrorBoundary";
import { store } from "./redux";
import * as serviceWorker from "./serviceWorker";

ReactDOM.render(
    <ErrorBoundary>
        <Provider store={store}>
            <App />
        </Provider>
    </ErrorBoundary>,

    document.getElementById("root")
);

console.log(process.env.NODE_ENV);

if (window.Cypress) {
    window.store = store;
}
serviceWorker.unregister();
