import { useDispatch, useSelector } from "react-redux";
import { Body2, FullCard, Heading, Heading2 } from "../../Atoms";
import { Box, FlexRow, Grid, Span } from "../../StyledSystemUtilities";
import { getDateDisplayText } from "./LoginHistory";
import { format, subMonths } from "date-fns/fp";
import getAllLoginEventsForAccount from "../../../redux/actions/getAllLoginEventsForUser";
import React, { useEffect, useRef, useState } from "react";
import { useApi } from "../../../hooks/useApi";
import axios from "axios";
import { ententeUrl } from "../../../config";

export function IndividualLoginHistory() {
    const [pageNumber, setPageNumber] = useState(0);
    const { account } = useSelector((state) => state.accountEventHistory);

    const [nullableEvents, isLoading] = useApi(async () => {
        return (
            await axios.post(
                "/piston/secure/events/get",
                {
                    pageNumber,
                    accountId: account._id,
                },
                { baseURL: ententeUrl }
            )
        ).data;
    }, [pageNumber]);

    const events = nullableEvents ?? [];

    const decrementPage = () => {
        if (isLoading) {
            return;
        }
        if (pageNumber === 0) {
            return;
        }

        setPageNumber((prev) => prev - 1);
    };

    const incrementPage = () => {
        if (isLoading) {
            return;
        }

        if (events.length === 0) {
            return;
        }

        setPageNumber((prev) => prev + 1);
    };

    return (
        <div>
            <FullCard>
                <Heading>{account.accountName}</Heading>
                <Box height={2} />
                <Heading2>Login History</Heading2>
                <Box>
                    <Span onClick={decrementPage} cursor={"pointer"}>
                        ⬅️
                    </Span>
                    <Span userSelect={"none"}> page {pageNumber + 1} </Span>
                    <Span onClick={incrementPage} cursor={"pointer"}>
                        ➡️
                    </Span>
                </Box>
            </FullCard>
            {events.length ? (
                <FullCard>
                    <TableHeadings />
                    <Box height={4} />
                    {events.map((event, index) => (
                        <Row key={index} event={event} />
                    ))}
                </FullCard>
            ) : (
                <FullCard>
                    <Box height={200} />
                    <FlexRow justifyContent={"center"} alignItems={"center"}>
                        <Body2>No Login Events</Body2>
                    </FlexRow>
                </FullCard>
            )}
        </div>
    );
}

function TableGrid({ children }) {
    return (
        <Grid gridTemplateColumns={".75fr 1fr .75fr 1fr"} minWidth={"900px"}>
            {children}
        </Grid>
    );
}

function TableHeadings() {
    return (
        <TableGrid>
            <Box pl={5}>
                <Heading2>User Name</Heading2>
            </Box>
            <Box pl={5}>
                <Heading2>Email</Heading2>
            </Box>
            <Box pl={5}>
                <Heading2>Device</Heading2>
            </Box>
            <Box pl={5}>
                <Heading2>Login Date</Heading2>
            </Box>
        </TableGrid>
    );
}

function Row({ event }) {
    const clientUsers = useSelector((state) => state.clientUsers);

    let recentLogin;
    let user;
    if (event.timestamp) {
        recentLogin = getDateDisplayText(event.timestamp);
        user = clientUsers.find((user) => user._id === event.userId);
    }

    return (
        <TableGrid>
            <Box gridColumn={"1/-1"} borderBottom={"1px solid grey"} />
            <FlexRow alignItems={"center"} p={5}>
                <Body2>
                    {user.firstName} {user.lastName}
                </Body2>
            </FlexRow>
            <FlexRow alignItems={"center"} p={5}>
                <Body2>{user.email}</Body2>
            </FlexRow>
            <FlexRow alignItems={"center"} p={5}>
                <Body2>{event.deviceType === "MOBILE" ? "Mobile" : "Desktop"}</Body2>
            </FlexRow>
            <FlexRow alignItems={"center"} p={5}>
                <Body2>
                    {recentLogin} - {format("P", event.timestamp)}
                </Body2>
            </FlexRow>
        </TableGrid>
    );
}
