import React from "react";
import styled from "styled-components";
import { format } from "date-fns";
import _ from "lodash/fp";

const RadialChart = ({
    currentPercentage,
    originalPercentage,
    originalVisibilityPercentageDate
}) => {
    const scalar = 0.8;
    const currentPercentageScaled = currentPercentage * scalar;

    const largeCircle = {
        r: 15.91549430918954,
        dashArray: [originalPercentage, 100 - originalPercentage],
        offset: 25 - (100 - originalPercentage)
    };
    const smallCircle = {
        r: largeCircle.r * scalar,
        dashArray: [currentPercentageScaled, 80 - currentPercentageScaled],
        offset: 20 - (80 - currentPercentageScaled)
    };

    return (
        <svg width="100%" height="100%" viewBox="2 2 37 37">
            <style>{`.small { font-size: 10px}`}</style>
            <circle
                cx="21"
                cy="21"
                r="15.91549430918954"
                fill="none"
                stroke="#fff"
                strokeWidth="2.5"
            />
            <circle
                cx="21"
                cy="21"
                r={`${largeCircle.r}`}
                fill="none"
                stroke="#B6C3D0"
                strokeDasharray={`${largeCircle.dashArray.join(" ")}`}
                strokeDashoffset={`${largeCircle.offset}`}
                strokeWidth="2.5"
            />

            <text fill={"#517992"} fontSize={2} x="55%" y="40%" textAnchor="middle">
                Current
            </text>
            <text fill={"#517992"} fontSize={6} x="57%" y="56%" textAnchor="middle">
                {_.round(currentPercentage)}%
            </text>
            <text fill={"#B6C3D0"} fontSize={2} x="55%" y="66%" textAnchor="middle">
                {format(originalVisibilityPercentageDate, "MMM y")}
            </text>
            <text fill={"#B6C3D0"} fontSize={3} x="56%" y="76%" textAnchor="middle">
                {_.round(originalPercentage)}%
            </text>

            <circle
                cx="21"
                cy="21"
                r={`${smallCircle.r}`}
                fill="none"
                stroke="#fff"
                strokeWidth="2.5"
            />
            <circle
                cx="21"
                cy="21"
                r={`${smallCircle.r}`}
                fill="none"
                stroke="#517992"
                strokeDasharray={`${smallCircle.dashArray.join(" ")}`}
                strokeDashoffset={`${smallCircle.offset}`}
                strokeWidth="2.5"
            />
        </svg>
    );
};

const DoughnutContainer = styled.div`
    margin: 0 auto;
    max-width: 250px;
    max-height: 250px;
`;

export const Doughnut = ({ currentVisibilityPercentage, originalVisibilityPercentage, originalVisibilityPercentageDate }) => {
    return (
        <DoughnutContainer>
            <RadialChart
                currentPercentage={currentVisibilityPercentage}
                originalPercentage={originalVisibilityPercentage}
                originalVisibilityPercentageDate={originalVisibilityPercentageDate}
            />
        </DoughnutContainer>
    );
};
