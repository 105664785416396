import _ from "lodash/fp";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { slices } from "../redux";

const useErrors = (label) => {
    const dispatch = useDispatch();
    const serverErrors = useSelector((store) => store.serverErrors);

    useEffect(() => {
        dispatch(slices.serverErrors.actions.unset(label));
    }, [dispatch, label]);

    return _.get([label, "message"], serverErrors);
};
export default useErrors;
