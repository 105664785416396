import styled from "styled-components";
import {
    border,
    display,
    flexbox,
    grid,
    space,
    color,
    layout,
    system,
    typography,
    position,
    compose,
    shadow,
} from "styled-system";
import React from "react";

export const allStyleFunctions = compose(
    display,
    grid,
    space,
    flexbox,
    border,
    color,
    layout,
    typography,
    position,
    shadow,
    system({
        gridColumnEnd: true,
        flexBasis: true,
        cursor: true,
        userSelect: true,
        pointer: {
            property: "cursor",
            transform: () => "pointer",
        },
        noUserSelect: {
            property: "user-select",
            transform: () => "none",
        },
        spanAllColumns: {
            property: "gridColumns",
            transform: () => "1/-1",
        },
        uppercase: {
            property: "textTransform",
            transform: () => "uppercase",
        },
        underlineOnHover: {
            property: "&:hover",
            transform: () => ({
                textDecoration: "underline",
            }),
        },
    })
);

export const Box = styled.div(allStyleFunctions);

const Flex = (props) => <Box display={"flex"} {...props} />;

export const FlexRow = (props) => <Flex flexDirection={"row"} {...props} />;

export const Grid = (props) => <Box display={"grid"} {...props} />;
export const FlexCol = (props) => <Flex flexDirection={"column"} {...props} />;

export const Span = (props) => <Box display={"inline"} {...props} />;
export const FlexRowAlignCenter = (props) => <FlexRow alignItems={"center"} {...props} />;
