import React from "react";
import styled, { css } from "styled-components";
import { color, typography } from "styled-system";
import { getColor } from "../../helpers/themeGetters";
import { allStyleFunctions, Box } from "../StyledSystemUtilities";

const baseTypography = css`
    font-weight: normal;
    margin: 0;
    font-family: Arial;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
    color: ${getColor("agateGray")};
    ${typography};
    ${color};
`;

export const Text = styled(Box)`
    font-weight: normal;
    margin: 0;
    font-family: Arial;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
    color: ${getColor("agateGray")};
    ${typography};
    ${color};
    ${allStyleFunctions}//line-height: 20px;
`;

export const Heading = (props) => (
    <Text
        as={"h1"}
        color={"nauticBlue"}
        fontSize={6}
        fontFamily={"montserrat"}
        fontWeight={400}
        {...props}
    />
);

export const Heading2 = (props) => (
    <Text as={"h2"} color={"balticBlue"} fontSize={5} fontFamily={"montserrat"} {...props} />
);

const BaseBody = (props) => <Text as={"p"} {...props} />;

//Body1 -- 16
export const Body1 = (props) => <BaseBody fontSize={4} {...props} />;
export const BlackBody1 = (props) => <Body1 color={"black"} {...props} />;
//Body2 -- 14
export const Body2 = (props) => <BaseBody fontSize={3} {...props} />;
export const BlueBody2 = (props) => <Body2 color={"balticBlue"} {...props} />;
export const BlackBody2 = (props) => <Body2 color={"black"} {...props} />;

//Body3 -- 12
export const Body3 = (props) => <BaseBody fontSize={2} {...props} />;
export const BlueBody3 = (props) => <Body3 color={"balticBlue"} {...props} />;
export const BlackBody3 = (props) => <Body3 color={"black"} {...props} />;

export const Body4 = (props) => <BaseBody fontSize={1} {...props} />;
export const BlueBody4 = (props) => <Body3 color={"balticBlue"} {...props} />;

export const UppercaseLabel = styled.label`
    ${baseTypography};
    font-size: 9px;
    color: ${getColor("slateGray")};
    text-transform: uppercase;
    letter-spacing: 0.05em;
    font-family: Roboto;
    ${allStyleFunctions}
`;
