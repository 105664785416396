import React from "react";
import styled from "styled-components";

const ErrorMessage = styled.p`
    color: #e94f5f;
`;

export const Error = ({ error }) => {
    if (error) {
        return <ErrorMessage>{error.message}</ErrorMessage>;
    } else {
        return null;
    }
};
