import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import useErrors from "../../hooks/useErrors";
import { actions } from "../../redux";
import { changePasswordSchema } from "../../yupSchemas";
import {
    BlueBody2,
    BlueBody3,
    Card,
    ErrorAlert,
    ModalFormContainer,
    PrimaryButton,
    UppercaseLabel,
} from "../Atoms";
import { PageHeading, TextInputWithLabel } from "../Molecules";
import { Modal } from "../Organisms";
import { Box, Grid } from "../StyledSystemUtilities";

export const MyAccount = () => {
    const myAccount = useSelector((state) => state.myAccount);
    const dispatch = useDispatch();
    const changePasswordError = useErrors("changePassword");

    const [openModal, setOpenModel] = useState("");

    useEffect(() => {
        dispatch(actions.getMyAccount());
    }, [dispatch]);

    const handleChangePassword = ({ currentPassword, newPassword }) => {
        dispatch(actions.changePassword(currentPassword, newPassword));
        setOpenModel("");
    };

    const handleLogoutClick = () => dispatch(actions.logout());

    return (
        <>
            <ErrorAlert message={changePasswordError} />
            <Modal
                title={"Change Your Password"}
                isOpen={openModal === "CHANGE_PASSWORD"}
                setIsOpen={() => setOpenModel("")}
            >
                <ChangePassword superviseSubmit={handleChangePassword} setOpenModal />
            </Modal>
            <PageHeading headlineText={"My Account"} subHeaderText={myAccount.name} />

            <Card>
                <Grid gridTemplateColumns={"1fr 1fr"} gridColumnGap={3} gridRowGap={10}>
                    <Box>
                        <UppercaseLabel mb={1}>name</UppercaseLabel>
                        <BlueBody3>{myAccount.name}</BlueBody3>
                    </Box>
                    <Box>
                        <UppercaseLabel mb={1}>email</UppercaseLabel>
                        <BlueBody3>{myAccount.email}</BlueBody3>
                    </Box>
                    <Box>
                        <UppercaseLabel mb={1}>positions</UppercaseLabel>
                        {myAccount.positions.map((position) => (
                            <BlueBody3>{position}</BlueBody3>
                        ))}
                    </Box>
                </Grid>
            </Card>
            <Card></Card>
            <Card>
                <BlueBody2
                    role={"link"}
                    onClick={() => setOpenModel("CHANGE_PASSWORD")}
                    mb={6}
                    pointer
                >
                    Change Your Password
                </BlueBody2>

                <BlueBody2 role={"link"} underlineOnHover onClick={handleLogoutClick} pointer>
                    Log Out
                </BlueBody2>
            </Card>
        </>
    );
};

const ChangePassword = ({ superviseSubmit }) => {
    const { register, errors, handleSubmit } = useForm({
        resolver: changePasswordSchema,
    });

    return (
        <form onSubmit={handleSubmit(superviseSubmit)}>
            <ModalFormContainer>
                <TextInputWithLabel
                    errors={errors}
                    label={"current password"}
                    register={register}
                    path={"currentPassword"}
                />
                <TextInputWithLabel
                    errors={errors}
                    label={"new password"}
                    register={register}
                    path={"newPassword"}
                />
                <TextInputWithLabel
                    errors={errors}
                    label={"confirm new password"}
                    register={register}
                    path={"confirmNewPassword"}
                />
                <PrimaryButton type={"submit"}>submit</PrimaryButton>
            </ModalFormContainer>
        </form>
    );
};
