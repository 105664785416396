import { createSlice } from "@reduxjs/toolkit";

const initialState = { account: {}, events: [], isMore: false, last: null };

export default createSlice({
    name: "accountEventHistory",
    initialState: initialState,
    reducers: {
        setEvents: (state, action) => {
            console.log("events: ", action.payload.events);
            return {
                ...state,
                events: [...state.events, ...action.payload.events],
                isMore: action.payload.isMore,
                last: action.payload.last,
            };
        },
        clear: () => initialState,
        setAccount: (state, action) => ({ ...state, account: action.payload }),
    },
});
