import { api } from "../utilityActions";
import loginWithToken from "./loginWithToken";

export default (credentials) =>
    api({
        call: {
            url: "/piston/open/authentication/login",
            method: "post",
            data: credentials,
            baseURL: process.env.REACT_APP_ENTENTE_URL
        },
        success: loginWithToken,
        label: "login",
    });
