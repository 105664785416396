import styled from "styled-components";
import { RawLogo, RawSmallLogo } from "./assets";

export const LargeLogo = styled(RawLogo)`
    display: block;
    box-sizing: border-box;
    height: 116px;
    width: 170px;
`;

export const SmallLogo = styled(RawSmallLogo)`
    display: block;
    box-sizing: border-box;
    height: 95px;
    width: 140px;
`;
