import { createSlice } from "@reduxjs/toolkit";

export default createSlice({
    name: "googleAccounts",
    initialState: {
        gaAccounts: [],
        gmbAccounts: [],
        searchConsoleAccounts: [],
    },
    reducers: {
        set: (state, action) => ({
            ...state,
            ...action.payload,
        }),
    },
});
