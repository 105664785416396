import { api } from "../utilityActions";
import slices from "../slices";
import {ententeUrl} from "../../config";
const getClientUsers = () =>
    api({
        call: {
            url: "/piston/secure/clients/get-client-users",
            method: "get",
            baseURL: ententeUrl
        },
        success: slices.clientUsers.actions.set,
    });

export default getClientUsers;
