import _ from "lodash/fp";

export const deepMap = (func, struct) => {
    if (_.isDate(struct)) {
        return func(struct);
    }
    if (_.isArray(struct)) {
        return _.map(x => deepMap(func, x), struct);
    }
    if (_.isPlainObject(struct)) {
        return _.mapValues(x => deepMap(func, x), struct);
    }
    return func(struct);
};

export const round = (number, decimals = 0) => {
    const scalar = Math.pow(10, decimals);
    return Math.round(number * scalar) / scalar;
};





