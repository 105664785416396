import { api } from "../utilityActions";
import slices from "../slices";
import {ententeUrl} from "../../config";
export default () =>
    api({
        call: {
            url: "/piston/secure/admin/manage-employees/get-all-employees",
            method: "get",
            baseURL: ententeUrl
        },
        success: slices.users.actions.set,
    });
