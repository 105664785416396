import _ from "lodash/fp";

const addPx = (number) => `${number}px`;

export const getColor = (color) => _.get(["theme", "colors", color]);

const baseNumberGetter = _.curry((name, key) => _.flow([_.get(["theme", name, key]), addPx]));

export const getSize = baseNumberGetter("sizes");

export const getSpace = baseNumberGetter("space");

export const getFontSize = baseNumberGetter("fontSizes");

export const getBorder = (border) => _.get(["theme", "borders", border]);
