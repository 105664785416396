import { createSlice } from "@reduxjs/toolkit";

const ppcReportInitialState = { activeCampaigns: [], pastCampaigns: [] };

export default createSlice({
    name: "ppcReport",
    initialState: ppcReportInitialState,
    reducers: {
        set: (state, action) => action.payload,
        reset: () => ppcReportInitialState,
    },
});
