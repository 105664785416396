import _ from "lodash/fp";
import React from "react";
import { Controller, useForm } from "react-hook-form";

import { useSelector } from "react-redux";
import practiceTypeOptions from "../../../practiceTypeOptions";
import { practiceSchema } from "../../../yupSchemas";
import {
    Body3,
    Checkbox,
    DatePicker,
    Error,
    ModalFormContainer,
    PrimaryButton,
    Select,
    TextAreaInput,
    UppercaseLabel,
} from "../../Atoms";
import { TextInputWithLabel } from "../../Molecules";
import { Box, FlexRow } from "../../StyledSystemUtilities";
import getHostName from "../../../utilities/getHostName";

const defaultInitialState = {
    perPatientValue: 650,
    active: true,
    type: practiceTypeOptions[0],
    startDate: new Date(),
};

const pmsTypeOptions = ["dentrix", "eaglesoft", "openDental", "dentrixAscend", "other", "unknown"];

export const PracticeForm = ({ superviseSubmit, initialState = defaultInitialState }) => {
    const googleAccounts = useSelector((state) => state.googleAccounts);
    const callRailCompanies = useSelector((state) => state.callRailCompanies);
    const recurlyAccounts = useSelector((state) => state.recurlyAccounts);
    const adviceLocalAccounts = useSelector((state) => state.adviceLocalAccounts);
    const veloxPractices = useSelector((state) => state.veloxPractices);
    const swellLocations = useSelector((state) => state.swellLocations);

    const { register, handleSubmit, errors, control } = useForm({
        defaultValues: initialState,
        resolver: practiceSchema,
    });

    return (
        <Box>
            <form onSubmit={handleSubmit(superviseSubmit)}>
                <ModalFormContainer>
                    <TextInputWithLabel
                        label={"strategist"}
                        errors={errors}
                        path={"strategist"}
                        register={register}
                    />
                    <TextInputWithLabel
                        errors={errors}
                        register={register}
                        path={"practiceName"}
                        label={"practice name"}
                    />

                    <Box>
                        <UppercaseLabel>Practice Type</UppercaseLabel>
                        <Box mb={1} />
                        <Controller
                            name={"type"}
                            render={({ onChange, value }) => {
                                return (
                                    <Select
                                        value={_.filter(_.isEqual(value), practiceTypeOptions)}
                                        options={practiceTypeOptions}
                                        onChange={(val) => onChange(val)}
                                        getOptionValue={(x) => x}
                                        getOptionLabel={_.startCase}
                                    />
                                );
                            }}
                            control={control}
                        />
                        <Error error={errors.practiceType} />
                    </Box>
                    <Box data-testid={"startDateContainer"}>
                        <UppercaseLabel>Relationship Start Date</UppercaseLabel>
                        <Box mb={1} />
                        <Controller
                            name={"startDate"}
                            control={control}
                            render={({ onChange, value, onBlur }) => (
                                <DatePicker
                                    maxDate={new Date()}
                                    onChange={onChange}
                                    selected={value}
                                    onBlur={onBlur}
                                />
                            )}
                        />
                        <Error error={errors.startDate} />
                    </Box>
                    <TextInputWithLabel
                        type={"number"}
                        register={register}
                        errors={errors}
                        label={"per patient value"}
                        path={"perPatientValue"}
                    />
                    <TextInputWithLabel
                        path={"primaryContact.name"}
                        label={"primary contact"}
                        errors={errors}
                        register={register}
                    />
                    <TextInputWithLabel
                        path={"primaryContact.label"}
                        label={"role of primary contact"}
                        errors={errors}
                        register={register}
                    />
                    <TextInputWithLabel
                        path={"primaryContact.email"}
                        label={"primary contact email"}
                        errors={errors}
                        register={register}
                    />
                    <TextInputWithLabel
                        path={"primaryContact.phone"}
                        label={"primary contact phone"}
                        errors={errors}
                        register={register}
                    />
                    <Box gridColumn={"1 / -1"}>
                        <UppercaseLabel>Practice Management Software Type</UppercaseLabel>
                        <Box mb={1} />
                        <Controller
                            name={"pmsType"}
                            render={({ onChange, value }) => {
                                return (
                                    <Select
                                        value={_.filter(_.isEqual(value), pmsTypeOptions)}
                                        options={pmsTypeOptions}
                                        onChange={(val) => onChange(val)}
                                        getOptionValue={(x) => x}
                                        getOptionLabel={_.startCase}
                                    />
                                );
                            }}
                            control={control}
                        />
                        <Error error={errors.pmsType} />
                    </Box>

                    <Box gridColumn={"1 / -1"}>
                        <UppercaseLabel>Google My Business</UppercaseLabel>
                        <Box mb={1} />

                        <Controller
                            name={"googleMyBusinessAccountId"}
                            render={({ onChange, value }) => {
                                return (
                                    <Select
                                        classNamePrefix={"gmbAccount"}
                                        menuPlacement="top"
                                        value={_.filter({ id: value }, googleAccounts.gmbAccounts)}
                                        options={googleAccounts.gmbAccounts}
                                        onChange={(googleMyBusinessAccount) =>
                                            onChange(googleMyBusinessAccount.id)
                                        }
                                        getOptionValue={(x) => x}
                                        getOptionLabel={(gmbAccount) =>
                                            `${gmbAccount.name} -- ${getHostName(
                                                gmbAccount.url
                                            )} -- ${gmbAccount.id}`
                                        }
                                    />
                                );
                            }}
                            control={control}
                        />
                    </Box>

                    <Box gridColumn={"1 / -1"}>
                        <UppercaseLabel>Google Analytics</UppercaseLabel>
                        <Box mb={1} />

                        <Controller
                            name={"googleAnalyticsPropertyId"}
                            render={({ onChange, value }) => {
                                return (
                                    <Select
                                        classNamePrefix={"gaAccount"}
                                        menuPlacement="top"
                                        value={_.filter(
                                            { propertyId: value },
                                            googleAccounts.gaAccounts
                                        )}
                                        options={googleAccounts.gaAccounts}
                                        onChange={(gaAccount) => onChange(gaAccount.propertyId)}
                                        getOptionValue={(x) => x}
                                        getOptionLabel={(gaAccount) =>
                                            `${gaAccount.name} -- ${gaAccount.propertyId}`
                                        }
                                    />
                                );
                            }}
                            control={control}
                        />
                    </Box>
                    <Box gridColumn={"1 / -1"}>
                        <UppercaseLabel>Search Console Account</UppercaseLabel>
                        <Box mb={1} />
                        <Controller
                            name={"googleSearchConsoleUrl"}
                            render={({ onChange, value }) => {
                                return (
                                    <Select
                                        classNamePrefix={"searchConsoleAccount"}
                                        menuPlacement="top"
                                        value={googleAccounts.searchConsoleAccounts.filter(
                                            (url) => url === value
                                        )}
                                        options={googleAccounts.searchConsoleAccounts}
                                        onChange={(searchConsoleUrl) => onChange(searchConsoleUrl)}
                                        getOptionValue={(x) => x}
                                        getOptionLabel={(x) => x}
                                    />
                                );
                            }}
                            control={control}
                        />
                    </Box>
                    <Box gridColumn={"1 / -1"}>
                        <UppercaseLabel>Call Rail</UppercaseLabel>
                        <Box mb={1} />

                        <Controller
                            name={"callRailCompanyId"}
                            render={({ onChange, value }) => {
                                return (
                                    <Select
                                        classNamePrefix={"callRailCompanyId"}
                                        menuPlacement="top"
                                        value={_.filter({ id: value }, callRailCompanies)}
                                        options={callRailCompanies}
                                        onChange={(company) => onChange(company.id)}
                                        getOptionValue={(x) => x}
                                        getOptionLabel={(company) =>
                                            `${company.name} -- ${company.id}`
                                        }
                                    />
                                );
                            }}
                            control={control}
                        />
                    </Box>
                    <Box gridColumn={"1 / -1"}>
                        <UppercaseLabel>Recurly</UppercaseLabel>
                        <Box mb={1} />

                        <Controller
                            name={"recurlyAccountCode"}
                            render={({ onChange, value }) => {
                                return (
                                    <Select
                                        classNamePrefix={"recurlyAccountCode"}
                                        menuPlacement="top"
                                        value={_.filter({ code: value }, recurlyAccounts)}
                                        options={recurlyAccounts}
                                        onChange={(account) => onChange(account.code)}
                                        getOptionValue={(x) => x}
                                        getOptionLabel={(account) =>
                                            `${account.name} -- ${account.code}`
                                        }
                                    />
                                );
                            }}
                            control={control}
                        />
                    </Box>

                    <Box gridColumn={"1 / -1"}>
                        <UppercaseLabel>Advice Local</UppercaseLabel>
                        <Box mb={1} />

                        <Controller
                            name={"adviceLocalClientId"}
                            render={({ onChange, value }) => {
                                return (
                                    <Select
                                        classNamePrefix={"adviceLocalClientId"}
                                        menuPlacement="top"
                                        value={_.filter({ id: Number(value) }, adviceLocalAccounts)}
                                        options={adviceLocalAccounts}
                                        onChange={(account) => onChange(account.id)}
                                        getOptionValue={(x) => x}
                                        getOptionLabel={(account) =>
                                            `${account.name} -- ${getHostName(
                                                account.website
                                            )} -- ${account.id}`
                                        }
                                    />
                                );
                            }}
                            control={control}
                        />
                    </Box>

                    <Box gridColumn={"1 / -1"}>
                        <UppercaseLabel>Velox Practice</UppercaseLabel>
                        <Box mb={1} />

                        <Controller
                            name={"veloxPracticeId"}
                            render={({ onChange, value }) => {
                                return (
                                    <Select
                                        classNamePrefix={"veloxPracticeId"}
                                        menuPlacement="top"
                                        value={_.filter({ id: Number(value) }, veloxPractices)}
                                        options={veloxPractices}
                                        onChange={(practice) => onChange(practice.id)}
                                        getOptionValue={(x) => x}
                                        getOptionLabel={(practice) =>
                                            `${practice.name} -- ${practice.pms_type} -- ${practice.id}`
                                        }
                                    />
                                );
                            }}
                            control={control}
                        />
                    </Box>

                    <Box gridColumn={"1 / -1"}>
                        <UppercaseLabel>Swell Location</UppercaseLabel>
                        <Box mb={1} />

                        <Controller
                            name={"swellLocationId"}
                            render={({ onChange, value }) => {
                                return (
                                    <Select
                                        classNamePrefix={"swellLocationId"}
                                        menuPlacement="top"
                                        value={_.filter({ id: value }, swellLocations)}
                                        options={swellLocations}
                                        onChange={(location) => onChange(location.id)}
                                        getOptionValue={(x) => x}
                                        getOptionLabel={(location) =>
                                            `${location.name} -- ${location.address}`
                                        }
                                    />
                                );
                            }}
                            control={control}
                        />
                    </Box>

                    <Box />
                    <FlexRow justifyContent={"space-between"}>
                        <FlexRow alignItems={"center"}>
                            <Body3>Active</Body3>
                            <Box mr={3} />
                            <Controller
                                name={"active"}
                                control={control}
                                render={({ onChange, value }) => {
                                    return (
                                        <Checkbox
                                            checked={value}
                                            onClick={() => onChange(!value)}
                                        />
                                    );
                                }}
                            />
                        </FlexRow>
                        <PrimaryButton data-testid={"submitButton"} type={"submit"}>
                            submit
                        </PrimaryButton>
                    </FlexRow>
                </ModalFormContainer>
            </form>
        </Box>
    );
};
