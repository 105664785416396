import { createSlice } from "@reduxjs/toolkit";

export default createSlice({
    name: "aggregatedInvoiceReport",
    initialState: {
        dateRange: {
            startDate: new Date(),
            endDate: new Date(),
        },
        rows: [],
        averages: {
            amountPerPatient: 0,
            newPatients: 0,
            total: 0,
        },
        totals: {
            total: 0,
            newPatients: 0,
        },
    },
    reducers: {
        set: (state, action) => action.payload,
    },
});
