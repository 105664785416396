import React from "react";
import styled from "styled-components";
import { getSpace } from "../../helpers/themeGetters";
//import { variant } from "styled-system";

// const base = {
//     py: 6,
//     px: 8,
//     gridColumn: "1/-1",
// };
//
// const Alert = styled.div(
//     variant({
//         variants: {
//             error: {
//                 color: "errorDark",
//                 border: "error",
//                 backGroundColor: "errorLight",
//             },
//             success: {
//                 color: "successDark",
//                 border: "success",
//                 backgroundColor: "successLight",
//             },
//         },
//     })
// );

const ErrorAlertContainer = styled.div`
    background-color: #fad7db;
    color: #e94f5f;
    border: 1px solid #e94f5f;
    padding: ${getSpace(6)} ${getSpace(8)};
    width: fit-content;
`;

export const ErrorAlert = ({ message }) =>
    message ? <ErrorAlertContainer id={"error"}>{message}</ErrorAlertContainer> : null;
