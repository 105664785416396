const colors = {
    auratiumGreen: "#74AD85",
    seaGreen: "#99C2AD",
    petrolBlue: "#107889",
    ipanemaBlue: "#329998",
    amaranthRed: "#B94F4F",
    sienna: "#E27867",
    nauticBlue: "#28415C",
    balticBlue: "#527991",
    agateGray: "#7F7F7F",
    slateGray: "#A7A9Ac",
    pearlGray: "#E6E7E8",
    chalk: "#F8F8F9",
    slateBlue: "#90A5B7",
    veniceBlue: "#B3C0CD",
    meissenBlue: "#E7F0F5",
    apricot: "#F6D6D1",
    lightApricot: "#FCF1EF",
    errorLight: "#fad7db",
    errorDark: "#e94f5f",
    successLight: "#D4EDDA",
    successDark: "#155724",
    black: "#000000",
};

const breakpoints = [640, 900, 1200];

const theme = {
    colors,
    //     [0, 1, 2, 3, 4, 5,  6,  7,  8,  9,  10, 11, 12, 13, 14, 15, 16, 17,  18,  19,  20,  21 ]
    space: [0, 2, 4, 6, 8, 10, 12, 16, 20, 24, 28, 32, 40, 48, 56, 64, 76, 128, 192, 256, 384, 512],
    //     [0, 1, 2, 3, 4, 5,  6,  7,  8,  9,  10, 11, 12, 13, 14, 15, 16, 17,  18,  19,  20,  21 ]
    sizes: [0, 2, 4, 6, 8, 10, 12, 16, 20, 24, 28, 32, 40, 48, 56, 64, 76, 128, 192, 256, 384, 512],
    fonts: {
        AvenirBook: "Avenir-Book",
        AvenirMedium: "Avenir-Medium",
        Arial: "Arial",
        roboto: "Roboto",
        montserrat: "Montserrat",
    },
    //         [0, 1,  2,  3,  4,  5,  6 ]
    fontWeights: {
        thin: 100,
        regular: 400,
        medium: 500,
        semiBold: 600,
        bold: 700,
        black: 900,
    },
    fontSizes: [8, 10, 12, 14, 16, 18, 30],
    borders: {
        white: "1px solid white",
        gray: `1px solid ${colors.pearlGray}`,
        heavyGray: `1px solid ${colors.slateGray}`,
        thiccGray: `2px solid ${colors.pearlGray}`,
        error: `1px solid ${colors.errorRedDark}`,
        success: `1px solid ${colors.successGreenDark}`,
        darkGray: `2px solid ${colors.slateGray}`,
    },
    breakpoints,
};

export default theme;
