import {format, startOfMonth, subMonths} from "date-fns/fp";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTable, useSortBy, useFilters } from "react-table";

import { getBorder, getColor } from "../../../helpers/themeGetters";
import { actions } from "../../../redux";

import { moneyCents, moneyRound, numberRound } from "../../../utilities";
import { percentage } from "../../../utilities/numberFormatting";
import { Body2, DatePicker, FullCard, Heading } from "../../Atoms";
import _ from "lodash/fp";
import { LoadingCard } from "../../Molecules";

import { allStyleFunctions, Box, FlexRowAlignCenter } from "../../StyledSystemUtilities";
import styled, { useTheme } from "styled-components";

const currentDate = new Date();

export function AggregatedPpc() {
    const theme = useTheme();

    const dispatch = useDispatch();
    const isLoading = useSelector((state) => state.loading.aggregatedPpc);
    const aggregatedPpc = useSelector((state) => state.aggregatedPpc);

    const [startDate, setStartDate] = useState(subMonths(0, startOfMonth(currentDate)));
    const [endDate, setEndDate] = useState(currentDate);

    const applyStyle = useCallback(
        (accessor) => (props) =>
            props.row.original.isInTheRed[accessor]
                ? { backgroundColor: theme.colors.apricot }
                : {},
        [theme]
    );

    useEffect(() => {
        dispatch(actions.getAggregatedPpc(startDate, endDate));
    }, [startDate, endDate, dispatch]);

    const legacyRows = useMemo(
        () => aggregatedPpc.legacyReport.rows,
        [aggregatedPpc.legacyReport.rows]
    );
    const legacyAverages = useMemo(
        () => aggregatedPpc.legacyReport.averages,
        [aggregatedPpc.legacyReport.averages]
    );

    const legacyColumns = useMemo(
        () => [
            {
                Header: "Practice Name",
                accessor: "practiceName",
                Filter: TextSearch,
                filter: "fuzzyText",
            },
            {
                Header: "NOM",
                accessor: "nom",
                sortType: "basic",
                format: (x) => numberRound(x, 1),
            },
            {
                Header: "Start Date",
                accessor: "startDate",
                sortType: "basic",
                Cell: (props) => format("MMM d yyyy", props.value),
            },
            {
                Header: "Days Active",
                accessor: "daysActive",
                sortType: "basic"
            },
            {
                Header: "Spend",
                accessor: "adSpend",
                Cell: (props) => moneyRound(props.value),
                sortType: "basic",
                format: moneyRound,
            },
            {
                Header: "Views",
                accessor: "impressions",
                Cell: (props) => numberRound(props.value),
                sortType: "basic",
                format: numberRound,
            },
            {
                Header: "Clicks",
                accessor: "clicks",
                Cell: (props) => numberRound(props.value),
                sortType: "basic",
                format: numberRound,
            },
            {
                Header: "Rate",
                accessor: "clicksPerImpression",
                Cell: (props) => {
                    return percentage(props.value, 1);
                },
                getStyle: applyStyle("clicksPerImpression"),
                sortType: "basic",
                format: (x) => percentage(x, 1),
            },
            {
                Header: "CPC",
                accessor: "costPerClick",
                Cell: (props) => moneyCents(props.value),
                sortType: "basic",
                format: moneyCents,
            },
            {
                Header: "Calls",
                accessor: "calls",
                Cell: (props) => numberRound(props.value),
                sortType: "basic",
                format: numberRound,
            },
            {
                Header: "Click to Call",
                accessor: "callsPerClick",
                Cell: (props) => percentage(props.value, 1),
                sortType: "basic",
                getStyle: applyStyle("callsPerClick"),
                format: (x) => percentage(x, 1),
            },
            {
                Header: "New Patients",
                accessor: "projectedNewPatients",
                Cell: (props) => numberRound(props.value),
                sortType: "basic",
                format: numberRound,
            },
            {
                Header: "Call Conv.",
                accessor: "projectedNewPatientsPerCall",
                Cell: (props) => percentage(props.value, 1),
                sortType: "basic",
                getStyle: applyStyle("projectedNewPatientsPerCall"),
                format: (x) => percentage(x, 1),
            },
            {
                Header: "CPP",
                accessor: "adSpendPerProjectedPatient",
                Cell: (props) => moneyRound(props.value),
                sortType: "basic",
                getStyle: applyStyle("adSpendPerProjectedPatient"),
                format: moneyRound,
            },
        ],
        [applyStyle]
    );

    const newModelRows = useMemo(
        () => aggregatedPpc.newModelReport.rows,
        [aggregatedPpc.newModelReport.rows]
    );
    const newModelAverages = useMemo(
        () => aggregatedPpc.newModelReport.averages,
        [aggregatedPpc.newModelReport.averages]
    );

    const newModelColumns = useMemo(
        () => [
            {
                Header: "Practice Name",
                accessor: "practiceName",
                Filter: TextSearch,
                filter: "fuzzyText",
            },
            {
                Header: "NOM",
                accessor: "nom",
                sortType: "basic",
                format: (x) => numberRound(x, 1),
            },
            {
                Header: "Start Date",
                accessor: "startDate",
                sortType: "basic",
                Cell: (props) => format("MMM d yyyy", props.value),
            },
            {
                Header: "Days Active",
                accessor: "daysActive",
                sortType: "basic"
            },
            {
                Header: "Spend",
                accessor: "adSpend",
                Cell: (props) => moneyRound(props.value),
                sortType: "basic",
                format: moneyRound,
            },
            {
                Header: "Views",
                accessor: "impressions",
                Cell: (props) => numberRound(props.value),
                sortType: "basic",
                format: numberRound,
            },
            {
                Header: "Clicks",
                accessor: "clicks",
                Cell: (props) => numberRound(props.value),
                sortType: "basic",
                format: numberRound,
            },
            {
                Header: "Rate",
                accessor: "clicksPerImpression",
                Cell: (props) => {
                    return percentage(props.value, 1);
                },
                getStyle: applyStyle("clicksPerImpression"),
                sortType: "basic",
                format: (x) => percentage(x, 1),
            },
            {
                Header: "CPC",
                accessor: "costPerClick",
                Cell: (props) => moneyCents(props.value),
                sortType: "basic",
                format: moneyCents,
            },
            {
                Header: "Calls",
                accessor: "calls",
                Cell: (props) => numberRound(props.value),
                sortType: "basic",
                format: numberRound,
            },
            {
                Header: "Click to Call",
                accessor: "callsPerClick",
                Cell: (props) => percentage(props.value, 1),
                sortType: "basic",
                getStyle: applyStyle("callsPerClick"),
                format: (x) => percentage(x, 1),
            },
            {
                Header: "Projected New Patients",
                accessor: "projectedNewPatients",
                Cell: (props) => numberRound(props.value),
                sortType: "basic",
                format: numberRound,
            },
            {
                Header: "Verified New Patients",
                accessor: "verifiedNewPatients",
                Cell: (props) => numberRound(props.value),
                sortType: "basic",
                format: numberRound,
            },
            {
                Header: "Projected Call Conv.",
                accessor: "projectedNewPatientsPerCall",
                Cell: (props) => percentage(props.value, 1),
                sortType: "basic",
                getStyle: applyStyle("projectedNewPatientsPerCall"),
                format: (x) => percentage(x, 1),
            },
            {
                Header: "Projected CPP",
                accessor: "adSpendPerProjectedPatient",
                Cell: (props) => moneyRound(props.value),
                sortType: "basic",
                getStyle: applyStyle("adSpendPerProjectedPatient"),
                format: moneyRound,
            },
            {
                Header: "Verified CPP",
                accessor: "adSpendPerVerifiedPatient",
                Cell: (props) => moneyRound(props.value),
                sortType: "basic",
                format: moneyRound,
            },
            {
                Header: "Ascent Revenue/Patient",
                accessor: "ascentRevenuePerVerifiedPatient",
                Cell: (props) => moneyRound(props.value),
                sortType: "basic",
                format: moneyRound,
            },
            {
                Header: "Projected Profitability",
                accessor: "projectedProfitability",
                Cell: (props) => moneyRound(props.value),
                sortType: "basic",
                format: moneyRound,
            },
            {
                Header: "Verified Profitability",
                accessor: "verifiedProfitability",
                Cell: (props) => moneyRound(props.value),
                sortType: "basic",
                format: moneyRound,
            },
        ],
        [applyStyle]
    );

    return (
        <>
            <FullCard>
                <FlexRowAlignCenter justifyContent={"space-between"}>
                    <Heading>Aggregated PPC</Heading>

                    <FlexRowAlignCenter>
                        <Body2 mr={3}>start</Body2>
                        <DatePicker
                            maxDate={endDate}
                            onChange={setStartDate}
                            selected={startDate}
                            hide={true}
                        />
                        <Box mr={10} />
                        <Body2 mr={3}>end</Body2>
                        <DatePicker
                            minDate={startDate}
                            maxDate={new Date()}
                            onChange={setEndDate}
                            selected={endDate}
                        />
                        <Box mr={10} />
                    </FlexRowAlignCenter>
                </FlexRowAlignCenter>
            </FullCard>
            <FullCard pt={3}>
                <LoadingCard isLoading={isLoading} />
                {!isLoading ? (
                    <>
                        <Box pb={"40px"} />
                        <Heading>New Model Report</Heading>
                        <Box pb={"10px"} />
                        <DataGrid
                            averages={newModelAverages}
                            columns={newModelColumns}
                            data={newModelRows}
                        />
                        <Box pb={"80px"} />
                        <Heading>Legacy Report</Heading>
                        <Box pb={"10px"} />
                        <DataGrid
                            averages={legacyAverages}
                            columns={legacyColumns}
                            data={legacyRows}
                        />
                    </>
                ) : null}
            </FullCard>
        </>
    );
}

function TextSearch({ column: { filterValue, setFilter } }) {
    return <input value={filterValue} onChange={(e) => setFilter(e.target.value || undefined)} />;
}

function DataGrid({ columns, data, averages }) {
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
        {
            columns,
            data,
            sortBy: [
                {
                    id: "Practice Name",
                },
            ],
        },
        useFilters,
        useSortBy
    );

    return (
        <Table {...getTableProps()}>
            <thead>
                {headerGroups.map((headerGroup, index) => (
                    <React.Fragment key={index}>
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <th {...column.getHeaderProps()}>
                                    <Box {...column.getSortByToggleProps()}>
                                        <FlexRowAlignCenter>
                                            {column.render("Header")}
                                            <Box mr={2} />

                                            {column.isSorted
                                                ? column.isSortedDesc
                                                    ? " 🔽"
                                                    : " 🔼"
                                                : ""}
                                        </FlexRowAlignCenter>
                                    </Box>
                                    {column.canFilter && column.Filter
                                        ? column.render("Filter")
                                        : null}
                                </th>
                            ))}
                        </tr>
                        <Tr borderBottom={"darkGray"}>
                            <Td colSpan={1} fontWeight={"bold"} textAlign={"right"}>
                                Average
                            </Td>
                            {columns.map((column, index) => {
                                if(index === 0){
                                    return null
                                }
                                const value = _.get(column.accessor, averages);

                                return typeof value === "number" ? (
                                    <Td key={index}>{column.format(value)}</Td>
                                ) : <Td></Td>;
                            })}
                        </Tr>
                    </React.Fragment>
                ))}
            </thead>
            <tbody {...getTableBodyProps()}>
                {rows.map((row) => {
                    prepareRow(row);
                    return (
                        <tr {...row.getRowProps()}>
                            {row.cells.map((cell) => {
                                return (
                                    <td
                                        {...cell.getCellProps({
                                            style: cell.column.getStyle
                                                ? cell.column.getStyle(cell)
                                                : {},
                                        })}
                                    >
                                        {cell.render("Cell")}
                                    </td>
                                );
                            })}
                        </tr>
                    );
                })}
            </tbody>
        </Table>
    );
}

export const Table = styled.table`
    width: 100%;
    border-collapse: collapse;
    font-family: Arial;
    font-size: 14px;
    overflow-x: scroll;
    grid-column: 1 / -1;

    th,
    td {
        white-space: nowrap;
        min-width: 80px;
        border: ${getBorder("gray")};
        text-align: left;
        padding: 10px;
        color: #181818;
    }

    th {
        background-color: ${getColor("chalk")};
        user-select: none;
    }
`;

const Td = styled.td(allStyleFunctions);
const Tr = styled.tr(allStyleFunctions);
