import { api } from "../utilityActions";
import getClientUsers from "./getClientUsers";
import {ententeUrl} from "../../config";

export default (newClientUser) =>
    api({
        call: {
            url: "/piston/secure/clients/invite-client-user",
            method: "post",
            data: newClientUser,
            baseURL: ententeUrl
        },
        success: getClientUsers,
        label: newClientUser.clientAccountId,
    });
