import React from "react";
import TextareaAutosize from "react-textarea-autosize";
import styled from "styled-components";
import { layout, space } from "styled-system";
import { getColor, getFontSize, getSize, getSpace } from "../../../helpers/themeGetters";

const BaseTextInput = styled.input`
    display: block;
    box-sizing: border-box;
    height: ${getSize(13)};
    width: 100%;
    padding: 0px 18px;
    border: none;
    font-size: ${getFontSize(3)};
    font-family: Arial;
    color: ${getColor("balticBlue")};
    ::placeholder {
        color: ${getColor("veniceBlue")};
        font-size: ${getFontSize(3)};
    }
    :focus {
        outline: none;
    }
    ${space};
    -webkit-appearance: none;
    -webkit-border-radius: 0;
    -moz-appearance: none;
    appearance: none;
`;

export const LargeTextInput = styled(BaseTextInput)``;

export const SmallTextInput = styled(BaseTextInput)`
    height: ${getSize(11)};
    font-size: ${getFontSize(2)};
    ::placeholder {
        font-size: ${getFontSize(2)};
    }
    border: 1px solid ${getColor("pearlGray")};
`;

export const StyledTextAreaInput = styled(TextareaAutosize)`
    padding: ${getSpace(4)};
    width: 100%;
    resize: none;
    font-size: ${getFontSize(2)};
    box-sizing: border-box;
    color: ${getColor("balticBlue")};
    border: 1px solid ${getColor("pearlGray")};
    line-height: 1.2em;
    ::placeholder {
        color: ${getColor("veniceBlue")};
    }
    :focus {
        outline: none;
        border: 1px solid ${getColor("pearlGray")};
    }
    ${layout};
    -webkit-appearance: none;
    -webkit-border-radius: 0;
    -moz-appearance: none;
    appearance: none;
`;

export const TextAreaInput = ({ register, ...props }) => {
    return <StyledTextAreaInput ref={register} minRows={3} {...props} />;
};

export const SpecialTextInput = styled.input`
    display: block;
    box-sizing: border-box;
    height: 70px;
    width: 100%;
    padding: 0px 18px;
    text-align: center;
    border: 2px solid ${getColor("pearlGray")};
    font-size: 30px;
    font-family: Arial;
    color: ${getColor("amaranthRed")};

    :focus {
        outline: none;
    }
    ${space};
    -webkit-appearance: none;
    -webkit-border-radius: 0;
    -moz-appearance: none;
    appearance: none;
`;
