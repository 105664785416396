import React from "react";
import styled from "styled-components";
import { getColor, getSpace } from "../../helpers/themeGetters";
import { HamburgerButton, CrossButton, ProfileButton } from "../Atoms";
import { routerHistory } from "../../routerHistory";
import { Box } from "../StyledSystemUtilities";

const Container = styled.div`
    background-color: ${getColor("slateBlue")};
    height: 42px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: ${getSpace(6)};
    padding-right: ${getSpace(9)};
    justify-content: space-between;

    transform: translateZ(0);
    -moz-transform: translatez(0);
    -ms-transform: translatez(0);
    -o-transform: translatez(0);
    -webkit-transform: translateZ(0);
    -webkit-font-smoothing: antialiased;
`;

export const Topbar = ({ showToggle, handleSidebarToggleClick, showSidebar }) => {
    return (
        <Container>
            {showToggle ? (
                showSidebar ? (
                    <CrossButton
                        color={"white"}
                        data-testid={"toggleSidebar"}
                        onClick={handleSidebarToggleClick}
                    />
                ) : (
                    <HamburgerButton
                        data-testid={"toggleSidebar"}
                        onClick={handleSidebarToggleClick}
                    />
                )
            ) : (
                <Box />
            )}
            <ProfileButton
                aria-label={"myAccount"}
                onClick={() => routerHistory.push("/dash/my-account")}
            />
        </Container>
    );
};
