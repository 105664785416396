import React from "react";
import { actions } from "../../../redux";

import { chooseNewPasswordSchema } from "../../../yupSchemas";
import { Error, LargeTextInput, PrimaryButton } from "../../Atoms";
import { AuthenticationTemplate } from "../../Templates";
import { Box } from "../../StyledSystemUtilities";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";

import { useParams } from "react-router-dom";

export const ChooseNewPassword = () => {
    const { passwordResetToken } = useParams();
    const dispatch = useDispatch();
    const { register, handleSubmit, errors } = useForm({
        resolver: chooseNewPasswordSchema,
    });

    const onSubmit = (formData) => {
        dispatch(actions.setNewPassword(passwordResetToken, formData.password));
    };

    return (
        <AuthenticationTemplate
            onSubmit={handleSubmit(onSubmit)}
            errorLabel={"chooseNewPassword"}
            headerText={"Choose New Password"}
        >
            <Box gridColumn={"1"}>
                <LargeTextInput ref={register} name={"password"} placeholder="new password" />
                <Box mb={1} />
                <Error error={errors.password} />
            </Box>
            <Box gridColumn={"1"}>
                <LargeTextInput
                    ref={register}
                    name={"confirmPassword"}
                    placeholder="confirm new password"
                />
                <Box mb={1} />
                <Error error={errors.confirmPassword} />
                <Box mb={8} />
                <Box display={"flex"} flexDirection={"column"} alignItems={"flex-end"}>
                    <Box>
                        <PrimaryButton type={"submit"}>Submit</PrimaryButton>
                    </Box>
                </Box>
            </Box>
        </AuthenticationTemplate>
    );
};
