import _ from "lodash/fp";
import React from "react";
import { Card, Heading, Heading2 } from "../Atoms";
import { Box, FlexRow } from "../StyledSystemUtilities";

export const PageHeading = ({ headlineText, subHeaderText, renderButton = _.noop }) => {
    return (
        <Card gridColumn={"1 / -1"}>
            <FlexRow justifyContent={"space-between"} flexWrap={"wrap"}>
                <Box>
                    <Heading data-testid={"pageHeadline"}>{headlineText}</Heading>
                    {subHeaderText ? (
                        <>
                            <Box mb={2} />
                            <Heading2 data-testid={"pageSubheader"}>{subHeaderText}</Heading2>
                        </>
                    ) : null}
                </Box>

                <Box alignSelf={"flexEnd"}>{renderButton()}</Box>
            </FlexRow>
        </Card>
    );
};
