import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLayoutEffect, useState } from "react";
import { actions } from "../../../redux";
import { getDateFromPeriod } from "../../../utilities";
import { Box } from "../../StyledSystemUtilities";
import {
    BlueBody2,
    BlueBody3,
    Card,
    EditButton,
    Heading,
    UppercaseLabel,
    Heading2,
    Body2,
} from "../../Atoms";
import _ from "lodash/fp";
import { Modal, DirectMailCampaignForm } from "../../Organisms";
import { format } from "date-fns/fp";

export const DirectMailSetup = () => {
    const dispatch = useDispatch();
    const directMailCampaigns = useSelector((state) => state.directMailCampaigns);
    const openPractice = useSelector((state) => state.openPractice);

    const [isNewCampaignModalOpen, setIsNewCampaignModalOpen] = useState(false);
    const [isUpdateCampaignOpen, setIsUpdateCampaignOpen] = useState(false);
    const [campaignToUpdateId, setCampaignToUpdateId] = useState("");

    const handleNewCampaignSubmit = (newCampaignData) => {
        dispatch(actions.addDirectMailCampaign(openPractice._id, newCampaignData));
        setIsNewCampaignModalOpen();
    };

    const handleUpdateMode = (id) => {
        setCampaignToUpdateId(id);
        setIsUpdateCampaignOpen(true);
    };
    const handleUpdateSubmit = (updateData) => {
        dispatch(actions.updateDirectMailCampaign(campaignToUpdateId, updateData));
        setIsUpdateCampaignOpen(false);
    };

    useLayoutEffect(() => {
        dispatch(actions.getDirectMailCampaigns(openPractice._id));
    }, [dispatch, openPractice._id]);

    return (
        <>
            <Modal
                title={"Add a Direct Mail Campaign"}
                isOpen={isNewCampaignModalOpen}
                setIsOpen={setIsNewCampaignModalOpen}
            >
                <DirectMailCampaignForm superviseSubmit={handleNewCampaignSubmit} />
            </Modal>

            <Modal
                title={"Edit Campaign"}
                isOpen={isUpdateCampaignOpen}
                setIsOpen={setIsUpdateCampaignOpen}
            >
                <DirectMailCampaignForm
                    initialState={_.find({ _id: campaignToUpdateId }, directMailCampaigns)}
                    superviseSubmit={handleUpdateSubmit}
                />
            </Modal>

            <Card gridColumn={"1 / -1"}>
                <Box
                    display={"flex"}
                    flexDirection={"row"}
                    justifyContent={"space-between"}
                    flexWrap={"wrap"}
                >
                    <Box>
                        <Heading data-testid={"pageHeadline"}>{"Direct Mail Setup"}</Heading>
                        <Box mb={2} />
                        <Heading2 data-testid={"pageSubheader"}>
                            {openPractice.practiceName}
                        </Heading2>
                    </Box>
                    <Box alignSelf={"flex-end"}>
                        <EditButton
                            data-testid={"addCampaign"}
                            onClick={() => setIsNewCampaignModalOpen((s) => !s)}
                        >
                            add campaign
                        </EditButton>
                    </Box>
                </Box>
            </Card>
            {directMailCampaigns.map((campaign) => (
                <Card data-testid={campaign._id} key={campaign._id}>
                    <Box
                        backgroundColor={"chalk"}
                        py={7}
                        px={9}
                        display={"grid"}
                        gridTemplateColumns={"1fr 1fr"}
                        gridColumnGap={3}
                        gridRowGap={8}
                    >
                        <Box gridColumn={"1/ -1"}>
                            <UppercaseLabel>Campaign name</UppercaseLabel>
                            <Box mb={1} />
                            <BlueBody2>{campaign.name}</BlueBody2>
                        </Box>
                        <Box>
                            <UppercaseLabel>new patient goal</UppercaseLabel>
                            <Box mb={1} />
                            <BlueBody3>{_.get("goals.newPatients", campaign)}</BlueBody3>
                        </Box>
                        <Box gridColumn={"1/ -1"}>
                            <Body2>Linked Call Rail Trackers</Body2>
                        </Box>
                        {_.map(
                            (linkedCallRailTracker) => (
                                <Box key={linkedCallRailTracker.trackerId} gridColumn={"1 / -1"}>
                                    <BlueBody3>{linkedCallRailTracker.name}</BlueBody3>
                                </Box>
                            ),
                            campaign.linkedCallRailTrackers
                        )}
                        <Box gridColumn={"1/ -1"}>
                            <Body2>Mailing Periods</Body2>
                        </Box>
                        {campaign.mailingPeriods.map((mailingPeriod, index, array) => (
                            <React.Fragment key={index}>
                                <Box>
                                    <UppercaseLabel>Start Month</UppercaseLabel>
                                    <Box mb={1} />
                                    <BlueBody3>
                                        {format(
                                            "MMM yyyy",
                                            getDateFromPeriod(mailingPeriod.startPeriod)
                                        )}
                                    </BlueBody3>
                                </Box>

                                <Box>
                                    <UppercaseLabel>End Month</UppercaseLabel>
                                    <Box mb={1} />
                                    <BlueBody3>
                                        {format(
                                            "MMM yyyy",
                                            getDateFromPeriod(mailingPeriod.endPeriod)
                                        )}
                                    </BlueBody3>
                                </Box>
                                <Box>
                                    <UppercaseLabel>Total Budget</UppercaseLabel>
                                    <Box mb={1} />
                                    <BlueBody3>{mailingPeriod.budget}</BlueBody3>
                                </Box>
                                <Box>
                                    <UppercaseLabel>Total Mailers</UppercaseLabel>
                                    <Box mb={1} />
                                    <BlueBody3>{mailingPeriod.numberOfMailers}</BlueBody3>
                                </Box>
                                {index === array.length - 1 ? null : (
                                    <Box gridColumn={"1/-1"} borderBottom={"heavyGray"} />
                                )}
                            </React.Fragment>
                        ))}

                        <Box gridColumn={2} justifySelf={"end"}>
                            <EditButton
                                data-testid={"editCampaign"}
                                onClick={() => handleUpdateMode(campaign._id)}
                            >
                                edit campaign
                            </EditButton>
                        </Box>
                    </Box>
                </Card>
            ))}
        </>
    );
};
