import _ from "lodash/fp";
import slices from "../slices";
import { historyPush } from "../utilityActions";
import resetReports from "./resetReports";
import getGoogleAdsAddOns from "./getGoogleAdsAddOns";
import getIsNewModel from "./getIsNewModel";

const selectPractice = (practiceId) => (dispatch, getState) => {
    const practice = _.find({ _id: practiceId }, getState().practices);

    dispatch([
        resetReports(),
        slices.openPractice.actions.set(practice),
        getIsNewModel(practice.recurlyAccountCode),
        historyPush("/dash/practice-list/practice-dashboard/practice-info"),
    ]);
    if (practice.recurlyAccountCode) dispatch(getGoogleAdsAddOns(practiceId));
};

export default selectPractice;
