import React, { useState } from "react";
import styled from "styled-components";
import { FlexRow } from "../StyledSystemUtilities";
import { getColor, getFontSize, getSize } from "../../helpers/themeGetters";

const Selector = styled.div`
    cursor: pointer;
    font-family: Roboto;
    text-transform: uppercase;
    font-size: ${getFontSize(1)};
    flex-basis: 160px;
    color: ${getColor("sienna")};
    ${(props) =>
        props.selected ? { borderBottom: `4px solid ${props.theme.colors.sienna}` } : null};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: ${getSize(9)};
    user-select: none;
`;

export const SelectionBar = ({ items, defaultSelected = 0 }) => {
    const [selected, setSelected] = useState(defaultSelected);

    return (
        <FlexRow
            px={8}
            borderBottom={"gray"}
            borderTop={"gray"}
            height={9}
            alignItems={"center"}
            gridColumn={"1 / -1"}
        >
            {items.map((item, index) => (
                <Selector
                    key={index}
                    data-testid={item.testid}
                    onClick={() => {
                        item.handleClick();
                        setSelected(index);
                    }}
                    selected={selected === index}
                >
                    {item.text}
                </Selector>
            ))}
        </FlexRow>
    );
};
