import { createSlice } from "@reduxjs/toolkit";

export default createSlice({
    name: "seoReport",
    initialState: {},
    reducers: {
        set: (state, action) => action.payload,
        reset: (state, action) => {},
    },
});
