import { api } from "../utilityActions";
import slices from "../slices";
import {ententeUrl} from "../../config";
export default () =>
    api({
        call: {
            url: `/piston/secure/call-rail/get-companies`,
            method: "get",
            baseURL: ententeUrl
        },
        success: slices.callRailCompanies.actions.set,
    });
