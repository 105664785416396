import { api } from "../utilityActions";
import getDirectMailCampaigns from "./getDirectMailCampaigns";
import {ententeUrl} from "../../config";

export default (practiceId, newCampaign) =>
    api({
        call: {
            url: "/piston/secure/direct-mail/add-campaign",
            method: "post",
            data: { newCampaign, practiceId },
            baseURL: ententeUrl
        },
        success: getDirectMailCampaigns,
    });
