import { createSlice } from "@reduxjs/toolkit";


const initialState = { leads: [], isMore: false, last: null };
export default createSlice({
    name: "leads",
    initialState: initialState,

    reducers: {
        set: (state, action) => {
            console.log("RESULTS: ", action.payload);
            return {
                ...state,
                leads: [...state.leads, ...action.payload.leads],
                isMore: action.payload.isMore,
                last: action.payload.last,
            };
        },
        clear: () => initialState,
    },
});
