
import { api } from "../utilityActions";
import getIsNewModel from "./getIsNewModel";
import {ententeUrl} from "../../config";

export default (practiceId, recurlyAccountCode) =>
    api({
        call: {
            url: `/piston/secure/practices/upgrade-subscription-model`,
            data: {
                practiceId,
            },
            method: "post",
            baseURL: ententeUrl
        },
        success: () => getIsNewModel(recurlyAccountCode),
        label: "upgradeSubscriptionModel",
    });
