import practiceGrowthDataReport from "../slices/practiceGrowthDataReport";
import seoReport from "../slices/seoReport";
import directMailReport from "../slices/directMailReport";
import ppcReport from "../slices/ppcReport";
import reviewsReport from "../slices/reviewsReport";

const resetReport = () => [
    practiceGrowthDataReport.actions.reset(),
    seoReport.actions.reset(),
    directMailReport.actions.reset(),
    ppcReport.actions.reset(),
    reviewsReport.actions.reset()
];

export default resetReport
