import getClients from "./getClients";
import { api } from "../utilityActions";
import {ententeUrl} from "../../config";

const addClient = (newClient) =>
    api({
        call: {
            url: "/piston/secure/clients/add",
            method: "post",
            data: { newClient },
            baseURL: ententeUrl,
        },
        success: getClients,
    });

export default addClient;
