import React from "react";
import { Body3, NavigationButton } from "../Atoms";
import { Box, FlexRow } from "../StyledSystemUtilities";

export const QuestionNavigationButton = ({ testid, text, buttonText, onClick, ...props }) => {
    return (
        <FlexRow
            flexWrap={"wrap"}
            alignItems={"center"}
            justifyContent={"space-between"}
            {...props}
        >
            <Box order={1}>
                <Body3>{text}</Body3>
            </Box>
            <Box order={2}>
                <NavigationButton data-testid={testid} type={"button"} onClick={onClick}>
                    {buttonText}
                </NavigationButton>
            </Box>
        </FlexRow>
    );
};
