import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash/fp";

export default createSlice({
    name: "serverLoading",
    initialState: { login: {}, register: {}, practiceList: {} },
    reducers: {
        set: {
            reducer: (state, action) => _.set(action.payload.label, action.payload.error, state),
            prepare: (label, error) => ({ payload: { label, error } }),
        },

        unset: {
            reducer: (state, action) => _.set(action.payload.label, {}, state),
            prepare: (label) => ({ payload: { label } }),
        },
    },
});
