import slices from "../slices";
import { api } from "../utilityActions";
import { ententeUrl } from "../../config";

export default () =>
    api({
        call: {
            url: "/piston/secure/swell/get-locations",
            method: "get",
            baseURL: ententeUrl,
        },
        success: slices.swellLocations.actions.set,
    });
