import { useEffect, useState } from "react";
import deepDateParse from "../utilities/deepDateParse";

export const useApi = (apiCall, deps) => {
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const load = () => {
        setIsLoading(true);
        apiCall()
            .then((data) => {
                setData(deepDateParse(data));
            })
            .catch((e) => {
                setError(e.message);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    useEffect(() => {
        load();
    }, deps);

    return [data, isLoading, error, load];
};
