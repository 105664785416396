import _ from "lodash/fp";
import { api } from "../utilityActions";
import slices from "../slices";
import {ententeUrl} from "../../config";

export default () => (dispatch, getState) =>
    dispatch(
        api({
            call: {
                url: `/piston/secure/direct-mail/get-campaigns/${_.get(
                    "openPractice._id",
                    getState()
                )}`,
                method: "get",
                baseURL: ententeUrl
            },
            success: slices.directMailCampaigns.actions.set,
        })
    );
