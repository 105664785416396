const practiceTypeOptions = [
    "generalDentist",
    "cosmeticDentist",
    "pediatricDentist",
    "prosthodontist",
    "periodontist",
    "endodontist",
    "orthodontist",
    "oralSurgeon",
];
export default practiceTypeOptions;
