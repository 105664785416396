import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash/fp";

export default createSlice({
    name: "myAccount",
    initialState: {
        positions: [],
    },
    reducers: {
        set: (state, { payload }) => payload,
        reset: _.constant({}),
    },
});
