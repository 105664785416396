import styled from "styled-components";
import React from "react";
import { getColor, getSpace } from "../../../helpers/themeGetters";
import _ from "lodash/fp";
import { Dot } from "../../Atoms";
import { Box, FlexRow, Span } from "../../StyledSystemUtilities";
import { border, color } from "styled-system";
const Table = styled.table`
    width: 100%;
    border-collapse: collapse;
`;

const Td = styled.td`
    color: ${getColor("agateGray")};
    height: 35px;

    border: ${(props) => props.theme.borders.gray};
    text-align: center;
    font-size: 12px;
    ${border}
`;

const TdA = styled(Td)`
    background-color: #f2f2f3;
    width: 52px;
    ${color}
`;

const TdB = styled(Td)`
    background-color: white;
    width: 52px;
    ${color}
`;

const TdName = styled(Td)`
    padding-left: ${getSpace(6)};
    text-align: left;
`;

const Th = styled.th`
    color: ${getColor("agateGray")};
    font-size: 10px;
    padding-bottom: ${getSpace(2)};
`;

export const CompetitorsTable = ({ competitors, target, headings, order = "asc" }) => {
    const sorted = _.flow([
        _.orderBy("b", order),
        _.take(5),
        _.concat(target),
        _.orderBy("b", order),
    ])(competitors);

    const topCompetitorId = _.isEqual(order, "asc")
        ? _.get("_id", _.minBy("b", competitors))
        : _.get("_id", _.maxBy("b", competitors));
    return (
        <Table>
            <thead>
                <tr>
                    <Th style={{ textAlign: "left" }}>{headings[0]}</Th>
                    <Th>{headings[1]}</Th>
                    <Th>{headings[2]}</Th>
                </tr>
            </thead>
            <tbody>
                {sorted.map((practice) => {
                    if (practice._id === target._id) {
                        return (
                            <tr key={practice._id}>
                                <TdName>
                                    <FlexRow alignItems={"center"}>
                                        <Dot flexShrink={0} backgroundColor={"sienna"} />
                                        <Box mr={2} />
                                        <Span fontWeight={"bold"}>{practice.practiceName}</Span>
                                    </FlexRow>
                                </TdName>
                                <TdA color={"white"} backgroundColor={"sienna"}>
                                    {practice.a}
                                </TdA>
                                <TdB color={"white"} backgroundColor={"amaranthRed"}>
                                    {practice.b}
                                </TdB>
                            </tr>
                        );
                    } else if (practice._id === topCompetitorId) {
                        return (
                            <tr key={practice._id}>
                                <TdName>
                                    <FlexRow alignItems={"center"}>
                                        <Dot flexShrink={0} backgroundColor={"balticBlue"} />
                                        <Box mr={2} />
                                        {practice.practiceName}
                                    </FlexRow>
                                </TdName>
                                <TdA>{practice.a}</TdA>
                                <TdB>{practice.b}</TdB>
                            </tr>
                        );
                    }
                    return (
                        <tr key={practice._id}>
                            <TdName>{practice.practiceName}</TdName>
                            <TdA>{practice.a}</TdA>
                            <TdB>{practice.b}</TdB>
                        </tr>
                    );
                })}
            </tbody>
        </Table>
    );
};
