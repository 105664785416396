import React, {useEffect, useMemo, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../../redux";
import { clientSchema } from "../../../yupSchemas";
import { Box, FlexRow, Grid } from "../../StyledSystemUtilities";
import {
    AddButton,
    BlueBody2,
    Body2,
    Body4,
    Card,
    CrossButton,
    EditButton,
    Error,
    Heading,
    Heading2,
    PrimaryButton,
    Select,
    SmallTextInput,
    UppercaseLabel,
} from "../../Atoms";
import _ from "lodash/fp";
import { Modal } from "../../Organisms";
import { useFieldArray, useForm, Controller } from "react-hook-form";
import useErrors from "../../../hooks/useErrors";

export const ClientManagement = () => {
    const dispatch = useDispatch();

    const currentUser = useSelector((state) => state.currentUser);
    const clients = useSelector((state) => state.clients);
    const practices = useSelector((state) => state.practices);
    const strategists = useSelector((state) => state.strategists);
    const clientUsers = useSelector((state) => state.clientUsers);

    const [isAddClientModalOpen, setIsAddClientModalOpen] = useState(false);
    const [isClientUpdateModalOpen, setIsClientUpdateModalOpen] = useState(false);
    const [idOfClientToUpdate, setIdOfClientToUpdate] = useState("");
    const [searchTerm, setSearchTerm] = useState("");

    const filteredClients = useMemo(() => {
        const lowerCaseSearchTerm = searchTerm.toLowerCase()
        return clients.filter(client => {
            return client.accountName.toLowerCase().includes(lowerCaseSearchTerm) || (client?.primaryContact?.name?.toLowerCase() ?? "").includes(lowerCaseSearchTerm)
        })
    },[searchTerm, clients])


    useEffect(() => {
        dispatch(actions.getStrategists());
        dispatch(actions.getClients());
    }, []);

    function handleNewClient(newClient) {
        dispatch(actions.addClient(newClient));
        setIsAddClientModalOpen(false);
    }
    function handleUpdate(update) {
        dispatch(actions.updateClient(idOfClientToUpdate, update));
        setIsClientUpdateModalOpen(false);
    }
    function handleEditClick(_id) {
        setIdOfClientToUpdate(_id);
        setIsClientUpdateModalOpen(true);
    }

    return (
        <>
            <Modal
                title={"Add a client account"}
                isOpen={isAddClientModalOpen}
                setIsOpen={setIsAddClientModalOpen}
            >
                <ClientForm superviseSubmit={handleNewClient} />
            </Modal>
            {isClientUpdateModalOpen ? (
                <Modal
                    title={`Update ${_.find({ _id: idOfClientToUpdate }, clients).accountName} `}
                    isOpen={isClientUpdateModalOpen}
                    setIsOpen={setIsClientUpdateModalOpen}
                >
                    <ClientForm
                        initialState={_.find({ _id: idOfClientToUpdate }, clients)}
                        superviseSubmit={handleUpdate}
                    />
                </Modal>
            ) : null}

            <Card gridColumn={"1 / -1"}>
                <FlexRow justifyContent={"space-between"} flexWrap={"wrap"}>
                    <Box>
                        <Heading data-testid={"pageHeadline"}>{"Client Accounts"}</Heading>
                        <Box mb={2} />
                        <Heading2 data-testid={"pageSubheader"}>
                            {_.get("user.name", currentUser)}
                        </Heading2>
                    </Box>
                    <Box alignSelf={"flex-end"} display={"flex"} flexDirection={"column"} alignItems={"flex-end"}>
                        <Box mb={3} width={20}>
                            <SmallTextInput
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                placeholder={"search..."}
                            />
                        </Box>
                        <EditButton
                            data-testid={"addClientButton"}
                            onClick={() => setIsAddClientModalOpen(true)}
                        >
                            add account
                        </EditButton>
                    </Box>
                </FlexRow>
            </Card>
            {filteredClients.map((client, index) => {
                return (
                    <Card key={index} data-testid={client._id}>
                        <Grid
                            py={6}
                            px={9}
                            backgroundColor={"chalk"}
                            gridColumnGap={3}
                            gridRowGap={8}
                            gridTemplateColumns={"1fr 1fr"}
                        >
                            <Box>
                                <UppercaseLabel mb={1}>account name</UppercaseLabel>
                                <BlueBody2>{client.accountName}</BlueBody2>
                            </Box>

                            <Box>
                                <UppercaseLabel mb={1}>website</UppercaseLabel>
                                <BlueBody2>{client.websiteAddress}</BlueBody2>
                            </Box>

                            <Box>
                                <UppercaseLabel mb={1}>primary contact name</UppercaseLabel>
                                <BlueBody2>{client.primaryContact.name}</BlueBody2>
                            </Box>

                            <Box>
                                <UppercaseLabel mb={1}>primary contact phone</UppercaseLabel>
                                <BlueBody2>{client.primaryContact.phone}</BlueBody2>
                            </Box>

                            <Box>
                                <UppercaseLabel mb={1}>primary contact email</UppercaseLabel>
                                <BlueBody2>{client.primaryContact.email}</BlueBody2>
                            </Box>

                            <Box>
                                <UppercaseLabel mb={1}>primary contact role</UppercaseLabel>
                                <BlueBody2>{client.primaryContact.label}</BlueBody2>
                            </Box>

                            <Box>
                                <UppercaseLabel mb={1}>street address</UppercaseLabel>
                                <BlueBody2>{client.streetAddress}</BlueBody2>
                            </Box>

                            <Box>
                                <UppercaseLabel mb={1}>city</UppercaseLabel>
                                <BlueBody2>{client.city}</BlueBody2>
                            </Box>

                            <Box>
                                <UppercaseLabel mb={1}>state</UppercaseLabel>
                                <BlueBody2>{client.state}</BlueBody2>
                            </Box>

                            <Box>
                                <UppercaseLabel mb={1}>zip code</UppercaseLabel>
                                <BlueBody2>{client.zipCode}</BlueBody2>
                            </Box>

                            <Box>
                                <UppercaseLabel mb={1}>strategist</UppercaseLabel>
                                <BlueBody2>
                                    {_.flow(
                                        _.find({ _id: client.strategist }),
                                        _.get("name")
                                    )(strategists)}
                                </BlueBody2>
                            </Box>

                            <Box>
                                <UppercaseLabel mb={1}>client users</UppercaseLabel>
                                {clientUsers
                                    .filter((user) => user.clientAccountId === client._id)
                                    .map((user, index) => (
                                        <Box key={index} pb={2}>
                                            <BlueBody2>{_.get("email", user)}</BlueBody2>
                                        </Box>
                                    ))}
                            </Box>

                            <Box gridColumn={"1/-1"}>
                                <Body2>Linked Practices</Body2>
                            </Box>

                            {client.practices.map((practiceId, index) => (
                                <Box key={index} gridColumn={"1/-1"}>
                                    <BlueBody2>
                                        {_.get(
                                            "practiceName",
                                            _.find({ _id: practiceId }, practices)
                                        )}
                                    </BlueBody2>
                                </Box>
                            ))}
                            <Box />
                            <EditButtons
                                handleEditClick={() => handleEditClick(client._id)}
                                client={client}
                            />
                        </Grid>
                    </Card>
                );
            })}
        </>
    );
};

function EditButtons({ handleEditClick, client }) {
    const dispatch = useDispatch();
    const error = useErrors(client._id);
    const [isInvited, setIsInvited] = useState(false);
    return (
        <Box justifySelf={"end"}>
            <EditButton onClick={() => handleEditClick(client._id)}>edit client</EditButton>
            <Box pb={4} />
            <EditButton
                onClick={() => {
                    const splitClientName = client.primaryContact.name.split(" ");

                    dispatch(
                        actions.addClientUser({
                            email: client.primaryContact.email,
                            firstName: splitClientName[0] ? splitClientName[0] : "",
                            lastName: splitClientName[1] ? splitClientName[1] : "",
                            clientAccountId: client._id,
                        })
                    );
                    setIsInvited(true);
                }}
            >
                invite client
            </EditButton>
            {error ? (
                <Body4 pt={4}>{error}</Body4>
            ) : isInvited ? (
                <Body4 pt={4}>User Invited!</Body4>
            ) : null}
        </Box>
    );
}

function preprocess(initialState) {
    if (_.isEmpty(initialState)) {
        return {};
    }
    return _.update(
        "practices",
        _.map((id) => ({ data: id })),
        initialState
    );
}
const postprocess = _.update("practices", _.map(_.get("data")));

function ClientForm({ superviseSubmit, initialState = {} }) {
    const practices = useSelector((state) => state.practices);
    const employees = useSelector((state) => state.strategists);

    const { register, handleSubmit, errors, control, getValues } = useForm({
        defaultValues: preprocess(initialState),
        resolver: clientSchema,
    });

    const { fields, remove, append } = useFieldArray({
        control,
        name: "practices",
    });

    return (
        <Box>
            <form onSubmit={handleSubmit((raw) => superviseSubmit(postprocess(raw)))}>
                <Grid
                    gridTemplateColumns={"repeat(auto-fill, minmax(280px, 1fr))"}
                    gridRowGap={8}
                    gridColumnGap={4}
                >
                    <Box>
                        <UppercaseLabel mb={1}>name</UppercaseLabel>
                        <SmallTextInput name={"accountName"} ref={register} />
                        <Error error={errors.accountName} />
                    </Box>
                    <Box>
                        <UppercaseLabel mb={1}>website address</UppercaseLabel>
                        <SmallTextInput name={"websiteAddress"} ref={register} />
                        <Error error={errors.websiteAddress} />
                    </Box>
                    <Box>
                        <UppercaseLabel mb={1}>primary contact name</UppercaseLabel>
                        <SmallTextInput name={"primaryContact.name"} ref={register} />
                        <Error error={_.get("primaryContact.name", errors)} />
                    </Box>
                    <Box>
                        <UppercaseLabel mb={1}>primary contact phone</UppercaseLabel>
                        <SmallTextInput name={"primaryContact.phone"} ref={register} />
                        <Error error={_.get("primaryContact.phone", errors)} />
                    </Box>
                    <Box>
                        <UppercaseLabel mb={1}>primary contact email</UppercaseLabel>
                        <SmallTextInput name={"primaryContact.email"} ref={register} />
                        <Error error={_.get("primaryContact.email", errors)} />
                    </Box>
                    <Box>
                        <UppercaseLabel mb={1}>primary contact role</UppercaseLabel>
                        <SmallTextInput name={"primaryContact.label"} ref={register} />
                        <Error error={_.get("primaryContact.label", errors)} />
                    </Box>
                    <Box>
                        <UppercaseLabel mb={1}>street address</UppercaseLabel>
                        <SmallTextInput name={"streetAddress"} ref={register} />
                        <Error error={_.get("streetAddress", errors)} />
                    </Box>
                    <Box>
                        <UppercaseLabel mb={1}>city</UppercaseLabel>
                        <SmallTextInput name={"city"} ref={register} />
                        <Error error={_.get("city", errors)} />
                    </Box>
                    <Box>
                        <UppercaseLabel mb={1}>state</UppercaseLabel>
                        <SmallTextInput name={"state"} ref={register} />
                        <Error error={_.get("state", errors)} />
                    </Box>
                    <Box>
                        <UppercaseLabel mb={1}>zip code</UppercaseLabel>
                        <SmallTextInput name={"zipCode"} ref={register} />
                        <Error error={_.get("zipCode", errors)} />
                    </Box>
                    <Box>
                        <UppercaseLabel mb={1}>strategist</UppercaseLabel>
                        <Controller
                            control={control}
                            name={`strategist`}
                            render={({ onChange, value }) => (
                                <Select
                                    classNamePrefix={`strategist`}
                                    value={_.filter({ _id: value }, employees)}
                                    menuPlacement={"auto"}
                                    isOptionSelected={_.flow(_.get("_id"), _.isEqual(value))}
                                    options={_.filter(
                                        (employee) => employee.positions.includes("STRATEGIST"),
                                        employees
                                    )}
                                    onChange={(employee) => onChange(employee._id)}
                                    getOptionLabel={(employee) => _.get("name", employee)}
                                />
                            )}
                        />
                    </Box>

                    {fields.map((field, index) => (
                        <FlexRow gridColumn={"1/-1"} key={index}>
                            <Box flexGrow={1}>
                                <Controller
                                    control={control}
                                    name={`practices[${index}].data`}
                                    defaultValue={field.data}
                                    render={({ onChange, value }) => (
                                        <Select
                                            classNamePrefix={`selectPractice${index}`}
                                            value={_.filter({ _id: value }, practices)}
                                            menuPlacement={"auto"}
                                            isOptionSelected={_.flow(
                                                _.get("_id"),
                                                _.isEqual(value)
                                            )}
                                            options={_.differenceWith(
                                                (practice, _id) => _.isEqual(_id, practice._id),
                                                practices,
                                                getValues().practices
                                            )}
                                            onChange={(practice) => onChange(practice._id)}
                                            getOptionLabel={(practice) =>
                                                `${practice.practiceName} -- ${_.get(
                                                    "primaryContact.name",
                                                    practice
                                                )}`
                                            }
                                        />
                                    )}
                                />
                            </Box>
                            <Box>
                                <CrossButton
                                    color={"red"}
                                    onClick={() => remove(index)}
                                    type={"button"}
                                />
                            </Box>
                        </FlexRow>
                    ))}
                    <Box gridColumn={"1 / -1"}>
                        <AddButton
                            testid={"linkAnotherPractice"}
                            onClick={() => append("")}
                            type={"button"}
                        />
                    </Box>
                    <Box gridColumnEnd={"-1"} justifySelf={"end"}>
                        <PrimaryButton type={"submit"}>submit</PrimaryButton>
                    </Box>
                </Grid>
            </form>
        </Box>
    );
}
