import { api } from "../utilityActions";
import slices from "../slices";
import {ententeUrl} from "../../config";

export default () => (dispatch) =>
    dispatch(
        api({
            call: {
                url: "/piston/secure/users/my-account",
                method: "get",
                baseURL: ententeUrl
            },
            success: slices.myAccount.actions.set,
        })
    );
