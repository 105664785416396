import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash/fp";

export default createSlice({
    name: "loading",
    initialState: {},
    reducers: {
        set: (state, { payload }) => _.set(payload, true, state),
        unset: (state, { payload }) => _.set(payload, false, state),
    },
});
