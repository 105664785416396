import slices from "../slices";
import { api } from "../utilityActions";
import logout from "./logout";
import {ententeUrl} from "../../config";

export default () =>
    api({
        call: {
            url: "/piston/secure/users/get-user",
            method: "get",
            baseURL: ententeUrl
        },
        success: slices.currentUser.actions.set,
        failure: logout,
    });
