import React from "react";
import ReactModal from "react-modal";
import { Heading2, TextButton } from "../../Atoms";
import { Box } from "../../StyledSystemUtilities";
import "./modal.css";

ReactModal.defaultStyles = {};

export const Modal = ({ title, children, isOpen, setIsOpen, ...props }) => {
    return (
        <ReactModal ariaHideApp={false} isOpen={isOpen} {...props}>
            <Box
                mb={6}
                display={"flex"}
                flexDirection={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
            >
                <Heading2>{title}</Heading2>
                <TextButton data-testid={"closeModal"} onClick={() => setIsOpen(false)}>
                    close
                </TextButton>
                {/*<Title*/}
                {/*    style={{ cursor: "pointer", whiteSpace: "nowrap" }}*/}
                {/*    onClick={() => setIsOpen(false)}*/}
                {/*>*/}
                {/*    CLOSE*/}
                {/*</Title>*/}
            </Box>
            {children}
            {/*<Box mb={20} />*/}
        </ReactModal>
    );
};
