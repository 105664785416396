import React from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { actions } from "../../../redux";
import { routerHistory } from "../../../routerHistory";
import { loginSchema } from "../../../yupSchemas";
import { Error, LargeTextInput, PrimaryButton } from "../../Atoms";
import { QuestionNavigationButton } from "../../Molecules";
import { Box, FlexCol } from "../../StyledSystemUtilities";
import { AuthenticationTemplate } from "../../Templates";

export const Login = () => {
    const dispatch = useDispatch();
    const { register, handleSubmit, errors } = useForm({
        resolver: loginSchema,
    });

    const onSubmit = (data) => dispatch(actions.login(data));

    return (
        <AuthenticationTemplate
            errorLabel={"login"}
            onSubmit={handleSubmit(onSubmit)}
            headerText={"Login"}
        >
            <div>
                <LargeTextInput placeholder={"Email"} name={"email"} ref={register} />
                <Error error={errors.email} />
            </div>
            <div>
                <LargeTextInput
                    type={"password"}
                    placeholder={"Password"}
                    name={"password"}
                    ref={register}
                />
                <Error error={errors.password} />
            </div>
            <div></div>

            <div>
                <FlexCol alignItems={"flex-end"}>
                    <Box>
                        <PrimaryButton type={"submit"}>login</PrimaryButton>
                    </Box>
                </FlexCol>

                <Box mb={13} />
                <Box mb={7} />
                <QuestionNavigationButton
                    onClick={() => routerHistory.push("/reset-password")}
                    text={"Forgot your password?"}
                    buttonText={"reset password"}
                />
            </div>
        </AuthenticationTemplate>
    );
};
