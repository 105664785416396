import { createSlice } from "@reduxjs/toolkit";

const clients = createSlice({
    name: "clientUsers",
    initialState: [],
    reducers: {
        set: (state, action) => action.payload,
    },
});

export default clients;
