import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Route } from "react-router-dom";
import { EmployeeSidebar } from "../../Organisms";
import { AdminSidebar } from "../../Organisms/Sidebars";
import ErrorBoundary from "../../Utilities/ErrorBoundary";
import { EmployeeManagement } from "../admin/EmployeeManagement";
import { BuildMain } from "../../Templates";
import { PracticeList } from "../shared/PracticeList";
import { AggregatedPpc } from "./AggregatedPpc";
import { ClientManagement } from "./ClientManagement";
import { Leads } from "./Leads";
import PracticeDashboard from "./PracticeDashboard";
import { actions } from "../../../redux";
import { LoginHistory } from "./LoginHistory";
import { IndividualLoginHistory } from "./IndividualLoginHistory";

const renderEmployeeRoutes = () => (
    <>
        <Route
            exact
            path={`/dash/practice-list`}
            render={() => (
                <ErrorBoundary>
                    <PracticeList />
                </ErrorBoundary>
            )}
        />
        <Route
            path={"/dash/practice-list/practice-dashboard"}
            render={() => (
                <ErrorBoundary>
                    <PracticeDashboard />
                </ErrorBoundary>
            )}
        />

        <Route path={"/dash/clients"} render={() => <ClientManagement />} />
        <Route path={"/dash/aggregated-ppc"} render={() => <AggregatedPpc />} />

        <Route path={"/dash/leads"} render={() => <Leads />} />
        <Route exact path={"/dash/login-history"} render={() => <LoginHistory />} />
        <Route path={"/dash/login-history/individual"} render={() => <IndividualLoginHistory />} />
    </>
);

function useInitialize() {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(actions.getPractices());
        dispatch(actions.getGoogleAccounts());
        dispatch(actions.getCallRailAccounts());
        dispatch(actions.getCallRailCompanies());
        dispatch(actions.getRecurlyAccounts());
        dispatch(actions.getClientUsers());
        dispatch(actions.getAdviceLocalAccounts());
        dispatch(actions.getVeloxPractices());
        dispatch(actions.getSwellLocations());
    }, [dispatch]);
}

export function EmployeeMain() {
    useInitialize();

    return (
        <BuildMain
            renderSidebar={(setShowSidebar) => <EmployeeSidebar setShowSidebar={setShowSidebar} />}
            renderRoutes={() => <>{renderEmployeeRoutes()}</>}
        />
    );
}

export function AdminMain() {
    useInitialize();

    return (
        <BuildMain
            renderSidebar={(setShowSidebar) => <AdminSidebar setShowSidebar={setShowSidebar} />}
            renderRoutes={() => (
                <>
                    {renderEmployeeRoutes()}
                    <Route path={"/dash/admin"} render={() => <EmployeeManagement />} />
                </>
            )}
        />
    );
}
