import { api } from "../utilityActions";
import slices from "../slices";
import {ententeUrl} from "../../config";
export default (startDate, endDate) =>
    api({
        call: {
            url: "/piston/secure/aggregated-reports/get-aggregated-ppc-report",
            method: "get",
            params: {
                startDate,
                endDate,
            },
            baseURL: ententeUrl
        },
        success: slices.aggregatedPpc.actions.set,
        label: "aggregatedPpc",
    });
