import _ from "lodash/fp";
import React from "react";
import { Error, SmallTextInput, UppercaseLabel } from "../Atoms";
import { Box } from "../StyledSystemUtilities";

export { QuestionNavigationButton } from "./QuestionButton";
export { PageHeading } from "./PageHeading";
export { DotLegend } from "./DotLegend";

export { ReviewsRankingPlot } from "./DataVisualization/ReviewsRankingPlot";

export { CompetitorsTable } from "./DataVisualization/CompetitorsTable";
export { Doughnut } from "./DataVisualization/Doughnut";
export { LabeledCheckbox } from "./LabeledCheckbox";
export { PrimaryTable, MultiYearTable } from "./DataVisualization/Tables";
export { LoadingMessage } from "./LoadingMessage";

export { LoadingCard } from "./LoadingCard";

export const TextInputWithLabel = ({ label, path, errors, register, ...props }) => (
    <Box>
        <UppercaseLabel htmlFor={path}>{label}</UppercaseLabel>
        <Box mb={1} />
        <SmallTextInput id={path} aria-label={path} name={path} ref={register} {...props} />
        <Error error={_.get(path, errors)} />
    </Box>
);
