import React from "react";
import { Grid } from "../StyledSystemUtilities";

export { Spinner } from "./Spinner";

export {
    NavigationButton,
    EditButton,
    InfoButton,
    PrimaryButton,
    SaveButton,
    HamburgerButton,
    AddButton,
    CrossButton,
    ProfileButton,
    TextButton,
} from "./Inputs/Buttons";

export {
    Heading,
    Heading2,
    Body2,
    BlueBody4,
    Body4,
    UppercaseLabel,
    Body3,
    BlueBody2,
    BlueBody3,
    BlackBody1,
    BlackBody2,
    BlackBody3,
} from "./Typography";

export { Checkbox } from "./Inputs/Checkbox";

export {
    LargeTextInput,
    SmallTextInput,
    TextAreaInput,
    SpecialTextInput,
} from "./Inputs/TextInputs";

export { Gradient } from "./Gradient";
export { LargeLogo, SmallLogo } from "./Logo";
export { Dot } from "./Dot";

export { FullPageBackground } from "./FullPageBackground";
export { Error } from "./Error";
export { ErrorAlert } from "./Alerts";
export { Hamburger, Cross } from "./Svg";

export { Card, FullCard } from "./Cards";

export { Arrow } from "./Arrow";
export { Select } from "./Inputs/Select";
export { DatePicker } from "./Inputs/DatePicker";
export { Cell } from "./Cells";

export const ModalFormContainer = (props) => (
    <Grid
        gridColumnGap={4}
        gridRowGap={8}
        gridTemplateColumns={"repeat(auto-fill, minmax(280px, 1fr))"}
        alignItems={"end"}
        {...props}
    />
);
