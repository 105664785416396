import { api } from "../utilityActions";
import getDirectMailCampaigns from "./getDirectMailCampaigns";
import {ententeUrl} from "../../config";

export default (campaignId, update) =>
    api({
        call: {
            url: "/piston/secure/direct-mail/update-campaign",
            method: "patch",
            data: { campaignId, update },
            baseURL: ententeUrl
        },
        success: getDirectMailCampaigns,
    });
