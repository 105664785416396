import _ from "lodash/fp";
import React, { useMemo, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { getColor } from "../../../helpers/themeGetters";

import useErrors from "../../../hooks/useErrors";
import { actions } from "../../../redux";
import * as R from "remeda";
import {
    Body3,
    Card,
    Checkbox,
    EditButton,
    ErrorAlert,
    Heading,
    Heading2,
    SmallTextInput,
    UppercaseLabel,
} from "../../Atoms";
import { RawArrowRight } from "../../Atoms/assets";
import { Text } from "../../Atoms/Typography";
import { Modal, PracticeForm, SelectionBar } from "../../Organisms";
import { Box, FlexRow } from "../../StyledSystemUtilities";

const BlueArrowRight = styled(RawArrowRight)`
    fill: ${getColor("balticBlue")};
`;

const Base = (props) => (
    <FlexRow
        alignItems={"center"}
        height={"45px"}
        px={8}
        justifyContent={"space-between"}
        {...props}
    />
);

const Left = (props) => (
    <Base pointer backgroundColor={"chalk"} color={"balticBlue"} fontSize={3} {...props} />
);

function isMissingAccount(practice) {
    return [
        !_.get("googleAnalyticsPropertyId", practice),
        !_.get("googleSearchConsoleUrl", practice),
        !_.get("googleMyBusinessAccountId", practice),
    ].some((x) => x);
}

const List = ({ practices, handleClick }) => {
    return practices.map((practice) => {
        return (
            <React.Fragment key={practice._id}>
                <Left data-testid={practice._id} onClick={() => handleClick(practice._id)}>
                    {practice.practiceName} <BlueArrowRight />
                </Left>

                <Base>
                    <FlexRow alignItems="center">
                        <UppercaseLabel>CONTACT</UppercaseLabel>
                        <Box ml={6} />
                        <Body3>{practice.primaryContact.name}</Body3>
                    </FlexRow>

                    <FlexRow alignItems={"center"}>
                        {isMissingAccount(practice) && practice.active ? (
                            <Text fontSize={2} color={"errorDark"}>
                                Missing Google Account
                            </Text>
                        ) : null}

                        <FlexRow ml={6} alignItems="center">
                            <Body3>Active</Body3>
                            <Box ml={2} />
                            <Checkbox checked={practice.active} />
                        </FlexRow>
                    </FlexRow>
                </Base>
            </React.Fragment>
        );
    });
};
function filterBySearchTerm(searchTerm) {
    const lowercasedSearchTerm = _.lowerCase(searchTerm);
    return _.filter(
        (practice) =>
            _.includes(lowercasedSearchTerm, _.lowerCase(practice.practiceName)) ||
            _.includes(lowercasedSearchTerm, _.lowerCase(_.get("primaryContact.name", practice)))
    );
}

const buildTransformer = (isFilteredByActive, searchTerm, sortPath, sortDirection) =>
    _.flow(
        isFilteredByActive ? _.filter("active") : _.identity,
        !_.isEmpty(searchTerm) ? filterBySearchTerm(searchTerm) : _.identity,
        _.orderBy([sortPath], [sortDirection])
    );

export const PracticeList = () => {
    const dispatch = useDispatch();
    const currentUser = useSelector((state) => state.currentUser);
    const practices = useSelector((state) => state.practices);

    const [isModalOpen, setIsModalOpen] = useState(false);

    const handlePracticeAdd = (practiceData) => {
        dispatch(actions.addPractice(practiceData));
        setIsModalOpen(false);
    };

    const [searchTerm, setSearchTerm] = useState("");
    const [isFilteredByActive, setIsFilteredByActive] = useState(true);
    const [sortPath, setSortPath] = useState("practiceName");
    const [sortDirection, setSortDirection] = useState("asc");

    const transformedPractices = useMemo(() => {
        return buildTransformer(isFilteredByActive, searchTerm, sortPath, sortDirection)(practices);
    }, [practices, isFilteredByActive, sortPath, sortDirection, searchTerm]);

    const handleSort = (path) => {
        if (_.isEqual(sortPath, path)) {
            if (sortDirection === "asc") {
                setSortDirection("desc");
            } else {
                setSortDirection("asc");
            }
        } else {
            setSortDirection("asc");
            setSortPath(path);
        }
    };

    const handlePracticeSelect = (id) => {
        dispatch(actions.selectPractice(id));
        /*routerHistory.push("/login");*/
    };
    const error = useErrors("getPractices");
    return (
        <>
            <ErrorAlert message={error} />
            <Modal title={"Add a Practice"} isOpen={isModalOpen} setIsOpen={setIsModalOpen}>
                <PracticeForm superviseSubmit={handlePracticeAdd} />
            </Modal>

            <Card gridColumn={"1 / -1"}>
                <FlexRow justifyContent={"space-between"} flexWrap={"wrap"}>
                    <Box>
                        <Heading data-testid={"pageHeadline"}>{"Practice List"}</Heading>
                        <Box mb={2} />
                        <Heading2 data-testid={"pageSubheader"}>
                            {_.get("user.name", currentUser)}
                        </Heading2>
                    </Box>
                    <Box alignSelf={"flex-end"}>
                        {/*<EditButton*/}
                        {/*    data-testid={"addPracticeButton"}*/}
                        {/*    onClick={() => setIsModalOpen(true)}*/}
                        {/*>*/}
                        {/*    add practice*/}
                        {/*</EditButton>*/}
                    </Box>
                </FlexRow>
            </Card>

            <SelectionBar
                items={[
                    {
                        text: "practice",
                        handleClick: () => handleSort("practiceName"),
                        testid: "sortByPractice",
                    },
                    {
                        text: "contact",
                        handleClick: () => handleSort("primaryContact.name"),
                        testid: "sortByContactName",
                    },
                ]}
            />
            <Box gridColumn={"1/-1"} mb={6} />
            <FlexRow
                flexWrap={"wrap"}
                alignItems={"center"}
                justifyContent={"space-between"}
                gridColumn={"1/-1"}
                pl={8}
                mb={6}
            >
                <FlexRow alignItems={"center"}>
                    <Checkbox
                        onClick={() => setIsFilteredByActive((s) => !s)}
                        checked={isFilteredByActive}
                    />
                    <Box mr={6} />
                    <Body3>Show only active clients</Body3>
                </FlexRow>
                <Box width={19}>
                    <SmallTextInput
                        data-testid={"practiceListSearchInput"}
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        placeholder={"search..."}
                    />
                </Box>
            </FlexRow>
            {transformedPractices.length > 0 ? (
                <List practices={transformedPractices} handleClick={handlePracticeSelect} />
            ) : null}
        </>
    );
};
