import { api } from "../utilityActions";
import getUsers from "./getUsers";
import {ententeUrl} from "../../config";

export default (update, userId) =>
    api({
        call: {
            url: "/piston/secure/admin/manage-employees/edit-employee",
            method: "post",
            data: { update, userId },
            baseURL: ententeUrl
        },
        success: getUsers,
        label: "updateEmployee",
    });
