import React from "react";
import styled from "styled-components";
import { getColor, getSize } from "../../../helpers/themeGetters";
import { RawCheck } from "../assets";

const Check = styled(RawCheck)`
    fill: ${getColor("sienna")};
    margin: 2px 0px 0px 1px;
`;

const Box = styled.div`
    height: ${getSize(8)};
    width: ${getSize(8)};
    border: 1px solid ${getColor("pearlGray")};
    cursor: pointer;
`;

export const Checkbox = ({ testid, checkTestid, checked, onClick }) => {
    return (
        <Box data-testid={testid} onClick={onClick}>
            {checked ? <Check data-testid={checkTestid} /> : null}
        </Box>
    );
};
