import { parseJSON } from "date-fns/fp";
import _ from "lodash/fp";
import { deepMap } from "./uti";

const dateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;

const func = (x) => {
    if (_.isString(x) && dateFormat.test(x)) {
        const date = parseJSON(x)
        const userTimezoneOffset = date.getTimezoneOffset() * 60000;
        return new Date(date.getTime() + userTimezoneOffset);
    } else {
        return x;
    }
};

const deepDateParse = _.partial(deepMap, [func]);

export default deepDateParse;
