import { useEffect } from "react";

export const useDetectOutsideClick = (ref, handleOutsideClick) => {
    useEffect(() => {
        function handleClick(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                event.preventDefault();
                event.stopPropagation();
                handleOutsideClick();
            }
        }
        window.document.addEventListener("click", handleClick, true);
        return () => {
            window.document.removeEventListener("click", handleClick, true);
        };
    }, [ref]);
};
