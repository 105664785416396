import getUsers from "./getUsers";
import { api } from "../utilityActions";
import {ententeUrl} from "../../config";

export default (newEmployee) =>
    api({
        call: {
            url: "/piston/secure/admin/manage-employees/add-employee",
            method: "post",
            data: newEmployee ,
            baseURL: ententeUrl
        },
        success: getUsers,
        label: "addEmployee",
    });
