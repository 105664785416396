import { api } from "../utilityActions";
import slices from "../slices";
import {ententeUrl} from "../../config";
const getClients = () =>
    api({
        call: {
            url: "/piston/secure/clients/get-all",
            method: "get",
            baseURL: ententeUrl
        },
        success: slices.clients.actions.set,
    });

export default getClients;
