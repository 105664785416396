import React, { useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useTable } from "react-table";

import useErrors from "../../../hooks/useErrors";
import { actions } from "../../../redux";
import { employeeSchema } from "../../../yupSchemas";
import {
    BlackBody1,
    BlackBody2,
    EditButton,
    ErrorAlert,
    FullCard,
    ModalFormContainer,
    PrimaryButton,
    Select,
} from "../../Atoms";
import { PageHeading, TextInputWithLabel } from "../../Molecules";
import { Modal } from "../../Organisms";
import { Box } from "../../StyledSystemUtilities";
import { Table, Td, Th } from "../../tableUtilities";
import _ from "lodash/fp";

export const EmployeeManagement = () => {
    const dispatch = useDispatch();
    const employees = useSelector((state) => state.users);
    const error = useErrors("addEmployee");

    const [isAddEmployeeFormOpen, setIsAddEmployeeFormOpen] = useState(false);
    const [editId, setEditId] = useState(null);

    useEffect(() => {
        dispatch(actions.getUsers());
    }, [dispatch]);

    const handleAddEmployee = (newEmployee) => {
        dispatch(actions.addEmployee(newEmployee));
        setIsAddEmployeeFormOpen(false);
    };

    const handleEditEmployee = (form) => {
        dispatch(actions.editEmployee(form, editId));
        setEditId("");
    };

    const columns = useMemo(
        () => [
            { Header: "Name", accessor: "name" },
            {
                Header: "Email",
                accessor: "email",
            },
            {
                Header: "Calendly Link",
                accessor: "calendlyLink",
            },
            {
                Header: "Image URL",
                accessor: "imageUrl",
            },
            {
                Header: "Positions",
                accessor: (user) => user.positions.map(_.capitalize).slice().sort().join(", "),
            },
            {
                Header: "Edit",
                Cell: (props) => {
                    return (
                        <BlackBody2
                            onClick={() => setEditId(props.row.original._id)}
                            pointer
                            underlineOnHover
                        >
                            Edit
                        </BlackBody2>
                    );
                },
            },
        ],
        []
    );

    return (
        <>
            <Modal
                title={"Add Employee"}
                isOpen={isAddEmployeeFormOpen}
                setIsOpen={setIsAddEmployeeFormOpen}
            >
                <EmployeeForm
                    initialState={{ positions: [], calendlyLink: "", imageUrl: "" }}
                    error={error}
                    superviseSubmit={handleAddEmployee}
                />
            </Modal>
            <Modal
                title={`Edit ${employees.find((employee) => editId === employee._id)?.name}`}
                isOpen={Boolean(editId)}
                setIsOpen={() => setEditId(null)}
            >
                <EmployeeForm
                    initialState={employees.find((employee) => employee._id === editId)}
                    error={error}
                    superviseSubmit={handleEditEmployee}
                />
            </Modal>
            <ErrorAlert message={error} />
            <PageHeading
                headlineText={"Users"}
                renderButton={() => (
                    <EditButton onClick={() => setIsAddEmployeeFormOpen(true)}>
                        add employee
                    </EditButton>
                )}
            />
            <BlackBody1>Employees</BlackBody1>
            <FullCard mb={5}>
                <DataGrid testid={"employeeTable"} columns={columns} data={employees} />
            </FullCard>
        </>
    );
};

function DataGrid({ columns, data, testid }) {
    const { rows, prepareRow, headerGroups, getTableProps } = useTable({
        columns,
        data,
    });

    return (
        <Table data-testid={testid} {...getTableProps()}>
            <thead>
                {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                            <Th {...column.getHeaderProps()}>{column.render("Header")}</Th>
                        ))}
                    </tr>
                ))}
            </thead>
            <tbody {...getTableProps()}>
                {rows.map((row) => {
                    prepareRow(row);
                    return (
                        <tr {...row.getRowProps()}>
                            {row.cells.map((cell) => (
                                <Td {...cell.getCellProps()}>{cell.render("Cell")}</Td>
                            ))}
                        </tr>
                    );
                })}
            </tbody>
        </Table>
    );
}

function EmployeeForm({ superviseSubmit, initialState }) {
    const { register, handleSubmit, errors, control } = useForm({
        defaultValues: initialState,
        resolver: employeeSchema,
    });

    return (
        <Box>
            <form onSubmit={handleSubmit(superviseSubmit)}>
                <ModalFormContainer>
                    <Box>
                        <TextInputWithLabel
                            label={"name"}
                            errors={errors}
                            register={register}
                            path={"name"}
                        />
                    </Box>
                    <Box>
                        <TextInputWithLabel
                            label={"email"}
                            errors={errors}
                            register={register}
                            path={"email"}
                        />
                    </Box>
                    <Box>
                        <TextInputWithLabel
                            label={"calendly link"}
                            errors={errors}
                            register={register}
                            path={"calendlyLink"}
                        />
                    </Box>
                    <Box>
                        <TextInputWithLabel
                            label={"image url"}
                            errors={errors}
                            register={register}
                            path={"imageUrl"}
                        />
                    </Box>
                    <Controller
                        name={"positions"}
                        render={({ onChange, value }) => {
                            return (
                                <Select
                                    menuPlacement="top"
                                    value={value}
                                    options={["ADMIN", "STRATEGIST"]}
                                    onChange={onChange}
                                    getOptionValue={(x) => x}
                                    getOptionLabel={(x) => x}
                                    isMulti={true}
                                />
                            );
                        }}
                        control={control}
                    />
                    <Box>
                        <PrimaryButton>submit</PrimaryButton>
                    </Box>
                </ModalFormContainer>
            </form>
        </Box>
    );
}
