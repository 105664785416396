import { api } from "../utilityActions";
import loginWithToken from "./loginWithToken";
import {ententeUrl} from "../../config";

export default (passwordResetToken, newPassword) =>
    api({
        call: {
            url: "/piston/open/authentication/set-new-password",
            method: "post",
            data: { passwordResetToken, newPassword },
            baseURL: ententeUrl
        },
        success: loginWithToken,
    });
