import axios from "axios";
import deepDateParse from "../../utilities/deepDateParse";
import { api } from "../utilityActions";
import slices from "../slices";

export const apiMiddleware =
    ({ dispatch }) =>
    (next) =>
    async (action) => {
        if (action.type !== api.type) {
            return next(action);
        }
        const { call, success, failure, label } = action.payload;
        try {
            dispatch(slices.loading.actions.set(label));
            const { data } = await axios({ baseURL: process.env.REACT_APP_BASE_URL, ...call });
            const dateParsedData = deepDateParse(data);
            if (success) {
                dispatch(success(dateParsedData));
            }
            dispatch(slices.serverErrors.actions.unset(label));
            dispatch(slices.loading.actions.unset(label));
        } catch (e) {
            dispatch(slices.loading.actions.unset(label));
            console.log(e);
            dispatch(slices.serverErrors.actions.set(label, e.response.data));
            if (failure) {
                dispatch(failure(e.response));
            }
            console.log(e.response);
        }
    };
