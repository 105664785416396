import React, { useState } from "react";
import { actions } from "../../../redux";
import { resetPasswordSchema } from "../../../yupSchemas";
import { LargeTextInput, PrimaryButton, BlueBody3, Error } from "../../Atoms";
import { AuthenticationTemplate } from "../../Templates";

import { Box } from "../../StyledSystemUtilities";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";

export const ResetPassword = () => {
    const dispatch = useDispatch();
    const { register, handleSubmit, errors } = useForm({
        resolver: resetPasswordSchema,
    });
    const [isSubmitted, setIsSubmitted] = useState(false);

    const onSubmit = (formData) => {
        dispatch(actions.resetPassword(formData.email));
        setIsSubmitted(true);
    };

    return (
        <AuthenticationTemplate
            errorLabel={"resetPassword"}
            onSubmit={handleSubmit(onSubmit)}
            headerText={"Reset Password"}
        >
            {!isSubmitted ? (
                <Box gridColumn={"1"}>
                    <Box>
                        <LargeTextInput name={"email"} ref={register} placeholder="email" />
                        <Box mb={1} />
                        <Error error={errors.email} />
                    </Box>
                    <Box mb={8} />

                    <Box display={"flex"} flexDirection={"column"} alignItems={"flex-end"}>
                        <Box>
                            <PrimaryButton type={"submit"}>submit</PrimaryButton>
                        </Box>
                    </Box>
                </Box>
            ) : (
                <Box gridColumn={"1"}>
                    <BlueBody3>
                        Please check your email for instructions on how to reset your password.
                    </BlueBody3>
                </Box>
            )}
        </AuthenticationTemplate>
    );
};
