import { api } from "../utilityActions";
import {ententeUrl} from "../../config";

const resetPassword = (email) =>
    api({
        call: {
            url: "/piston/open/authentication/reset-password",
            method: "post",
            data: { email },
            baseURL: ententeUrl
        },
    });

export default resetPassword