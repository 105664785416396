import slices from "../slices";
import { api } from "../utilityActions";
import getPractices from "./getPractices";
import {ententeUrl} from "../../config";

export default (practiceId, update) =>
    api({
        call: {
            url: "/piston/secure/practices/update-practice",
            method: "patch",
            data: { practiceId, update },
            baseURL: ententeUrl
        },
        success: (updatedPractice) => [
            slices.openPractice.actions.set(updatedPractice),
            getPractices(),
        ],
    });
