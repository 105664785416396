import { api } from "../utilityActions";
import slices from "../slices";
import {ententeUrl} from "../../config";

export default (practiceId) =>
    api({
        call: {
            url: `/piston/secure/recurly/growth/get-google-ads-add-ons/${practiceId}`,
            method: "get",
            baseURL: ententeUrl
        },
        success: slices.googleAdsAddOns.actions.set,
    });
