import _ from "lodash/fp";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useExpanded, useFilters, useSortBy, useTable } from "react-table";
import styled from "styled-components";
import { getBorder, getColor } from "../../../helpers/themeGetters";
import { actions, slices } from "../../../redux";
import {
    convertUTCDateToLocalDate,
    formatDate,
    getAddressString,
    moneyCents,
} from "../../../utilities";
import getHostName from "../../../utilities/getHostName";
import { BlueBody3, Body2, DatePicker, FullCard, Heading, Heading2 } from "../../Atoms";
import { Box, FlexRowAlignCenter, Grid, Span } from "../../StyledSystemUtilities";
import FilterAsset from "../../Atoms/assets/filterIcon.png";
import { FilterDropDown } from "../../Organisms/FilterDropDown";
import { LoadingCard } from "../../Molecules";
import axios from "axios";
import { ententeUrl } from "../../../config";
import { useApi } from "../../../hooks/useApi";
import { subMonths } from "date-fns/fp";

const FETCH_LIMIT = 350;
const currentDate = new Date();

export function Leads() {
    // const { leads, isMore, last } = useSelector((state) => state.leads);
    // const isLoading = useSelector((state) => state.loading.leads);
    // const leadsDoc = useRef(null);

    //
    // useEffect(() => {
    //     dispatch(slices.leads.actions.clear());
    //     dispatch(
    //         actions.getLeads({
    //             limit: FETCH_LIMIT,
    //             startDate,
    //             endDate,
    //             sources: sourceFilterOptions,
    //         })
    //     );
    // }, [dispatch, startDate, endDate]);
    //
    // useEffect(() => {
    //     const handleScroll = () => {
    //         if (
    //             window.innerHeight + window.scrollY >= leadsDoc?.current?.clientHeight &&
    //             isMore &&
    //             last &&
    //             !isLoading
    //         ) {
    //             let lastIds = [];
    //             for (let i = leads.length - FETCH_LIMIT; i < leads.length; i++) {
    //                 lastIds.push(leads[i]._id);
    //             }
    //             dispatch(
    //                 actions.getLeads({
    //                     limit: FETCH_LIMIT,
    //                     last,
    //                     sources: sourceFilterOptions,
    //                     lastIds,
    //                     startDate,
    //                     endDate,
    //                 })
    //             );
    //         }
    //     };
    //
    //     window.addEventListener("scroll", handleScroll);
    //     return () => window.removeEventListener("scroll", handleScroll);
    // }, [isMore, last, leadsDoc, isLoading]);

    const [pageNumber, setPageNumber] = useState(0);

    const [startDate, setStartDate] = useState(subMonths(12, currentDate));
    const [endDate, setEndDate] = useState(currentDate);
    const [sourceFilterOptions, setSourceFilterOptions] = useState([]);

    console.log(startDate, endDate, pageNumber, sourceFilterOptions);

    useEffect(() => {
        setPageNumber(0);
    }, [startDate, endDate, sourceFilterOptions]);

    const [nullableLeads, isLoading] = useApi(async () => {
        return (
            await axios.post(
                "/piston/secure/leads/get",
                {
                    startDate,
                    endDate,
                    sourceFilterOptions,
                    pageNumber,
                },
                { baseURL: ententeUrl }
            )
        ).data;
    }, [startDate, endDate, sourceFilterOptions, pageNumber]);

    const leads = nullableLeads ?? [];

    const decrementPage = () => {
        if (isLoading) {
            return;
        }
        if (pageNumber === 0) {
            return;
        }

        setPageNumber((prev) => prev - 1);
    };

    const incrementPage = () => {
        if (isLoading) {
            return;
        }

        if (leads.length === 0) {
            return;
        }

        setPageNumber((prev) => prev + 1);
    };

    const columns = useMemo(
        () => [
            {
                Header: "Date",
                accessor: "date",
                sortType: "datetime",
                Cell: (cell) => formatDate(convertUTCDateToLocalDate(cell.value)),
            },
            {
                Header: "Source",
                accessor: (row) => getHostName(row.source),
            },
            {
                Header: "Name",
                accessor: "userName",
            },
            {
                Header: "Email",
                accessor: "email",
            },
            {
                Header: "Practice",
                accessor: "practiceName",
            },
            {
                Header: "Address",
                accessor: (row) => {
                    return getAddressString(row);
                },
            },
            {
                Header: "Type",
                accessor: (row) => _.startCase(row.practiceType),
            },
        ],
        []
    );
    return (
        <Box gridColumn={"1 / -1"}>
            <FullCard>
                <FlexRowAlignCenter justifyContent={"space-between"}>
                    <Box>
                        <Heading>Leads</Heading>
                        <Box height={2} />
                        <Heading2>
                            {/*{page.length}*/}
                            {/*{isMore ? "+" : null} Results*/}
                        </Heading2>
                    </Box>

                    <FlexRowAlignCenter>
                        <Body2 mr={3}>start</Body2>
                        <DatePicker
                            maxDate={endDate}
                            onChange={setStartDate}
                            selected={startDate}
                            hide={true}
                        />
                        <Box mr={10} />
                        <Body2 mr={3}>end</Body2>
                        <DatePicker
                            minDate={startDate}
                            maxDate={new Date()}
                            onChange={setEndDate}
                            selected={endDate}
                        />
                        <Box mr={10} />
                    </FlexRowAlignCenter>
                </FlexRowAlignCenter>
            </FullCard>

            <FullCard>
                <FlexRowAlignCenter justifyContent={"space-between"}>
                    <BlueBody3>Click on row to expand and see products.</BlueBody3>{" "}
                    <Box>
                        <Span onClick={decrementPage} cursor={"pointer"}>
                            ⬅️
                        </Span>
                        <Span userSelect={"none"}> page {pageNumber + 1} </Span>
                        <Span onClick={incrementPage} cursor={"pointer"}>
                            ➡️
                        </Span>
                    </Box>
                </FlexRowAlignCenter>
                <Box height={5} />
                <DataGrid
                    data={leads}
                    columns={columns}
                    sourceFilterOptions={sourceFilterOptions}
                    setSourceFilterOptions={setSourceFilterOptions}
                />
                <LoadingCard isLoading={isLoading} />
                <Box height={10} />
            </FullCard>
        </Box>
    );
}

function FilterIcon({ filtered }) {
    return (
        <FlexRowAlignCenter
            justifyContent={"center"}
            width={9}
            height={9}
            backgroundColor={filtered ? "slateBlue" : null}
            borderRadius={5}
        >
            <img src={FilterAsset} width={20} height={20} />
        </FlexRowAlignCenter>
    );
}

const SOURCES = [
    "hs-one.ascentpgs.com",
    "insight.ascentpgs.com",
    "insight.bestfordentistry.com",
    "burkhart.ascentpgs.com",
    "insight-unitas.ascentpgs.com",
    "insight-patterson.ascentpgs.com",
    "aadom.ascentpgs.com",
    // "localhost",
    "insight1.ascentpgs.com",
    // "insight-pipe-new-descri-wjq0d0.herokuapp.com",
];

function DataGrid({ columns, data, sourceFilterOptions, setSourceFilterOptions }) {
    const initialState = useMemo(() => {
        return {
            sortBy: [
                {
                    id: "date",
                    desc: true,
                },
            ],
        };
    }, []);
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        visibleColumns,
        toggleAllRowsExpanded,
    } = useTable(
        {
            initialState,
            columns,
            data,
        },
        useFilters,
        useSortBy,
        useExpanded
    );

    const [sourceDropDownOpen, setSourceDropDownOpen] = useState(false);
    const [resultsAreFilteredBySource, setResultsAreFilteredBySource] = useState(false);
    const [initialCheckedSourceOptions, setInitialCheckedSourceOptions] = useState([]);

    const handleSourceFilterClicked = () => {
        if (sourceDropDownOpen) {
            setSourceFilterOptions(initialCheckedSourceOptions);
        } else {
            setInitialCheckedSourceOptions(sourceFilterOptions);
        }

        setSourceDropDownOpen(!sourceDropDownOpen);
    };

    const dataGridHandleFilterSourcesEvent = () => {
        if (sourceFilterOptions.length > 0) {
            setResultsAreFilteredBySource(true);
        } else {
            setResultsAreFilteredBySource(false);
        }
    };

    const sourceTableHeaderRef = useRef(null);

    return (
        <>
            {sourceDropDownOpen ? (
                <FilterDropDown
                    options={SOURCES}
                    setFilterOptions={setSourceFilterOptions}
                    checkedOptions={sourceFilterOptions}
                    onCancelClicked={() => {
                        setSourceDropDownOpen(false);
                        setSourceFilterOptions(initialCheckedSourceOptions);
                    }}
                    onOkClicked={() => {
                        setSourceDropDownOpen(false);
                        dataGridHandleFilterSourcesEvent();
                    }}
                    headerRef={sourceTableHeaderRef}
                />
            ) : null}
            <Table {...getTableProps()}>
                <thead>
                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column, index) => (
                                <>
                                    {column.Header === "Source" ? (
                                        <th
                                            ref={sourceTableHeaderRef}
                                            {...column.getHeaderProps()}
                                            onClick={handleSourceFilterClicked}
                                            key={index}
                                        >
                                            <Box>
                                                <FlexRowAlignCenter
                                                    justifyContent={"space-between"}
                                                >
                                                    {column.render("Header")}
                                                    <Box mr={2} />
                                                    <FilterIcon
                                                        filtered={resultsAreFilteredBySource}
                                                    />
                                                </FlexRowAlignCenter>
                                            </Box>
                                        </th>
                                    ) : (
                                        <th
                                            {...column.getHeaderProps()}
                                            {...column.getSortByToggleProps()}
                                            key={index}
                                        >
                                            <Box>
                                                <FlexRowAlignCenter
                                                    justifyContent={"space-between"}
                                                >
                                                    {column.render("Header")}
                                                    <Box mr={2} />
                                                    {column.isSorted
                                                        ? column.isSortedDesc
                                                            ? " 🔽"
                                                            : " 🔼"
                                                        : ""}
                                                </FlexRowAlignCenter>
                                            </Box>

                                            {column.canFilter && column.Filter
                                                ? column.render("Filter")
                                                : null}
                                        </th>
                                    )}
                                </>
                            ))}
                        </tr>
                    ))}
                </thead>

                <tbody {...getTableBodyProps()}>
                    {rows.map((row) => {
                        prepareRow(row);
                        const rowProps = row.getRowProps();
                        return (
                            <React.Fragment key={rowProps.key}>
                                <tr
                                    onClick={() => {
                                        const temp = row.isExpanded;
                                        toggleAllRowsExpanded(false);
                                        row.toggleRowExpanded(!temp);
                                    }}
                                >
                                    {row.cells.map((cell) => (
                                        <td
                                            {...cell.getCellProps({
                                                style: cell.column.getStyle
                                                    ? cell.column.getStyle(cell)
                                                    : {},
                                            })}
                                        >
                                            {cell.render("Cell")}
                                        </td>
                                    ))}
                                </tr>

                                {row.isExpanded ? (
                                    <tr>
                                        <td colSpan={visibleColumns.length}>{Products(row)}</td>
                                    </tr>
                                ) : null}
                            </React.Fragment>
                        );
                    })}
                </tbody>
            </Table>
        </>
    );
}

const Table = styled.table`
    width: 100%;
    border-collapse: collapse;
    font-family: Arial;
    font-size: 14px;
    overflow-x: scroll;

    tbody tr {
        &:hover {
            background-color: #fbfbfb;
        }
    }
    th,
    td {
        white-space: nowrap;
        min-width: 80px;
        border: ${getBorder("gray")};
        text-align: left;
        padding: 10px;
        color: #181818;
        cursor: pointer;
    }

    th {
        background-color: ${getColor("chalk")};

        user-select: none;
    }
`;

function Products(row) {
    const { profit, growth, online } = row.original;

    return (
        <Box>
            <Box>
                <h4>Online</h4>
                <ProductList products={online} />
            </Box>

            <Box>
                <h4>Growth</h4>
                <ProductList products={growth} />
            </Box>
            <Box>
                <h4>Profit - {_.startCase(row.original.profitPaymentType)}</h4>
                <ProductList products={profit} profitPaymentType={row.original.profitPaymentType} />
            </Box>
        </Box>
    );
}

function ProductList({ products, profitPaymentType = "" }) {
    return (
        <Grid width={"400px"} gridTemplateColumns={"1fr auto"} gridRowGap={4}>
            {products.map((product) => {
                const price =
                    profitPaymentType === "monthlyPayment"
                        ? (product.price * 1.12) / 12
                        : product.price;
                return (
                    <>
                        <Box>{product.name}</Box>
                        <Box>{moneyCents(price)}</Box>
                    </>
                );
            })}
        </Grid>
    );
}
