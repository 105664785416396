import React from "react";
import styled from "styled-components";
import { getColor, getFontSize, getSpace } from "../../helpers/themeGetters";
import { SmallLogo } from "../Atoms";
import { allStyleFunctions, Box } from "../StyledSystemUtilities";
import { Link, useLocation } from "react-router-dom";
import { routerHistory } from "../../routerHistory";
import _ from "lodash/fp";

const WithShow =
    (Component) =>
    ({ show = true, ...props }) => {
        return show ? <Component {...props} /> : null;
    };

const Container = styled.div`
    background-color: ${getColor("slateBlue")};
    width: 192px;
    height: 100%;
    padding-left: ${getSpace(9)};
    padding-top: ${getSpace(7)};
    z-index: 100;
`;

const StyledNavLinkRow = WithShow(styled(Link)`
    height: 30px;
    font-size: ${getFontSize(3)};
    font-family: Arial;
    cursor: pointer;
    text-decoration: none;
    &:visited {
        text-decoration: none;
    }
    color: white;
    display: flex;
    align-items: center;
    border-bottom: ${(props) => props.theme.borders.white};
    ${allStyleFunctions}
`);

const StyledA = WithShow(styled("a")`
    height: 30px;
    font-size: ${getFontSize(3)};
    font-family: Arial;
    cursor: pointer;
    text-decoration: none;
    &:visited {
        text-decoration: none;
    }
    color: white;
    display: flex;
    align-items: center;
    border-bottom: ${(props) => props.theme.borders.white};
    ${allStyleFunctions}
`);

const NavLinkRow = ({ used, ...props }) =>
    used ? (
        <StyledNavLinkRow color={"hsl(210, 4% 91%)"} fontSize={3} fontWeight={"bold"} {...props} />
    ) : (
        <StyledNavLinkRow {...props} />
    );

function BuildSidebar({ pathToHome, links, setShowSidebar }) {
    const { pathname } = useLocation();

    //const handleClick = () => (setShowSidebar ? setShowSidebar(false) : null);
    const handleClick = () => null;
    const isActive = (path) => pathname.includes(path);

    function renderLinks(links, parentPath = "/dash", leftMargin = 0) {
        return links.map((link) => {
            const fullPath = parentPath + link.path;
            const prefixPathOfChildren = fullPath + _.getOr("", "childrenPrefix", link);
            if (link.isExternal) {
                return link.renderContent();
            }
            return (
                <React.Fragment key={fullPath}>
                    <NavLinkRow
                        ml={`${leftMargin}px`}
                        onClick={handleClick}
                        used={isActive(fullPath)}
                        to={fullPath}
                    >
                        {link.renderContent()}
                    </NavLinkRow>

                    {isActive(prefixPathOfChildren) && link.children
                        ? renderLinks(link.children, prefixPathOfChildren, leftMargin + 16)
                        : null}
                </React.Fragment>
            );
        });
    }

    return (
        <Container>
            <Box
                pointer
                pb={9}
                borderBottom={"white"}
                onClick={() => {
                    routerHistory.push(pathToHome);
                    handleClick();
                }}
            >
                <SmallLogo />
            </Box>
            {renderLinks(links)}
        </Container>
    );
}

const employeeLinks = [
    {
        path: "/practice-list",
        renderContent: () => "Practice List",
        childrenPrefix: "/practice-dashboard",
        children: [
            {
                path: "/practice-info",
                renderContent: () => "Practice Info",
            },
            {
                path: "/setup-ppc",
                renderContent: () => "PPC Setup",
            },
        ],
    },
    {
        path: "/clients",
        renderContent: () => "Client Accounts",
    },
    {
        path: "/leads",
        renderContent: () => "Leads",
    },
    {
        path: "/aggregated-ppc",
        renderContent: () => "PPC",
    },
    {
        path: "/login-history",
        renderContent: () => "Login History",
    },
    {
        isExternal: true,
        path: "",
        renderContent: () => (
            <StyledA href={"https://app.ascentpgs.com/dashboard/integrations"} target={"_blank"}>
                Integrations
            </StyledA>
        ),
    },
];

export function AdminSidebar(props) {
    return BuildSidebar({
        pathToHome: "/dash/practice-list",
        links: employeeLinks,
        ...props,
    });
}

export function EmployeeSidebar(props) {
    return BuildSidebar({
        pathToHome: "/dash/practice-list",
        links: employeeLinks,
        ...props,
    });
}
