import React from "react";
import styled from "styled-components";
import { getColor } from "../../helpers/themeGetters";
import { RawArrowDown, RawArrowLeft, RawArrowRight } from "./assets";

const ArrowLeft = styled(RawArrowLeft)`
    fill: ${(props) => getColor(props.color)};
`;
const ArrowRight = styled(RawArrowRight)`
    fill: ${(props) => getColor(props.color)};
`;
const ArrowDown = styled(RawArrowDown)`
    fill: ${(props) => getColor(props.color)};
`;

export const Arrow = ({ variant = "left", color = "sienna" }) => {
    switch (variant) {
        case "left": {
            return <ArrowLeft color={color} />;
        }
        case "right": {
            return <ArrowRight color={color} />;
        }
        case "down": {
            return <ArrowDown color={color} />;
        }
        default:
        //do nothing
    }
};
