import { yupResolver } from "@hookform/resolvers/yup";
import { string, object, number, boolean, array, mixed, date, ref } from "yup";
import _ from "lodash/fp";
import practiceTypeOptions from "./practiceTypeOptions";
import { getPeriodFromPartial } from "./utilities";

export const employeeSchema = yupResolver(
    object().shape({
        name: string().required("Please enter a name.").trim(),
        email: string().email().required("Please enter an email.").trim(),
        calendlyLink: string(),
        imageUrl: string(),
        positions: array(string()),
    })
);

const callRailTrackerSchema = object().shape({
    name: string().required(),
    trackerId: string().required(),
});

export const subCampaignSchema = object().shape({
    campaignId: string().trim(),
    campaignName: string().trim(),
    customerId: string().trim(),
    customerName: string().trim(),
});

export const ppcCampaignSchema = yupResolver(
    object().shape({
        goals: object().shape({
            newPatients: number().typeError("Please enter a number.").default(0),
        }),
        active: boolean().default(true),
        linkedCallRailTrackers: array()
            .of(callRailTrackerSchema)
            .compact()
            .transform(_.map(_.get("data")))
            .default([]),
        subCampaigns: array()
            .of(subCampaignSchema)
            .compact()
            .transform(_.map(_.get("data")))
            .ensure(),
        recurlyCampaignId: string().trim(),
    })
);

export const loginSchema = yupResolver(
    object().shape({
        email: string()
            .required("Please enter your email address.")
            .email("Please enter a valid email.")
            .trim(),
        password: string().required("Please enter your password.").trim(),
    })
);

export const directMailCampaignSchema = yupResolver(
    object().shape({
        name: string().required("Please enter a campaign name.").trim(),
        goals: object().shape({
            newPatients: number().typeError("Please enter a number."),
        }),
        active: boolean().default(true),
        linkedCallRailTrackers: array()
            .compact()
            .transform(_.map(_.get("data")))
            .default([]),
        mailingPeriods: array()
            .of(
                object().shape({
                    budget: number().required("Please enter budget"),
                    numberOfMailers: number().required("Please enter number of mailers"),
                    startPeriod: object().shape({
                        month: number(),
                        year: number(),
                    }),
                    endPeriod: object().shape({
                        month: number(),
                        year: number(),
                    }),
                })
            )
            .compact()
            .transform(
                _.map((mailingPeriod) => {
                    return {
                        ...mailingPeriod,
                        startPeriod: getPeriodFromPartial(mailingPeriod.startPeriod),
                        endPeriod: getPeriodFromPartial(mailingPeriod.endPeriod),
                    };
                })
            ),
    })
);

export const practiceSchema = yupResolver(
    object().shape({
        practiceName: string().required("Please enter a practice name.").trim(),
        active: boolean(),
        type: mixed().oneOf(practiceTypeOptions).required("Please select a practice type."),
        perPatientValue: number().typeError("please enter a number."),
        details: string().trim(),
        strategist: string().required("Please enter a strategist name.").trim(),
        startDate: date().required("please enter a relationship start date."),
        primaryContact: object().shape({
            name: string().trim(),
            email: string().trim(),
            phone: string().trim(),
            label: string().trim(),
        }),
        googleMyBusinessAccountId: string(),
        googleAnalyticsPropertyId: string(),
        googleSearchConsoleUrl: string(),
        callRailCompanyId: string(),
        recurlyAccountCode: string(),
        adviceLocalClientId: string(),
        veloxPracticeId: string(),
        swellLocationId: number(),
        pmsType: string().required("Please select a Practice Management Software Type"),
    })
);

export const changePasswordSchema = yupResolver(
    object().shape({
        currentPassword: string().required("Please enter your current password"),
        newPassword: string()
            .required("Please enter a password.")
            .min(8, "Password must be at least 8 characters long.")
            .matches(/^.*[A-Z].*$/, "Password must contain a capitol letter.")
            .matches(/^.*\d.*$/, "Password must contain a number.")
            .trim(),
        confirmNewPassword: string()
            .required("Please confirm your password.")
            .oneOf([ref("newPassword"), null], "Passwords must match.")
            .trim(),
    })
);

export const chooseNewPasswordSchema = yupResolver(
    object().shape({
        password: string()
            .required("Please enter a new password.")
            .min(8, "Password must be at least 8 characters long.")
            .matches(/^.*[A-Z].*$/, "Password must contain a capitol letter.")
            .matches(/^.*\d.*$/, "Password must contain a number.")
            .trim(),
        confirmPassword: string()
            .required("Please confirm your new password.")
            .oneOf([ref("password"), null], "Passwords must match.")
            .trim(),
    })
);

export const resetPasswordSchema = yupResolver(
    object().shape({
        email: string()
            .required("Please enter your email address.")
            .email("Please enter a valid email.")
            .trim(),
    })
);

export const clientSchema = yupResolver(
    object().shape({
        accountName: string().required("Please enter an account name.").trim(),
        websiteAddress: string(),
        streetAddress: string(),
        city: string(),
        state: string(),
        zipCode: string(),
        primaryContact: object().shape({
            name: string().trim(),
            email: string().trim(),
            phone: string().trim(),
            label: string().trim(),
        }),
        strategist: string(),
    })
);

export const manageCampaignSchema = yupResolver(
    object().shape({
        ppcCampaigns: array()
            .of(
                object().shape({
                    data: object().shape({
                        campaignId: string().required(),
                        campaignName: string().required(),
                        customerName: string().required(),
                        customerId: string().required(),
                    }),
                })
            )
            .default([]),
    })
);
