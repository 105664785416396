import { format } from "date-fns/fp";
import _ from "lodash/fp";

export { moneyRound, numberRound, moneyCents } from "./numberFormatting";

export const formatDate = format("LLL d yyyy");

export const convertUTCDateToLocalDate = (date) => {
    return new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000);
};


export function getAddressString(object) {
    if (!object) {
        return "";
    }
    return `${object.streetAddress}, ${object.state}, ${object.city}, ${object.zipCode}`;
}

export function getDateFromPeriod(period) {
    return new Date(period.year, period.month);
}

export function getPeriodFromPartial({ year, month, day = 0 }) {
    return {
        ordinal: getOrdinal(year, month, day),
        day,
        month,
        year,
    };
}

function getOrdinal(year, month, day) {
    return _.toInteger(parseNumber(year) + parseNumber(month) + parseNumber(day));
}

function parseNumber(number) {
    if (number < 10) {
        return `0${number}`;
    } else {
        return `${number}`;
    }
}

export function transformCampaignInitialState(initialState) {
    if (_.get("linkedCallRailTrackers", initialState)) {
        return {
            ...initialState,
            linkedCallRailTrackers: initialState.linkedCallRailTrackers.map((tracker) => {
                return {
                    data: tracker,
                };
            }),
        };
    }

    return initialState;
}


