import { api } from "../utilityActions";
import slices from "../slices";
import {ententeUrl} from "../../config";

export default () =>
    api({
        call: {
            url: "/piston/secure/google-accounts/get-all",
            method: "get",
            baseURL: ententeUrl
        },
        success: slices.googleAccounts.actions.set,
    });
