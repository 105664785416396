import React from "react";
import styled, { css } from "styled-components";
import { color } from "styled-system";
import { getBorder, getColor, getSpace } from "../../../helpers/themeGetters";
import { allStyleFunctions } from "../../StyledSystemUtilities";
import { RawArrowDown, RawArrowLeft, RawArrowRight } from "../assets";
import { AddIcon, Cross, Hamburger, ProfileIcon } from "../Svg";

const BaseButton = styled.button`
    display: block;
    box-sizing: border-box;
    border: ${getBorder("gray")};
    ${color};
    background-color: white;
    width: 160px;
    height: 20px;
    font-size: 10px;
    text-transform: uppercase;
    font-family: Arial;
    letter-spacing: 0.05em;
    padding-top: 0.3em;
    cursor: pointer;
    padding-left: 3px;
    padding-right: 3px;
    :focus {
        outline: none;
    }
    ${allStyleFunctions}
`;
BaseButton.defaultProps = {
    color: "slateGray",
};

const arrowCss = css`
    position: relative;
    fill: ${(props) => (props.color ? props.color : "white")};
    float: ${(props) => (props.float ? props.float : "right")};
`;

const ArrowLeft = styled(RawArrowLeft)`
    ${arrowCss}
`;

const ArrowRight = styled(RawArrowRight)`
    ${arrowCss}
`;

const ArrowDown = styled(RawArrowDown)`
    ${arrowCss}
`;

const OrangeArrowRight = styled(ArrowRight)`
    fill: ${getColor("sienna")};
`;

const OrangeArrowLeft = styled(ArrowLeft)`
    fill: ${getColor("sienna")};
`;

const BlueArrowLeft = styled(ArrowLeft)`
    fill: ${getColor("balticBlue")};
`;

const BlueArrowRight = styled(ArrowRight)`
    fill: ${getColor("balticBlue")};
`;

const BlueArrowDown = styled(ArrowDown)`
    fill: ${getColor("balticBlue")};
`;

const StyledPrimaryButton = styled(BaseButton)`
    background-color: ${getColor("slateBlue")};
    border: none;
    color: white;
`;

export const PrimaryButton = (props) => (
    <StyledPrimaryButton {...props}>
        {props.children}
        <ArrowRight />
    </StyledPrimaryButton>
);
export const NavigationButton = (props) => (
    <BaseButton {...props}>
        {props.children}
        <BlueArrowRight />
    </BaseButton>
);
export const InfoButton = (props) => (
    <BaseButton {...props}>
        {props.children}
        {props.isSelected === true ? <BlueArrowDown /> : <BlueArrowLeft />}
    </BaseButton>
);
export const SaveButton = (props) => (
    <BaseButton {...props}>
        <OrangeArrowRight float={"left"} />
        {props.children}
    </BaseButton>
);
export const EditButton = (props) => (
    <BaseButton {...props}>
        {props.children}
        <OrangeArrowLeft />
    </BaseButton>
);
export const SquareButton = (props) => (
    <BaseButton
        width={60}
        height={40}
        borderRadius={5}
        backgroundColor={"slateBlue"}
        color={"white"}
        {...props}
    >
        {props.children}
    </BaseButton>
);

const IconButtonWrapper = styled.button`
    padding: ${getSpace(6)};
    display: block;
    box-sizing: border-box;
    border: none;
    background-color: inherit;
    font-family: Arial;
    cursor: pointer;
    :focus {
        outline: none;
    }
    ${allStyleFunctions}
`;

export const HamburgerButton = (props) => (
    <IconButtonWrapper {...props}>
        <Hamburger />
    </IconButtonWrapper>
);

export const AddButton = (props) => (
    <IconButtonWrapper data-testid={props.testid} {...props}>
        <AddIcon />
    </IconButtonWrapper>
);

export const CrossButton = ({ color, testid, ...props }) => (
    <IconButtonWrapper data-testid={testid} {...props}>
        <Cross color={color} />
    </IconButtonWrapper>
);

export const ProfileButton = ({ ...props }) => (
    <IconButtonWrapper {...props}>
        <ProfileIcon />
    </IconButtonWrapper>
);

export const TextButton = styled.button`
    padding: ${getSpace(4)} ${getSpace(8)};
    display: block;
    box-sizing: border-box;
    border: none;
    background-color: inherit;
    font-family: Arial;
    cursor: pointer;
    :hover {
        text-decoration: underline;
    }
    :focus {
        outline: none;
    }
    text-transform: uppercase;
    color: ${getColor("agateGray")};
`;
