export const moneyRound = (x, y = 0, allowZero = false) => {
    if (!x && !allowZero) {
        return "";
    }
    return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
        maximumFractionDigits: y
    }).format(x);
};

export const moneyCents = x => {
    if (!x) {
        return "";
    }
    return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 2
    }).format(x);
};

export const numberRound = (x, y = 0) => {
    if (!x) {
        return "";
    }
    return new Intl.NumberFormat("en-US", {
        style: "decimal",
        minimumFractionDigits: 0,
        maximumFractionDigits: y
    }).format(x);
};

export const percentage = (x, numberOfDigitsAfterDecimal = 0) => {
    if (!x) {
        return "";
    }
    return new Intl.NumberFormat("en-US", {
        style: "percent",
        minimumFractionDigits: 0,
        maximumFractionDigits: numberOfDigitsAfterDecimal
    }).format(x);
};
