import getClients from "./getClients";
import { api } from "../utilityActions";
import {ententeUrl} from "../../config";

const updateClient = (clientId, update) =>
    api({
        call: {
            url: "/piston/secure/clients/update",
            method: "patch",
            data: { clientId, update },
            baseURL: ententeUrl

        },
        success: getClients,
    });

export default updateClient;
