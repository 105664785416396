import currentUser from "./currentUser";
import practices from "./practices";
import openPractice from "./openPractice";
import myAccount from "./myAccount";
import dimensions from "./dimensions";
import loading from "./loading";
import serverErrors from "./serverErrors";
import growthData from "./growthData";
import practiceGrowthDataReport from "./practiceGrowthDataReport";
import seoReport from "./seoReport";
import directMailReport from "./directMailReport";
import ppcReport from "./ppcReport";
import users from "./users";
import googleAccounts from "./googleAccounts";
import leads from "./leads";
import ppcCampaigns from "./ppcCampaigns";
import callRailAccounts from "./callRailAccounts";
import aggregatedPpc from "./aggregatedPpc";
import directMailCampaigns from "./directMailCampaigns";
import clients from "./clients";
import aggregatedDirectMail from "./aggregatedDirectMail";
import reviewsReport from "./reviewsReport";
import googleAdsAddOns from "./googleAdsAddOns";
import callRailCompanies from "./callRailCompanies";
import recurlyAccounts from "./recurlyAccounts";
import clientUsers from "./clientUsers";
import insightReportingUsers from "./insightReportingUsers";
import accountEventHistory from "./accountEventHistory";
import adviceLocalAccounts from "./adviceLocalAccounts";
import veloxPractices from "./veloxPractices";
import contractPeriodsReport from "./contractPeriodsReport";
import strategists from "./strategists";
import aggregatedInvoiceReport from "./aggregatedInvoiceReport";
import swellLocations from "./swellLocations";

const slices = {
    currentUser,
    practices,
    openPractice,
    myAccount,
    dimensions,
    loading,
    serverErrors,
    growthData,
    practiceGrowthDataReport,
    seoReport,
    directMailReport,
    ppcReport,
    users,
    googleAccounts,
    leads,
    ppcCampaigns,
    callRailAccounts,
    aggregatedPpc,
    directMailCampaigns,
    clients,
    aggregatedDirectMail,
    reviewsReport,
    googleAdsAddOns,
    callRailCompanies,
    recurlyAccounts,
    clientUsers,
    insightReportingUsers,
    accountEventHistory,
    adviceLocalAccounts,
    veloxPractices,
    contractPeriodsReport,
    strategists,
    aggregatedInvoiceReport,
    swellLocations,
};

export default slices;
