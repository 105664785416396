import React from "react";
import { FullCard, Spinner } from "../Atoms";
import { FlexRowAlignCenter } from "../StyledSystemUtilities";

export function LoadingCard({ isLoading }) {
    return isLoading ? (
        <FullCard mt={10}>
            <FlexRowAlignCenter justifyContent={"center"}>
                <Spinner />
            </FlexRowAlignCenter>
        </FullCard>
    ) : null;
}
