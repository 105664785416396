import { combineReducers } from "redux";
import slices from "./slices";

const rootReducer = combineReducers({
    currentUser: slices.currentUser.reducer,
    practices: slices.practices.reducer,
    myAccount: slices.myAccount.reducer,
    dimensions: slices.dimensions.reducer,
    openPractice: slices.openPractice.reducer,
    loading: slices.loading.reducer,
    serverErrors: slices.serverErrors.reducer,
    growthData: slices.growthData.reducer,
    practiceGrowthDataReport: slices.practiceGrowthDataReport.reducer,
    seoReport: slices.seoReport.reducer,
    directMailReport: slices.directMailReport.reducer,
    ppcReport: slices.ppcReport.reducer,
    users: slices.users.reducer,
    googleAccounts: slices.googleAccounts.reducer,
    leads: slices.leads.reducer,
    ppcCampaigns: slices.ppcCampaigns.reducer,
    callRailAccounts: slices.callRailAccounts.reducer,
    aggregatedPpc: slices.aggregatedPpc.reducer,
    directMailCampaigns: slices.directMailCampaigns.reducer,
    clients: slices.clients.reducer,
    reviewsReport: slices.reviewsReport.reducer,
    googleAdsAddOns: slices.googleAdsAddOns.reducer,
    callRailCompanies: slices.callRailCompanies.reducer,
    recurlyAccounts: slices.recurlyAccounts.reducer,
    clientUsers: slices.clientUsers.reducer,
    insightReportingUsers: slices.insightReportingUsers.reducer,
    accountEventHistory: slices.accountEventHistory.reducer,
    adviceLocalAccounts: slices.adviceLocalAccounts.reducer,
    veloxPractices: slices.veloxPractices.reducer,
    contractPeriodsReport: slices.contractPeriodsReport.reducer,
    strategists: slices.strategists.reducer,
    aggregatedInvoiceReport: slices.aggregatedInvoiceReport.reducer,
    swellLocations: slices.swellLocations.reducer,
});

export default rootReducer;
