import { api } from "../utilityActions";
import slices from "../slices";
import {ententeUrl} from "../../config";
export default () =>
    api({
        call: {
            url: "/piston/secure/velox-practices/get-all",
            method: "get",
            baseURL: ententeUrl
        },
        success: slices.veloxPractices.actions.set,
        label: "veloxPractices",
    });
