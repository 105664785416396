import { Checkbox, Body3 } from "../Atoms";
import { Box } from "../StyledSystemUtilities";
import React from "react";

export const LabeledCheckbox = ({ label, checked, onClick = () => false }) => {
    return (
        <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
            <Body3>{label}</Body3>
            <Box mr={3} />
            <Checkbox checked={checked} onClick={onClick} />
        </Box>
    );
};
