import { api } from "../utilityActions";
import slices from "../slices";
import { endOfDay, startOfDay } from "date-fns";
export default ({ limit, last, sources, lastIds, startDate, endDate }) => {
    const startOfStartDate = startOfDay(startDate);
    const endOfEndDate = endOfDay(endDate);

    let url = `/employee/secure/leads/get-leads?limit=${limit}&startDate=${startOfStartDate.valueOf()}&endDate=${endOfEndDate.valueOf()}`;
    if (last) {
        url = url.concat(`&last=${last}`);
    }
    if (sources && sources.length > 0) {
        for (let i = 0; i < sources.length; i++) {
            url = url.concat(`&sources[]=${sources[i]}`);
        }
    }

    if (lastIds && lastIds.length > 0) {
        for (let i = 0; i < lastIds.length; i++) {
            url = url.concat(`&lastIds[]=${lastIds[i]}`);
        }
    }

    console.log("URL: ", url);

    return api({
        call: {
            url: url,
            method: "get",
        },
        success: slices.leads.actions.set,
        label: "leads",
    });
};
