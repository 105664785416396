import { Body2, FullCard, Heading, Heading2, SmallTextInput } from "../../Atoms";
import { useDispatch, useSelector } from "react-redux";
import { Box, FlexRow, FlexRowAlignCenter, Grid } from "../../StyledSystemUtilities";
import { differenceInCalendarDays } from "date-fns/fp";
import { actions, slices } from "../../../redux";
import React, { useEffect, useState } from "react";
import { historyPush } from "../../../redux/utilityActions";
import getAllLoginEventsForAccount from "../../../redux/actions/getAllLoginEventsForUser";

const filterAccounts = (clientAccounts, searchTerm) => {
    return clientAccounts
        .filter((account) =>
            account.accountName.toLowerCase().includes(searchTerm.toLowerCase().trim())
        )
        .sort((a, b) => {
            if (a.mostRecentLogin && !b.mostRecentLogin) {
                return -1;
            } else if (!a.mostRecentLogin && b.mostRecentLogin) {
                return 1;
            } else if (!a.mostRecentLogin && !b.mostRecentLogin) {
                return 1;
            }
            return a.mostRecentLogin.timestamp < b.mostRecentLogin.timestamp ? 1 : -1;
        });
};

export function LoginHistory() {
    const clientAccounts = useSelector((state) => state.clients);
    const dispatch = useDispatch();
    const [searchTerm, setSearchTerm] = useState("");

    useEffect(() => {
        dispatch(actions.getClients());
    }, [dispatch]);

    const handleRowClicked = (account) => {
        console.log("Clicked " + account.accountName);
        dispatch([
            slices.accountEventHistory.actions.clear(),
            slices.accountEventHistory.actions.setAccount(account),
            historyPush("/dash/login-history/individual"),
        ]);
    };

    const filteredAccounts = filterAccounts(clientAccounts, searchTerm);

    return (
        <>
            <FullCard>
                <FlexRowAlignCenter justifyContent={"space-between"}>
                    <Heading>Login History</Heading>

                    <Box width={19}>
                        <SmallTextInput
                            data-testid={"practiceListSearchInput"}
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            placeholder={"search..."}
                        />
                    </Box>
                </FlexRowAlignCenter>
            </FullCard>

            <FullCard>
                <TableHeadings />
                <Box height={4} />
                {filteredAccounts.map((account, index) => (
                    <Row key={index} account={account} onClick={() => handleRowClicked(account)} />
                ))}
            </FullCard>
        </>
    );
}

function TableGrid({ children, onClick }) {
    return (
        <Grid
            gridTemplateColumns={"1.25fr .75fr 1fr 1fr"}
            minWidth={"900px"}
            onClick={onClick}
            cursor={onClick ? "pointer" : null}
        >
            {children}
        </Grid>
    );
}

function TableHeadings() {
    return (
        <TableGrid>
            <Box pl={5}>
                <Heading2>Account Name</Heading2>
            </Box>
            <Box pl={5}>
                <Heading2>Last Login</Heading2>
            </Box>
            <Box pl={5}>
                <Heading2>User</Heading2>
            </Box>
            <Box pl={5}>
                <Heading2>Email</Heading2>
            </Box>
        </TableGrid>
    );
}

function Row({ account, onClick }) {
    const clientUsers = useSelector((state) => state.clientUsers);

    let recentLogin;
    let user;
    if (account.mostRecentLogin) {
        recentLogin = getDateDisplayText(account.mostRecentLogin.timestamp);

        user = clientUsers.find((user) => user._id === account.mostRecentLogin.userId);
    }

    return (
        <TableGrid onClick={onClick}>
            <Box gridColumn={"1/-1"} borderBottom={"1px solid grey"} />
            <FlexRow alignItems={"center"} p={5}>
                <Body2>{account.accountName}</Body2>
            </FlexRow>
            <FlexRow alignItems={"center"} p={5}>
                <Body2>{recentLogin ?? "No login history"}</Body2>
            </FlexRow>
            <FlexRow alignItems={"center"} p={5}>
                <Body2>{user ? `${user.firstName} ${user.lastName}` : null}</Body2>
            </FlexRow>
            <FlexRow alignItems={"center"} p={5}>
                <Body2>{user ? user.email : null}</Body2>
            </FlexRow>
        </TableGrid>
    );
}

export function getDateDisplayText(date) {
    let displayText;
    let daysAgo = differenceInCalendarDays(date, new Date());

    if (daysAgo > 365) {
        const yearsAgo = Math.floor(daysAgo / 365);
        displayText = `${yearsAgo} year${yearsAgo > 1 ? "s" : ""} ago`;
    } else if (daysAgo > 30) {
        const monthsAgo = Math.floor(daysAgo / 30);
        displayText = `${monthsAgo} month${monthsAgo > 1 ? "s" : ""} ago`;
    } else if (daysAgo === 1) {
        displayText = "Yesterday";
    } else if (daysAgo === 0) {
        displayText = "Today";
    } else {
        displayText = `${daysAgo} days ago`;
    }

    return displayText;
}
