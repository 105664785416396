import { createSlice } from "@reduxjs/toolkit";

export default createSlice({
    name: "aggregatedPpc",
    initialState: {
        legacyReport: {
            rows: [],
            averages: {},
        },
        newModelReport: {
            rows: [],
            averages: {},
        },
    },
    reducers: {
        set: (state, action) => action.payload,
    },
});
