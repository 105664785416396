import React from "react";
import { FullPageBackground, Heading, Gradient, LargeLogo, ErrorAlert } from "../Atoms";
import styled from "styled-components";
import { Box, Grid } from "../StyledSystemUtilities";
import { getSpace } from "../../helpers/themeGetters";
import useErrors from "../../hooks/useErrors";

const Form = styled.form`
    display: grid;
    grid-template-columns: 1fr;
    column-gap: ${getSpace(7)};
    row-gap: ${getSpace(7)};
    justify-content: center;
`;

const CenterContainer = styled.div`
    display: grid;
    grid-template-columns: minMax(10px, 1fr) minmax(300px, 400px) minMax(10px, 1fr);
`;

export const AuthenticationTemplate = ({ errorLabel, headerText, children, onSubmit }) => {
    const error = useErrors(errorLabel);
    return (
        <FullPageBackground>
            <Gradient />
            <CenterContainer>
                <Grid mb={10} gridColumn={2}>
                    <Box justifySelf={"center"} mt={9} mb={4}>
                        <LargeLogo />
                    </Box>

                    <Box mt={7} mb={4}>
                        <Heading>{headerText}</Heading>
                    </Box>
                    {error ? (
                        <Box my={7}>
                            <ErrorAlert message={error} />
                        </Box>
                    ) : null}
                    <Form onSubmit={onSubmit}>{children}</Form>
                </Grid>
            </CenterContainer>
        </FullPageBackground>
    );
};
