import React, { useState } from "react";
import { actions } from "../../../redux";
import {
    BlueBody3,
    Card,
    UppercaseLabel,
    EditButton,
    Checkbox,
    Body2,
    PrimaryButton,
} from "../../Atoms";
import { Box, FlexRow } from "../../StyledSystemUtilities";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash/fp";
import { PageHeading } from "../../Molecules";
import { format } from "date-fns/fp";

import { Modal, PracticeForm } from "../../Organisms";

const GeneralInformation = () => {
    const openPractice = useSelector((state) => state.openPractice);
    const googleAccounts = useSelector((state) => state.googleAccounts);
    const isNewModel = useSelector((state) => state.openPractice?.isNewModel ?? false);
    const currentUser = useSelector((state) => state.currentUser);
    const getIsNewModelLoading = useSelector((state) => state.loading.getIsNewModel);
    const upgradeSubscriptionModelLoading = useSelector(
        (state) => state.loading.upgradeSubscriptionModel
    );
    const dispatch = useDispatch();

    function getAddress() {
        const gmbAccount = _.find(
            { id: _.get("googleMyBusinessAccountId", openPractice) },
            googleAccounts.gmbAccounts
        );

        return gmbAccount ? gmbAccount.address : "";
    }

    function getUrl() {
        const searchConsoleUrl = _.find(
            (x) => _.get("googleSearchConsoleUrl", openPractice) === x,
            googleAccounts.searchConsoleAccounts
        );

        if (!searchConsoleUrl) {
            return "";
        }
        return searchConsoleUrl;
    }

    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const handlePracticeEdit = (practiceUpdate) => {
        dispatch(actions.updatePractice(openPractice._id, practiceUpdate));
        setIsEditModalOpen(false);
    };
    if (_.isEmpty(openPractice)) {
        return null;
    }

    const handleUpgradeToNewModel = () => {
        if (!window.confirm("Are you sure you want to upgrade this client?")) {
            return;
        }
        dispatch(
            actions.upgradePracticeToNewModel(openPractice._id, openPractice.recurlyAccountCode)
        );
    };

    const shouldShowUpgradeToNewModelButton =
        !isNewModel &&
        _.intersection(["ADMIN", "SALES"], currentUser.user?.positions ?? []).length > 0;

    const isLoading = upgradeSubscriptionModelLoading || getIsNewModelLoading;

    return (
        <>
            <Modal
                title={`Update ${openPractice.practiceName}`}
                isOpen={isEditModalOpen}
                setIsOpen={setIsEditModalOpen}
            >
                <PracticeForm initialState={openPractice} superviseSubmit={handlePracticeEdit} />
            </Modal>
            <PageHeading
                subHeaderText={_.get("practiceName", openPractice)}
                headlineText={"Practice Info"}
            />
            <div>
                <Card>
                    <FlexRow justifyContent={"space-between"}>
                        <Box>
                            <UppercaseLabel>strategist</UppercaseLabel>
                            <BlueBody3>{openPractice.strategist}</BlueBody3>
                        </Box>
                        <EditButton
                            data-testid={"editPracticeButton"}
                            onClick={() => setIsEditModalOpen(true)}
                        >
                            edit practice data
                        </EditButton>
                    </FlexRow>
                    <Box mb={7} />
                    <Box
                        py={6}
                        px={9}
                        backgroundColor={"chalk"}
                        display={"grid"}
                        gridTemplateColumns={"1fr 1fr"}
                        gridColumnGap={3}
                        gridRowGap={10}
                    >
                        <Box>
                            <UppercaseLabel>primary contact</UppercaseLabel>
                            <Box mb={1} />
                            <BlueBody3>{_.get("primaryContact.name", openPractice)}</BlueBody3>
                        </Box>

                        <Box>
                            <UppercaseLabel>relationship start date</UppercaseLabel>
                            <Box mb={1} />
                            <BlueBody3>{format("MM/dd/yyyy", openPractice.startDate)}</BlueBody3>
                        </Box>
                        <Box>
                            <UppercaseLabel>role</UppercaseLabel>
                            <Box mb={1} />
                            <BlueBody3>{_.get("primaryContact.label", openPractice)}</BlueBody3>
                        </Box>
                        <Box>
                            <UppercaseLabel>website link</UppercaseLabel>
                            <Box mb={1} />
                            <BlueBody3 color={"sienna"}>{getUrl()}</BlueBody3>
                        </Box>
                        <Box>
                            <UppercaseLabel>phone</UppercaseLabel>
                            <Box mb={1} />
                            <BlueBody3>{_.get("primaryContact.phone", openPractice)}</BlueBody3>
                        </Box>
                        <Box>
                            <UppercaseLabel>practice address</UppercaseLabel>
                            <Box mb={1} />
                            <BlueBody3>{getAddress()}</BlueBody3>
                        </Box>
                        <Box>
                            <UppercaseLabel>email</UppercaseLabel>
                            <Box mb={1} />
                            <BlueBody3>{_.get("primaryContact.email", openPractice)}</BlueBody3>
                        </Box>
                        <Box>
                            <UppercaseLabel>practice type</UppercaseLabel>
                            <Box mb={1} />
                            <BlueBody3>{_.startCase(_.get("type", openPractice))}</BlueBody3>
                        </Box>
                        <Box>
                            <UppercaseLabel>is new model?</UppercaseLabel>
                            <Box mb={1} />
                            <Checkbox
                                checkTestid={"isNewModel"}
                                checked={_.get("isNewModel", openPractice)}
                            />
                        </Box>
                        <Box>
                            <UppercaseLabel>practice management software type</UppercaseLabel>
                            <Box mb={1} />
                            <BlueBody3>{_.startCase(_.get("pmsType", openPractice))}</BlueBody3>
                        </Box>
                        <Box>
                            <UppercaseLabel>Active client?</UppercaseLabel>
                            <Box mb={1} />
                            <Checkbox
                                checkTestid={"isActiveCheck"}
                                checked={_.get("active", openPractice)}
                            />
                        </Box>
                    </Box>
                </Card>

                {shouldShowUpgradeToNewModelButton ? (
                    <Card>
                        <FlexRow justifyContent={"flex-end"}>
                            {isLoading ? (
                                <BlueBody3>Loading...</BlueBody3>
                            ) : (
                                <PrimaryButton onClick={handleUpgradeToNewModel}>
                                    Upgrade to New Model
                                </PrimaryButton>
                            )}
                        </FlexRow>
                    </Card>
                ) : null}

                <Card>
                    <Box py={6} px={3} backgroundColor={"chalk"}>
                        <UppercaseLabel>linked accounts</UppercaseLabel>
                        <Box
                            px={7}
                            py={5}
                            mt={3}
                            display={"grid"}
                            gridTemplateColumns={"1fr 1fr 1fr 1fr"}
                            gridRowGap={8}
                        >
                            <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
                                <Checkbox
                                    checkTestid={"hasGmbAccount"}
                                    checked={
                                        !_.isEmpty(_.get("googleMyBusinessAccountId", openPractice))
                                    }
                                />
                                <Box mb={2} />
                                <BlueBody3 textAlign={"center"}>Google My Business</BlueBody3>
                            </Box>
                            <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
                                <Checkbox
                                    checkTestid={"hasGaAccount"}
                                    checked={
                                        !_.isEmpty(_.get("googleAnalyticsPropertyId", openPractice))
                                    }
                                />
                                <Box mb={2} />
                                <BlueBody3 textAlign={"center"}>Google Analytics</BlueBody3>
                            </Box>
                            <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
                                <Checkbox
                                    checkTestid={"hasSearchConsoleAccount"}
                                    checked={
                                        !_.isEmpty(_.get("googleSearchConsoleUrl", openPractice))
                                    }
                                />
                                <Box mb={2} />
                                <BlueBody3 textAlign={"center"}>Search Console</BlueBody3>
                            </Box>
                            <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
                                <Checkbox
                                    checkTestid={"hasCallRailCompanyId"}
                                    checked={!_.isEmpty(_.get("callRailCompanyId", openPractice))}
                                />
                                <Box mb={2} />
                                <BlueBody3 textAlign={"center"}>Call Rail</BlueBody3>
                            </Box>
                            <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
                                <Checkbox
                                    checkTestid={"hasRecurlyAccountCode"}
                                    checked={!_.isEmpty(_.get("recurlyAccountCode", openPractice))}
                                />
                                <Box mb={2} />
                                <BlueBody3 textAlign={"center"}>Recurly</BlueBody3>
                            </Box>

                            <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
                                <Checkbox
                                    checked={!_.isEmpty(_.get("adviceLocalClientId", openPractice))}
                                />
                                <Box mb={2} />
                                <BlueBody3 textAlign={"center"}>Advice Local</BlueBody3>
                            </Box>

                            <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
                                <Checkbox
                                    checked={!_.isEmpty(_.get("veloxPracticeId", openPractice))}
                                />
                                <Box mb={2} />
                                <BlueBody3 textAlign={"center"}>Velox</BlueBody3>
                            </Box>

                            <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
                                <Checkbox
                                    checked={Boolean(_.get("swellLocationId", openPractice))}
                                />
                                <Box mb={2} />
                                <BlueBody3 textAlign={"center"}>Swell</BlueBody3>
                            </Box>
                        </Box>
                    </Box>
                </Card>
                <Card>
                    <Box backgroundColor={"chalk"} py={6}>
                        <Box px={3}>
                            <Body2>Overview &mdash; notes, goals, and general strategy</Body2>
                        </Box>
                        <Box px={7} mt={4}>
                            <BlueBody3>{openPractice.details}</BlueBody3>
                        </Box>
                    </Box>
                </Card>
            </div>
        </>
    );
};

export const PracticeInfo = () => {
    return (
        <>
            <GeneralInformation />
        </>
    );
};
